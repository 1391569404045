import ControllerFactory from "./ControllerFactory";
import ContactFormController from "guest_website/controllers/ContactFormController";
import ContactFormData from "../booking_system/ContactFormData";
import ContactFormControllerImpl from "guest_website/controllers/ContactFormControllerImpl";
import ZipCodeFormController from "guest_website/controllers/ZipCodeFormController";
import ZipCodeFormControllerImpl from "guest_website/controllers/ZipCodeFormControllerImpl";
import BookingFormController from "guest_website/controllers/BookingFormController";
import BookingFromControllerImpl from "guest_website/controllers/BookingFormControllerImpl";
import BookingConfirmationFormController from "guest_website/controllers/BookingConfirmationFormController";
import BookingConfirmationFormData from "services/booking_system/BookingConfirmationFormData";
import BookingConfirmationFormControllerImpl from "guest_website/controllers/BookingConfirmationFormControllerImpl";
import { CalendarSlot } from "model/calendarSlot";

export default class ControllerFactoryImpl implements ControllerFactory {
  createContactFormController(data?: ContactFormData): ContactFormController {
    return new ContactFormControllerImpl(data);
  }

  createZipCodeFormController(zip?: string): ZipCodeFormController {
    return new ZipCodeFormControllerImpl(zip);
  }

  createBookingFormController(slot?: CalendarSlot): BookingFormController {
    return new BookingFromControllerImpl(slot);
  }

  createBookingConfirmationFormController(
    slot: CalendarSlot,
    formData?: BookingConfirmationFormData
  ): BookingConfirmationFormController {
    return new BookingConfirmationFormControllerImpl(slot, formData);
  }
}
