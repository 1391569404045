import { SEND_MAIL_FAILED, RESET_SEND_MAIL_ERROR, RESET } from "store/actions/action_types";
import { BookingSystemState } from "./stateTypes";

const initialState: BookingSystemState = {
  contactFormError: "",
};

function BookingSystemReducer(state = initialState, action: any) {
  switch (action.type) {
    case SEND_MAIL_FAILED:
      return {
        ...state,
        contactFormError: action.message,
      };
    case RESET_SEND_MAIL_ERROR:
      return {
        ...state,
        contactFormError: "",
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return { ...state };
  }
}

export default BookingSystemReducer;
