import React, { ReactElement } from "react";
import { Col, Row } from "react-bootstrap";
import { basicDefaultProps, BasicProps } from "ui/BasicProps";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import Padding from "./Padding";

type Props = BasicProps & {
  builder(idx: number): ReactElement;
  itemCount: number;
};

HorizontalListView.defaultProps = {
  ...basicDefaultProps,
};

function HorizontalListView(props: Props) {
  return (
    <Row noGutters={true} className="align-items-center">
      {buildItems()}
    </Row>
  );

  function buildItems(): ReactElement[] {
    let items: ReactElement[] = [];
    for (let i = 0; i < props.itemCount; i++) {
      items.push(
        // <ResponsiveCol dimensions={ColDimensions.only({lg: 1, md: 1, sm: 12 })}>
        //   {props.builder(i)}
        // </ResponsiveCol>
        <Col
          xl={true}
          lg={6}
          md={12}
          sm={12}
          style={{ alignItems: "center" }}
          className="align-items-center">
          <Padding
            padding={
              i == 0
                ? EdgeInsets.onlyRight(0.25)
                : i == props.itemCount - 1
                ? EdgeInsets.onlyLeft(0.25)
                : EdgeInsets.symetricHorizontal(0.25)
            }>
            {props.builder(i)}
          </Padding>
        </Col>
      );
    }
    return items;
  }
}

export default HorizontalListView;
