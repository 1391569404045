export interface LocaliteInfo {
  commune: string;
  commune_code: string;
}

export type LocaliteData = { [id: string]: LocaliteInfo };

export const communeFromLocalite: LocaliteData = {
  "3770": { commune: "RIEMST", commune_code: "3770" },
  "3870": { commune: "HEERS", commune_code: "3870" },
  "3700": { commune: "TONGEREN", commune_code: "3700" },
  "2430": { commune: "LAAKDAL", commune_code: "2430" },
  "2890": { commune: "PUURS-SINT-AMANDS", commune_code: "2870" },
  "3460": { commune: "BEKKEVOORT", commune_code: "3460" },
  "3740": { commune: "BILZEN", commune_code: "3740" },
  "3723": { commune: "KORTESSEM", commune_code: "3720" },
  "3471": { commune: "KORTENAKEN", commune_code: "3470" },
  "1745": { commune: "OPWIJK", commune_code: "1745" },
  "3294": { commune: "DIEST", commune_code: "3290" },
  "2812": { commune: "MECHELEN", commune_code: "2800" },
  "3400": { commune: "LANDEN", commune_code: "3400" },
  "3850": { commune: "NIEUWERKERKEN", commune_code: "3850" },
  "3960": { commune: "BREE", commune_code: "3960" },
  "3803": { commune: "SINT-TRUIDEN", commune_code: "3800" },
  "2960": { commune: "BRECHT", commune_code: "2960" },
  "1933": { commune: "ZAVENTEM", commune_code: "1930" },
  "2830": { commune: "WILLEBROEK", commune_code: "2830" },
  "3830": { commune: "WELLEN", commune_code: "3830" },
  "3401": { commune: "LANDEN", commune_code: "3400" },
  "3930": { commune: "HAMONT-ACHEL", commune_code: "3930" },
  "1790": { commune: "AFFLIGEM", commune_code: "1790" },
  "3271": { commune: "SCHERPENHEUVEL-ZICHEM", commune_code: "3270" },
  "2490": { commune: "BALEN", commune_code: "2490" },
  "1730": { commune: "ASSE", commune_code: "1730" },
  "2560": { commune: "NIJLEN", commune_code: "2560" },
  "3190": { commune: "BOORTMEERBEEK", commune_code: "3190" },
  "3640": { commune: "KINROOI", commune_code: "3640" },
  "3511": { commune: "HASSELT", commune_code: "3500" },
  "3891": { commune: "GINGELOM", commune_code: "3890" },
  "1910": { commune: "KAMPENHOUT", commune_code: "1910" },
  "3350": { commune: "LINTER", commune_code: "3350" },
  "1640": { commune: "Sint-Genesius-Rode", commune_code: "1640" },
  "3650": { commune: "DILSEN-STOKKEM", commune_code: "3650" },
  "1741": { commune: "TERNAT", commune_code: "1740" },
  "2235": { commune: "HULSHOUT", commune_code: "2235" },
  "2980": { commune: "ZOERSEL", commune_code: "2980" },
  //"2018": { commune: "ANTWERPEN", commune_code: "2030" },
  "1501": { commune: "HALLE", commune_code: "1500" },
  "1570": { commune: "GALMAARDEN", commune_code: "1570" },
  "1702": { commune: "DILBEEK", commune_code: "1700" },
  "3717": { commune: "HERSTAPPE", commune_code: "3717" },
  "1701": { commune: "DILBEEK", commune_code: "1700" },
  "3381": { commune: "GLABBEEK", commune_code: "3380" },
  "3840": { commune: "BORGLOON", commune_code: "3840" },
  //"2040": { commune: "ANTWERPEN", commune_code: "2030" },
  "2240": { commune: "ZANDHOVEN", commune_code: "2240" },
  "3391": { commune: "TIELT-WINGE", commune_code: "3390" },
  "3300": { commune: "TIENEN", commune_code: "3300" },
  "3050": { commune: "OUD-HEVERLEE", commune_code: "3050" },
  "3202": { commune: "AARSCHOT", commune_code: "3200" },
  "3370": { commune: "BOUTERSEM", commune_code: "3370" },
  "2880": { commune: "BORNEM", commune_code: "2880" },
  "3730": { commune: "HOESELT", commune_code: "3730" },
  "3670": { commune: "OUDSBERGEN", commune_code: "3670" },
  "3520": { commune: "ZONHOVEN", commune_code: "3520" },
  "3360": { commune: "BIERBEEK", commune_code: "3360" },
  "1547": { commune: "Bever", commune_code: "1547" },
  "3200": { commune: "AARSCHOT", commune_code: "3200" },
  "1850": { commune: "GRIMBERGEN", commune_code: "1850" },
  "3440": { commune: "ZOUTLEEUW", commune_code: "3440" },
  "3940": { commune: "HECHTEL-EKSEL", commune_code: "3940" },
  "3550": { commune: "HEUSDEN-ZOLDER", commune_code: "3550" },
  "1880": { commune: "KAPELLE-OP-DEN-BOS", commune_code: "1880" },
  "1785": { commune: "MERCHTEM", commune_code: "1785" },
  "2640": { commune: "MORTSEL", commune_code: "2640" },
  "3890": { commune: "GINGELOM", commune_code: "3890" },
  "2491": { commune: "BALEN", commune_code: "2490" },
  "1932": { commune: "ZAVENTEM", commune_code: "1930" },
  "3020": { commune: "HERENT", commune_code: "3020" },
  "3384": { commune: "GLABBEEK", commune_code: "3380" },
  "3800": { commune: "SINT-TRUIDEN", commune_code: "3800" },
  "3380": { commune: "GLABBEEK", commune_code: "3380" },
  "3450": { commune: "GEETBETS", commune_code: "3450" },
  "2320": { commune: "HOOGSTRATEN", commune_code: "2320" },
  "3472": { commune: "KORTENAKEN", commune_code: "3470" },
  "3620": { commune: "LANAKEN", commune_code: "3620" },
  "1840": { commune: "LONDERZEEL", commune_code: "1840" },
  "3790": { commune: "FOURONS", commune_code: "3790" },
  "1930": { commune: "ZAVENTEM", commune_code: "1930" },
  "2260": { commune: "WESTERLO", commune_code: "2260" },
  "1601": { commune: "SINT-PIETERS-LEEUW", commune_code: "1600" },
  "3120": { commune: "TREMELO", commune_code: "3120" },
  "3290": { commune: "DIEST", commune_code: "3290" },
  "1980": { commune: "ZEMST", commune_code: "1980" },
  "3742": { commune: "BILZEN", commune_code: "3740" },
  "1820": { commune: "STEENOKKERZEEL", commune_code: "1820" },
  "3900": { commune: "PELT", commune_code: "3900" },
  "2580": { commune: "PUTTE", commune_code: "2580" },
  "3590": { commune: "DIEPENBEEK", commune_code: "3590" },
  "3630": { commune: "MAASMECHELEN", commune_code: "3630" },
  "1500": { commune: "HALLE", commune_code: "1500" },
  "1755": { commune: "GOOIK", commune_code: "1755" },
  "2550": { commune: "KONTICH", commune_code: "2550" },
  "1502": { commune: "HALLE", commune_code: "1500" },
  "3540": { commune: "HERK-DE-STAD", commune_code: "3540" },
  "3220": { commune: "HOLSBEEK", commune_code: "3220" },
  "3512": { commune: "HASSELT", commune_code: "3500" },
  "3724": { commune: "KORTESSEM", commune_code: "3720" },
  "2910": { commune: "ESSEN", commune_code: "2910" },
  "2275": { commune: "LILLE", commune_code: "2275" },
  "3530": { commune: "HOUTHALEN-HELCHTEREN", commune_code: "3530" },
  "2450": { commune: "MEERHOUT", commune_code: "2450" },
  "3212": { commune: "LUBBEEK", commune_code: "3210" },
  "1760": { commune: "ROOSDAAL", commune_code: "1760" },
  "1600": { commune: "SINT-PIETERS-LEEUW", commune_code: "1600" },
  "2940": { commune: "STABROEK", commune_code: "2940" },
  "3390": { commune: "TIELT-WINGE", commune_code: "3390" },
  "2431": { commune: "LAAKDAL", commune_code: "2430" },
  "3545": { commune: "HALEN", commune_code: "3545" },
  "1731": { commune: "ASSE", commune_code: "1730" },
  "3404": { commune: "LANDEN", commune_code: "3400" },
  "3001": { commune: "LEUVEN", commune_code: "3000" },
  //"2660": { commune: "ANTWERPEN", commune_code: "2030" },
  "3140": { commune: "KEERBERGEN", commune_code: "3140" },
  "2250": { commune: "OLEN", commune_code: "2250" },
  "3560": { commune: "LUMMEN", commune_code: "3560" },
  "2520": { commune: "RANST", commune_code: "2520" },
  "2840": { commune: "RUMST", commune_code: "2840" },
  "3473": { commune: "KORTENAKEN", commune_code: "3470" },
  "2800": { commune: "MECHELEN", commune_code: "2800" },
  "3272": { commune: "SCHERPENHEUVEL-ZICHEM", commune_code: "3270" },
  "2340": { commune: "BEERSE", commune_code: "2340" },
  "2820": { commune: "BONHEIDEN", commune_code: "2820" },
  "1761": { commune: "ROOSDAAL", commune_code: "1760" },
  "3990": { commune: "PEER", commune_code: "3990" },
  "2220": { commune: "HEIST-OP-DEN-BERG", commune_code: "2220" },
  "2222": { commune: "HEIST-OP-DEN-BERG", commune_code: "2220" },
  "2950": { commune: "KAPELLEN", commune_code: "2950" },
  "3510": { commune: "HASSELT", commune_code: "3500" },
  "3945": { commune: "HAM", commune_code: "3945" },
  "1630": { commune: "LINKEBEEK", commune_code: "1630" },
  "2230": { commune: "HERSELT", commune_code: "2230" },
  "2870": { commune: "PUURS-SINT-AMANDS", commune_code: "2870" },
  "3150": { commune: "HAACHT", commune_code: "3150" },
  "3832": { commune: "WELLEN", commune_code: "3830" },
  "3501": { commune: "HASSELT", commune_code: "3500" },
  "3581": { commune: "BERINGEN", commune_code: "3580" },
  "1620": { commune: "DROGENBOS", commune_code: "1620" },
  "1653": { commune: "BEERSEL", commune_code: "1650" },
  "1671": { commune: "PEPINGEN", commune_code: "1670" },
  "3191": { commune: "BOORTMEERBEEK", commune_code: "3190" },
  "1560": { commune: "HOEILAART", commune_code: "1560" },
  "3010": { commune: "LEUVEN", commune_code: "3000" },
  "2390": { commune: "MALLE", commune_code: "2390" },
  "2321": { commune: "HOOGSTRATEN", commune_code: "2320" },
  //"2170": { commune: "ANTWERPEN", commune_code: "2030" },
  "1970": { commune: "WEZEMBEEK-OPPEM", commune_code: "1970" },
  "3722": { commune: "KORTESSEM", commune_code: "3720" },
  "3060": { commune: "BERTEM", commune_code: "3060" },
  "1700": { commune: "DILBEEK", commune_code: "1700" },
  "2570": { commune: "DUFFEL", commune_code: "2570" },
  "1750": { commune: "LENNIK", commune_code: "1750" },
  "3053": { commune: "OUD-HEVERLEE", commune_code: "3050" },
  "3040": { commune: "HULDENBERG", commune_code: "3040" },
  "2322": { commune: "HOOGSTRATEN", commune_code: "2320" },
  "3470": { commune: "KORTENAKEN", commune_code: "3470" },
  "2860": { commune: "SINT-KATELIJNE-WAVER", commune_code: "2860" },
  "1541": { commune: "HERNE", commune_code: "1540" },
  "3631": { commune: "MAASMECHELEN", commune_code: "3630" },
  "3720": { commune: "KORTESSEM", commune_code: "3720" },
  "1670": { commune: "PEPINGEN", commune_code: "1670" },
  //"2140": { commune: "ANTWERPEN", commune_code: "2030" },
  "1981": { commune: "ZEMST", commune_code: "1980" },
  "3320": { commune: "HOEGAARDEN", commune_code: "3320" },
  "3461": { commune: "BEKKEVOORT", commune_code: "3460" },
  "2223": { commune: "HEIST-OP-DEN-BERG", commune_code: "2220" },
  "1780": { commune: "WEMMEL", commune_code: "1780" },
  "2630": { commune: "AARTSELAAR", commune_code: "2630" },
  "1652": { commune: "BEERSEL", commune_code: "1650" },
  //"2000": { commune: "ANTWERPEN", commune_code: "2030" },
  "2387": { commune: "BAARLE-HERTOG", commune_code: "2387" },
  //"2180": { commune: "ANTWERPEN", commune_code: "2030" },
  "3078": { commune: "KORTENBERG", commune_code: "3070" },
  "3680": { commune: "MAASEIK", commune_code: "3680" },
  "3971": { commune: "LEOPOLDSBURG", commune_code: "3970" },
  "1860": { commune: "MEISE", commune_code: "1860" },
  "3221": { commune: "HOLSBEEK", commune_code: "3220" },
  "2242": { commune: "ZANDHOVEN", commune_code: "2240" },
  "2970": { commune: "SCHILDE", commune_code: "2970" },
  "2900": { commune: "SCHOTEN", commune_code: "2900" },
  "3080": { commune: "TERVUREN", commune_code: "3080" },
  "1861": { commune: "MEISE", commune_code: "1860" },
  "3580": { commune: "BERINGEN", commune_code: "3580" },
  "2801": { commune: "MECHELEN", commune_code: "2800" },
  "1654": { commune: "BEERSEL", commune_code: "1650" },
  "3061": { commune: "BERTEM", commune_code: "3060" },
  "3791": { commune: "FOURONS", commune_code: "3790" },
  "2310": { commune: "RIJKEVORSEL", commune_code: "2310" },
  "3454": { commune: "GEETBETS", commune_code: "3450" },
  "1703": { commune: "DILBEEK", commune_code: "1700" },
  "3270": { commune: "SCHERPENHEUVEL-ZICHEM", commune_code: "3270" },
  "1740": { commune: "TERNAT", commune_code: "1740" },
  "2290": { commune: "VORSELAAR", commune_code: "2290" },
  "3690": { commune: "ZUTENDAAL", commune_code: "3690" },
  "2070": { commune: "ZWIJNDRECHT", commune_code: "2070" },
  //"2020": { commune: "ANTWERPEN", commune_code: "2030" },
  "3500": { commune: "HASSELT", commune_code: "3500" },
  "2270": { commune: "HERENTHOUT", commune_code: "2270" },
  "1830": { commune: "MACHELEN", commune_code: "1830" },
  "2360": { commune: "OUD-TURNHOUT", commune_code: "2360" },
  "3128": { commune: "TREMELO", commune_code: "3120" },
  "2460": { commune: "KASTERLEE", commune_code: "2460" },
  "3070": { commune: "KORTENBERG", commune_code: "3070" },
  "3201": { commune: "AARSCHOT", commune_code: "3200" },
  "2200": { commune: "HERENTALS", commune_code: "2200" },
  "1853": { commune: "GRIMBERGEN", commune_code: "1850" },
  "1982": { commune: "ZEMST", commune_code: "1980" },
  "2110": { commune: "WIJNEGEM", commune_code: "2110" },
  "3806": { commune: "SINT-TRUIDEN", commune_code: "3800" },
  "3111": { commune: "ROTSELAAR", commune_code: "3110" },
  "3054": { commune: "OUD-HEVERLEE", commune_code: "3050" },
  //"2600": { commune: "ANTWERPEN", commune_code: "2030" },
  "2150": { commune: "BORSBEEK", commune_code: "2150" },
  "2288": { commune: "GROBBENDONK", commune_code: "2280" },
  "3792": { commune: "FOURONS", commune_code: "3790" },
  "1851": { commune: "GRIMBERGEN", commune_code: "1850" },
  "3950": { commune: "BOCHOLT", commune_code: "3950" },
  "3970": { commune: "LEOPOLDSBURG", commune_code: "3970" },
  "3210": { commune: "LUBBEEK", commune_code: "3210" },
  "2547": { commune: "LINT", commune_code: "2547" },
  "2990": { commune: "WUUSTWEZEL", commune_code: "2990" },
  "3920": { commune: "LOMMEL", commune_code: "3920" },
  "3130": { commune: "BEGIJNENDIJK", commune_code: "3130" },
  "3211": { commune: "LUBBEEK", commune_code: "3210" },
  "3583": { commune: "BERINGEN", commune_code: "3580" },
  "2627": { commune: "SCHELLE", commune_code: "2627" },
  "3051": { commune: "OUD-HEVERLEE", commune_code: "3050" },
  "2323": { commune: "HOOGSTRATEN", commune_code: "2320" },
  "3980": { commune: "TESSENDERLO", commune_code: "3980" },
  "3793": { commune: "FOURONS", commune_code: "3790" },
  //"2050": { commune: "ANTWERPEN", commune_code: "2030" },
  "2280": { commune: "GROBBENDONK", commune_code: "2280" },
  "2620": { commune: "HEMIKSEM", commune_code: "2620" },
  "1540": { commune: "HERNE", commune_code: "1540" },
  "2811": { commune: "MECHELEN", commune_code: "2800" },
  "2328": { commune: "HOOGSTRATEN", commune_code: "2320" },
  "2330": { commune: "MERKSPLAS", commune_code: "2330" },
  "3321": { commune: "HOEGAARDEN", commune_code: "3320" },
  "2530": { commune: "BOECHOUT", commune_code: "2530" },
  "2221": { commune: "HEIST-OP-DEN-BERG", commune_code: "2220" },
  "2930": { commune: "BRASSCHAAT", commune_code: "2930" },
  "3071": { commune: "KORTENBERG", commune_code: "3070" },
  "3600": { commune: "GENK", commune_code: "3600" },
  "3582": { commune: "BERINGEN", commune_code: "3580" },
  "3000": { commune: "LEUVEN", commune_code: "3000" },
  "3621": { commune: "LANAKEN", commune_code: "3620" },
  "2531": { commune: "BOECHOUT", commune_code: "2530" },
  "3831": { commune: "WELLEN", commune_code: "3830" },
  "1852": { commune: "GRIMBERGEN", commune_code: "1850" },
  "3052": { commune: "OUD-HEVERLEE", commune_code: "3050" },
  //"2100": { commune: "ANTWERPEN", commune_code: "2030" },
  "3798": { commune: "FOURONS", commune_code: "3790" },
  "2382": { commune: "RAVELS", commune_code: "2380" },
  "3293": { commune: "DIEST", commune_code: "3290" },
  "3941": { commune: "HECHTEL-EKSEL", commune_code: "3940" },
  "1800": { commune: "VILVOORDE", commune_code: "1800" },
  //"2610": { commune: "ANTWERPEN", commune_code: "2030" },
  "2480": { commune: "DESSEL", commune_code: "2480" },
  "2650": { commune: "EDEGEM", commune_code: "2650" },
  "3910": { commune: "PELT", commune_code: "3900" },
  "2243": { commune: "ZANDHOVEN", commune_code: "2240" },
  "2470": { commune: "RETIE", commune_code: "2470" },
  "2350": { commune: "VOSSELAAR", commune_code: "2350" },
  "3118": { commune: "ROTSELAAR", commune_code: "3110" },
  "3665": { commune: "AS", commune_code: "3665" },
  "1831": { commune: "MACHELEN", commune_code: "1830" },
  "2440": { commune: "GEEL", commune_code: "2440" },
  "2920": { commune: "KALMTHOUT", commune_code: "2920" },
  "2845": { commune: "NIEL", commune_code: "2845" },
  "1742": { commune: "TERNAT", commune_code: "1740" },
  "2381": { commune: "RAVELS", commune_code: "2380" },
  "3570": { commune: "ALKEN", commune_code: "3570" },
  "1674": { commune: "PEPINGEN", commune_code: "1670" },
  "1950": { commune: "KRAAINEM", commune_code: "1950" },
  "1651": { commune: "BEERSEL", commune_code: "1650" },
  "3110": { commune: "ROTSELAAR", commune_code: "3110" },
  "1602": { commune: "SINT-PIETERS-LEEUW", commune_code: "1600" },
  "2370": { commune: "ARENDONK", commune_code: "2370" },
  "1650": { commune: "BEERSEL", commune_code: "1650" },
  "1673": { commune: "PEPINGEN", commune_code: "1670" },
  "2590": { commune: "BERLAAR", commune_code: "2590" },
  "2500": { commune: "LIER", commune_code: "2500" },
  "1770": { commune: "LIEDEKERKE", commune_code: "1770" },
  "3012": { commune: "LEUVEN", commune_code: "3000" },
  //"2060": { commune: "ANTWERPEN", commune_code: "2030" },
  "2850": { commune: "BOOM", commune_code: "2850" },
  "2861": { commune: "SINT-KATELIJNE-WAVER", commune_code: "2860" },
  "3660": { commune: "OUDSBERGEN", commune_code: "3670" },
  "3090": { commune: "OVERIJSE", commune_code: "3090" },
  //"2030": { commune: "ANTWERPEN", commune_code: "2030" },
  "2540": { commune: "HOVE", commune_code: "2540" },
  "2400": { commune: "MOL", commune_code: "2400" },
  "3668": { commune: "AS", commune_code: "3665" },
  "2380": { commune: "RAVELS", commune_code: "2380" },
  "3732": { commune: "HOESELT", commune_code: "3730" },
  "2300": { commune: "TURNHOUT", commune_code: "2300" },
  "3721": { commune: "KORTESSEM", commune_code: "3720" },
  "3018": { commune: "LEUVEN", commune_code: "3000" },
  "2160": { commune: "WOMMELGEM", commune_code: "2160" },
  "3746": { commune: "BILZEN", commune_code: "3740" },
};
