import React from "react";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "../guest_website/components/widgets/ScrollToTop";
import ApplicationRoutes from "./ApplicationRoutes";

function WebsiteRouter() {
  return (
    <div>
      <BrowserRouter>
      <ScrollToTop />
        <ApplicationRoutes />
      </BrowserRouter>
    </div>
  );
}

export default WebsiteRouter;
