import Color from "./Color";

class SolidColor implements Color {
    red: number;
    green: number;
    blue: number;
    alpha: number;

    constructor(r: number, g: number, b: number, a: number = 1) {
        this.red = r;
        this.green = g;
        this.blue = b;
        this.alpha = a;
    }

    static fromRGBA(r: number, g: number, b: number, a: number = 1): SolidColor {
        return new SolidColor(r, g, b, a);
    }

    public withAlpha(a: number): SolidColor {
        return new SolidColor(this.red, this.green, this.blue, a);
    }

    public toString(): string {
        return `rgba(${this.red}, ${this.green}, ${this.blue}, ${this.alpha})`
    }
}

export default SolidColor