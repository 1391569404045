import { combineReducers } from "redux";
import RealisationsReducer from "./RealisationsReducer";
import NavigationReducer from "./NavigationReducer";
import ConstantsReducer from "./ConstantsReducer";
import BookingSystemReducer from "./BookingSystemReducer";

const RootReducer = combineReducers({
  realisations: RealisationsReducer,
  navigation: NavigationReducer,
  constants: ConstantsReducer,
  booking: BookingSystemReducer,
});

export type RootState = ReturnType<typeof RootReducer>;
export default RootReducer;
