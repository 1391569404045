type ColDimensionsProps = {
  defaultValue?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

class ColDimensions {
  defaultValue?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;

  constructor(defaultValue?: number, sm?: number, md?: number, lg?: number, xl?: number) {
    this.defaultValue = defaultValue;
    this.sm = sm;
    this.md = md;
    this.lg = lg;
    this.xl = xl;
  }


  static only(dimensions: ColDimensionsProps): ColDimensions {
    return new ColDimensions(dimensions.defaultValue, dimensions.sm, dimensions.md, dimensions.lg, dimensions.xl);
  }

  public toString(): string {
    return `${this.defaultValue === undefined ? "" : "col-" + this.defaultValue}${
      this.sm === undefined ? "" : " col-sm-" + this.sm
    }${this.md === undefined ? "" : " col-md-" + this.md}${
      this.lg === undefined ? "" : " col-lg-" + this.lg
    }${this.xl === undefined ? "" : " col-xl-" + this.xl}`.trimStart();
  }
}

export default ColDimensions;
