import React, { CSSProperties } from "react";
import { TextAlignment, FontWeight } from "../graphic_helpers/graphic_enums";
import Color from "../graphic_helpers/Color";
import Colors from "../graphic_helpers/Colors";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";

type Props = BasicProps & {
  text: string;
  textAlign: TextAlignment;
  color: Color;
  fontSize: number;
  fontWeight: FontWeight;
};

Text.defaultProps = {
  ...basicDefaultProps,
  textAlign: TextAlignment.Left,
  color: Colors.blackText,
  fontSize: 16,
  fontWeight: FontWeight.normal,
};

function Text(props: Props) {
  const style: CSSProperties = {
    textAlign: props.textAlign,
    color: props.color.toString(),
    fontSize: props.fontSize,
    fontWeight: props.fontWeight,
    zIndex: props.zIndex,
    padding: 0,
    margin: 0,
    whiteSpace: "pre-wrap",
  };

  return (
    <p style={style} id={props.id} className={props.className}>
      {props.text}
    </p>
  );
}

export default Text;
