const fsConstants = {
  collections: {
    realisations: "realisations",
    contactMails: "contactMails",
    reservations: "reservations",
    dayInfos: "dayInfos",
    bookingMails: "bookingMails",
  },
  docs: {
    companyReal: "company-realisations",
    individualReal: "individual-realisations",
  },
};

export default fsConstants;
