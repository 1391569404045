import Color from "./Color";
import SolidColor from "./SolidColor";

type GradientColorProps = {
  angle?: number;
  colorA: SolidColor;
  colorB: SolidColor;
  percentA?: number;
  percentB?: number;
};

class GradientColor implements Color {
  angle: number;
  colorA: SolidColor;
  colorB: SolidColor;
  percentA: number;
  percentB: number;

  constructor(settings: GradientColorProps) {
    this.angle = settings.angle ?? 0;
    this.colorA = settings.colorA;
    this.colorB = settings.colorB;
    this.percentA = settings.percentA ?? 0;
    this.percentB = settings.percentB ?? (this.percentA === 0 ? 100 : 0);
  }

  static from2Colors(colorA: SolidColor, colorB: SolidColor) {
    return new GradientColor({ colorA: colorA, colorB: colorB });
  }

  static from2ColorsHorizontal(colorA: SolidColor, colorB: SolidColor) {
    return new GradientColor({ colorA: colorA, colorB: colorB, angle: 90 });
  }

  static from2ColorsWithAngle(colorA: SolidColor, colorB: SolidColor, angle: number) {
    return new GradientColor({ colorA: colorA, colorB: colorB, angle: angle });
  }

  public toString() {
    let colorString: string;
    if (this.percentA <= this.percentB) {
      colorString = `linear-gradient(${this.angle}deg, ${this.colorA.toString()} ${
        this.percentA
      }%, ${this.colorB.toString()} ${this.percentB.toString()}%)`;
    } else {
      colorString = `linear-gradient(${this.angle}deg, ${this.colorB.toString()} ${
        this.percentB
      }%, ${this.colorA.toString()} ${this.percentA.toString()}%)`;
    }
    return colorString;
  }
}

export default GradientColor;
