type SymetricTypes = {
  horizontal?: number;
  vertical?: number;
};

type OnlyTypes = {
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
};

class EdgeInsets {
  left: number = 0;
  top: number = 0;
  right: number = 0;
  bottom: number = 0;

  private constructor(left: number, top: number, right: number, bottom: number) {
    this.left = left;
    this.top = top;
    this.right = right;
    this.bottom = bottom;
  }

  static zero(): EdgeInsets{
    return EdgeInsets.all(0);
  } 

  static all(pad: number): EdgeInsets {
    return new EdgeInsets(pad, pad, pad, pad);
  }

  static onlyLeft(pad: number): EdgeInsets {
    return new EdgeInsets(pad, 0, 0, 0);
  }

  static onlyTop(pad: number): EdgeInsets {
    return new EdgeInsets(0, pad, 0, 0);
  }

  static onlyRight(pad: number): EdgeInsets {
    return new EdgeInsets(0, 0, pad, 0);
  }

  static onlyBottom(pad: number): EdgeInsets {
    return new EdgeInsets(0, 0, 0, pad);
  }

  static only(padding: OnlyTypes) {
    return new EdgeInsets(
      padding.left ?? 0,
      padding.top ?? 0,
      padding.right ?? 0,
      padding.bottom ?? 0
    );
  }

  static symetricHorizontal(horizontal: number) {
    return new EdgeInsets(horizontal, 0, horizontal, 0);
  }

  static symetricVertical(vertical: number) {
    return new EdgeInsets(0, vertical, 0, vertical);
  }

  static symetricHorizontalVertical(horizontal: number, vertical: number) {
    return new EdgeInsets(horizontal, vertical, horizontal, vertical);
  }

  static symetric(padding: SymetricTypes) {
    return new EdgeInsets(
      padding.horizontal ?? 0,
      padding.vertical ?? 0,
      padding.horizontal ?? 0,
      padding.vertical ?? 0
    );
  }

  static fromLTRB(left: number, top: number, right: number, bottom: number): EdgeInsets {
    return new EdgeInsets(left, top, right, bottom);
  }

  public toString() {
    return `${this.top}rem ${this.right}rem ${this.bottom}rem ${this.left}rem`;
  }
}

export default EdgeInsets;
