import Masonry from "react-masonry-component";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";
import React, { ReactNode, useState } from "react";
import { Image } from "react-bootstrap";
import ModalImageGallery from "ui/generic_components/ModalImageGallery";
import "./realisations.css";
import Padding from "ui/generic_components/Padding";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import SectionTitle from "ui/generic_components/SectionTitle";
import Center from "ui/generic_components/Center";
import Divider from "ui/generic_components/Divider";
import { useSelector } from "react-redux";
import { ConstantsState } from "store/reducers/stateTypes";
import { RootState } from "store/reducers/RootReducer";

type Props = BasicProps & {
  picUrls: string[];
  lastImageRef: any;
};

RealisationsPageView.defaultProps = {
  ...basicDefaultProps,
};

const masonryOptions = {
  transitionDuration: 1,
  columnWidth: ".grid-sizer",
  itemSelector: ".grid-item",
  percentPosition: true,
};

export function RealisationsPageView(props: Props) {
  const { strings, theme }: ConstantsState = useSelector((state: RootState) => state.constants);
  const [galleryState, setGalleryShown] = useState({ isGalleryShown: false, idx: 0 });
  const [loading, setLoading] = useState(true);

  const render = () => {
    return (
      <div id={props.id} style={{ minHeight: 1000, background: theme.backgroundColorA.toString() }}>
        <Padding padding={EdgeInsets.all(2)}>
          <SectionTitle title={strings.realisations} color={theme.mainTextColor} />
          <Center>
            <Divider color={theme.accentColor} width={160} />
          </Center>
          {renderGallery()}
          {loading ? <></> : <></>}
          <Masonry
            onImagesLoaded={() =>
              setTimeout(() => {
                if (loading && props.picUrls.length > 0) {
                  setLoading(false);
                }
              }, 500)
            }
            className="grid"
            options={masonryOptions} // default {}
            elementType={"div"}
            enableResizableChildren={true}>
            <div className="grid-sizer"></div>
            <div style={loading ? { display: "none" } : {}}>{createImagesFromUrls(props.picUrls)}</div>
          </Masonry>
        </Padding>
      </div>
    );
  };

  const renderGallery = () => {
    return (
      <ModalImageGallery
        index={galleryState.idx}
        onHide={() => setGalleryShown({ isGalleryShown: false, idx: 0 })}
        isGalleryShown={galleryState.isGalleryShown}
        images={props.picUrls}
      />
    );
  };

  const createImagesFromUrls = (picUrls: string[]): ReactNode[] => {
    return picUrls.map((url, idx) => {
      const img = idx === picUrls.length - 1 ? createLastImageFromUrl(url) : createImageFromUrl(url);
      return (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setGalleryShown({ isGalleryShown: true, idx: idx })}
          key={url + "--" + Math.random().toString()}>
          {img}
        </div>
      );
    });
  };

  const createImageFromUrl = (picUrl: string): ReactNode => {
    return (
      <div>
        <Image fluid className="grid-item" src={picUrl} />
      </div>
    );
  };

  const createLastImageFromUrl = (picUrl: string): ReactNode => {
    return (
      <div>
        <Image fluid src={picUrl} className="grid-item" />
      </div>
    );
  };

  return render();
}
