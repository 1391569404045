import { CHANGE_LANGUAGE, SWITCH_THEME, RESET } from "store/actions/action_types";
import { ConstantsState } from "./stateTypes";
import strings from "assets/strings/strings";
import { postalCodes } from "assets/geo/postalCodeToZones";
import { zoneAdja } from "assets/geo/AdjacentZones";
import { Theme } from "assets/themes/theme";
import {
  loadThemeFromLocalStorage,
  saveThemeToLocalStorage,
  saveLanguageToLocalStorage,
  loadLanguageFromLocalStorage,
} from "services/local_storage/local_storage";
import darkTheme from "assets/themes/darkTheme";
import lightTheme from "assets/themes/lightTheme";
import { communeFromLocalite } from "assets/geo/LocaliteToCommune";

const initialState: ConstantsState = {
  strings: loadLanguageFromLocalStorage(),
  postalCode: postalCodes,
  zoneAdja: zoneAdja,
  localiteData: communeFromLocalite,
  theme: loadThemeFromLocalStorage(),
};

function ConstantsReducer(state = initialState, action: any) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      let lang: string = action.lang;
      saveLanguageToLocalStorage(lang);
      return {
        ...state,
        strings: strings[lang],
      };
    case SWITCH_THEME:
      let wasDarkTheme: boolean = state.theme.id === darkTheme.id;
      let newTheme: Theme = wasDarkTheme ? lightTheme : darkTheme;
      saveThemeToLocalStorage(newTheme);
      return {
        ...state,
        theme: newTheme,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return { ...state };
  }
}

export default ConstantsReducer;
