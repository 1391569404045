import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

function CookieBar() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I accept"
      cookieName="cookie-bar"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}>
      This website uses cookies to enhance the user experience.{" "}
      <Link to="/cookie-policy" style={{ fontSize: "10px" }} id="cookie-policy-link">
        Check our cookie policy
      </Link>
    </CookieConsent>
  );
}

export default CookieBar;
