import React from "react";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";
import ReactBnbGallery from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";
import "./modal-image-gallery.css";

type Props = BasicProps & {
  isGalleryShown: boolean;
  images: string[];
  index: number;
  onHide: () => any;
};

ModalImageGallery.defaultProps = {
  ...basicDefaultProps,
};

function ModalImageGallery(props: Props) {
  return (
    <ReactBnbGallery
      show={props.isGalleryShown}
      photos={props.images}
      onClose={props.onHide}
      activePhotoIndex={props.index}
    />
  );
}

export default ModalImageGallery;
