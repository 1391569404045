import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./main/App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { DataAccessor } from "./services/firestore/DataAccessor";
import { FirestoreService } from "./services/firestore/FirestoreService";
import { ServiceProvider } from "./services/ServiceProvider";
import { firestore } from "./firebaseConfig";
import { BookingSystem } from "services/booking_system/BookingSystem";
import { BookingSystemService } from "services/booking_system/BookingSystemService";
import store from "store/store";
import ControllerFactory from "services/factory/ControllerFactory";
import ControllerFactoryImpl from "services/factory/ControllerFactoryImpl";

const dataAccessor: DataAccessor = new FirestoreService(firestore);
const bookingSystem: BookingSystem = new BookingSystemService();
const controllerFactory: ControllerFactory = new ControllerFactoryImpl();
ServiceProvider.init(dataAccessor, bookingSystem, controllerFactory);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
