import { Theme } from "./theme";
import R from "assets/R";
import Colors from "ui/graphic_helpers/Colors";
import GradientColor from "ui/graphic_helpers/GradientColor";
import BoxShadow from "ui/graphic_helpers/BoxShadow";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";

const darkTheme: Theme = {
  id: "dark",
  logo: R.IMAGES.logoTurquoisWhite,
  themeName: "Bedrijf",
  mainTextColor: Colors.white,
  secondaryTextColor: Colors.white80,
  headerGradient: new GradientColor({
    angle: 5,
    colorA: Colors.anthracite,
    colorB: Colors.almostBlack,
    percentA: 0,
    percentB: 74,
  }),
  sectionGradient: new GradientColor({
    angle: 0,
    colorA: Colors.darkAnthracite,
    colorB: Colors.lightBlack,
    percentA: 0,
    percentB: 50,
  }),
  turquoisGradient: new GradientColor({
    angle: 45,
    colorA: Colors.lightTurquoise,
    colorB: Colors.turquoise,
    percentA: 0,
    percentB: 50,
  }),
  navBarColor: Colors.black,
  navBarTextColor: Colors.white,
  navBarVariant: "dark",
  footerColor: Colors.black,
  footerTextColor: Colors.white,
  backgroundColorA: Colors.lightBlack,
  backgroundColorB: Colors.darkAnthracite,
  accentColor: Colors.turquoise,
  materialCardColor: Colors.darkAnthracite,
  cardDropShadow: BoxShadow.fromColorAndBlur(Colors.black45, 15),
  bigCardDropShadow: BoxShadow.fromColorAndBlur(Colors.black.withAlpha(0.3), 25),
  individualsCardStyle: {
    padding: EdgeInsets.fromLTRB(2, 8, 2, 8),
    fontSize: 20,
    iconSize: 80,
    col: "col-5",
    zIndex: 0,
    key: "small",
  },
  companiesCardStyle: {
    col: "col-7",
    zIndex: 1,
    iconSize: 120,
    fontSize: 28.32,
    padding: EdgeInsets.fromLTRB(3, 12.11, 3, 12.11),
    key: "big",
  },
};

export default darkTheme;
