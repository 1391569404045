import React, { useEffect } from "react";
import "../guest_website.css";
import "index.css";
import Header from "../sections/Header";
import ServicesSection from "../sections/ServicesSection";
import RealisationsSection from "../sections/RealisationsSection";
import AboutUsSection from "../sections/AboutUsSection";

function HomePage() {
  let refs: {
    [key: string]: React.RefObject<any>;
  } = {
    "#services": React.createRef(),
    "#realisations": React.createRef(),
  };

  
  useEffect(() => {
    if (window.location.hash !== "") {
      //TODO: FUCKING SMOOTH SCROLL PRECISION ?
      refs[window.location.hash].current.scrollIntoView({
        //behavior: "smooth",
      });
    }
    return () => {};
  // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Header />
      <ServicesSection reference={refs["#services"]} />
      <RealisationsSection reference={refs["#realisations"]} />
      <AboutUsSection />
    </div>
  );
}

export default HomePage;
