import React, { ReactNode } from "react";
import SectionTitle from "ui/generic_components/SectionTitle";
import Divider from "ui/generic_components/Divider";
import Center from "ui/generic_components/Center";
import { Container, Row, Col } from "react-bootstrap";
import WorkTypeCard from "../widgets/WorkTypeCard";
import { ShapeDivider, DividerShapes } from "ui/generic_components/ShapeDivider";
import SectionSeparator from "ui/generic_components/SectionSeparator";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import R from "assets/R";
import { ConstantsState } from "store/reducers/stateTypes";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import Padding from "ui/generic_components/Padding";
import Text from "ui/generic_components/Text";
import { FontWeight, TextAlignment } from "ui/graphic_helpers/graphic_enums";
import Colors from "ui/graphic_helpers/Colors";
import { Link } from "react-router-dom";
import MaterialButton from "ui/generic_components/MaterialButton";
import GradientColor from "ui/graphic_helpers/GradientColor";

RealisationsSection.defaultProps = basicDefaultProps;

function RealisationsSection(props: BasicProps) {
  const { strings, theme }: ConstantsState = useSelector((state: RootState) => state.constants);

  return (
    <div ref={props.reference}>
      <div style={{ backgroundImage: theme.sectionGradient.toString() }} id="realisations-section">
        <SectionSeparator />
        <SectionTitle title={strings.our_realisations} color={theme.mainTextColor} />
        <Center>
          <Divider color={theme.accentColor} />
        </Center>
        {renderIntroText()}
        <Divider height={2} />
        <Row>
          <Col />
          <Col>{theme.id == "light" ? renderIndividualsCard() : renderCompaniesCard()}</Col>
          <Col />
          <SectionSeparator />
        </Row>
        <Divider height={3} />
        <Container>{renderAlternateWork()}</Container>
        <Divider height={3} />
      </div>
      {renderShapeDivider()}
    </div>
  );

  function renderIntroText() {
    return (
      <Container>
      <Text
        text={strings.realisaties}
        color={theme.secondaryTextColor}
        textAlign={TextAlignment.Center}
      />
      </Container>
    );
  }

  function renderAlternateWork() {
    return (
      <>
        <Text
          text={theme.id == "light" ? strings.realisaties_private : strings.realisaties_company}
          color={theme.secondaryTextColor}
          textAlign={TextAlignment.Center}
        />
        <Divider height={1} />
        <Link to={theme.id == "light" ? "realisations/companies" : "realisations/individuals"}>
          <MaterialButton
            padding={EdgeInsets.symetricHorizontalVertical(2, 1)}
            onClick={(_) => {}}
            id="reservation-button"
            color={GradientColor.from2ColorsHorizontal(Colors.turquoise, Colors.lightTurquoise)}
            className="hvr-shrink cursor-pointer">
            <Text
              text={
                theme.id == "light"
                  ? strings.realisaties_private_button
                  : strings.realisaties_company_button
              }
              textAlign={TextAlignment.Center}
              color={Colors.black}
              fontWeight={FontWeight.w500}
              fontSize={16}
            />
          </MaterialButton>
        </Link>
      </>
    );
  }

  function renderShapeDivider(): ReactNode {
    return (
      <ShapeDivider
        shape={DividerShapes.TriShadowAsymetricTriangle}
        horizontalFlip={true}
        height={150}
        shapeColor={theme.backgroundColorB}
        dividerColor={theme.backgroundColorA}
      />
    );
  }

  function renderWorkTypeCards(): ReactNode {
    return (
      <Container>
        <Row className="align-items-center" style={{ margin: 0 }}>
          <Col
            className={"col-12 col-lg-6 " + theme.individualsCardStyle.col}
            style={{ zIndex: theme.individualsCardStyle.zIndex, paddingBottom: "30px" }}>
            {renderIndividualsCard()}
          </Col>
          <Col
            className={"col-12 col-lg-6 " + theme.companiesCardStyle.col}
            style={{ zIndex: theme.companiesCardStyle.zIndex, paddingBottom: "30px" }}>
            {renderCompaniesCard()}
          </Col>
        </Row>
      </Container>
    );
  }

  function renderIndividualsCard(): ReactNode {
    return (
      <WorkTypeCard
        title={strings.individuals}
        id="work-individual-card"
        destinationUrl="individuals"
        iconName="archive"
        imageName={R.IMAGES.particularRealisation}
        padding={theme.individualsCardStyle.padding}
        fontSize={theme.individualsCardStyle.fontSize}
        iconSize={theme.individualsCardStyle.iconSize}
        key={theme.individualsCardStyle.key}
      />
    );
  }

  function renderCompaniesCard(): ReactNode {
    return (
      <WorkTypeCard
        title={strings.companies}
        id="work-companies-card"
        destinationUrl="companies"
        iconName="archive"
        imageName={R.IMAGES.companyRealisation}
        padding={theme.companiesCardStyle.padding}
        fontSize={theme.companiesCardStyle.fontSize}
        iconSize={theme.companiesCardStyle.iconSize}
        key={theme.individualsCardStyle.key}
      />
    );
  }
}

export default RealisationsSection;
