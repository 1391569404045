import { Strings } from "assets/strings/strings";
import DateUtils from "guest_website/helpers/DateUtils";
import { CalendarSlot } from "model/calendarSlot";
import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import { basicDefaultProps, BasicProps } from "ui/BasicProps";
import Divider from "ui/generic_components/Divider";
import HorizontalListView from "ui/generic_components/HorizontalListView";
import Text from "ui/generic_components/Text";
import Colors from "ui/graphic_helpers/Colors";
import { FontWeight, TextAlignment } from "ui/graphic_helpers/graphic_enums";
import BookingSlot from "./BookingSlot";

type Props = BasicProps & {
  firstDate: Date;
  selectedDate?: Date;
  selectedSlot: CalendarSlot;
  daySlotList: CalendarSlot[][];
  isSlotSelected: boolean;
  onSlotChanged(slot: CalendarSlot): void;
};

BookingWidget.defaultProps = {
  ...basicDefaultProps,
};

function BookingWidget(props: Props) {
  const strings: Strings = useSelector((state: RootState) => state.constants.strings);

  let dayStrings = [
    strings.day_abbr_sun,
    strings.day_abbr_mon,
    strings.day_abbr_tue,
    strings.day_abbr_wed,
    strings.day_abbr_thu,
    strings.day_abbr_fri,
    strings.day_abbr_sat,
  ];

  return (
    <>
      <HorizontalListView itemCount={7} builder={buildSlot} />
    </>
  );

  function buildSlot(idx: number): ReactElement {
    let date: Date = new Date(props.firstDate);
    date.setDate(props.firstDate.getDate() + idx);
    let afternoonDate = new Date(date);
    afternoonDate.setHours(15);
    let morningDate = new Date(date);
    morningDate.setHours(8);
    return (
      <div style={{ width: "100%" }}>
        <Divider height={2} />
        {renderDayTitle(date)}
        <Divider height={0.8} />
        {renderSlot(props.daySlotList[idx][0])}
        <Divider height={0.05} />
        {renderSlot(props.daySlotList[idx][1])}
      </div>
    );

    function renderDayTitle(date: Date) {
      return (
        <Text
          color={Colors.blackText}
          fontSize={16}
          textAlign={TextAlignment.Center}
          fontWeight={FontWeight.w600}
          text={dayStrings[date.getDay()]}
        />
      );
    }

    function renderSlot(slot: CalendarSlot) {
      let isMorning: boolean = !slot.isAfternoon;
      return (
        <BookingSlot
          onClicked={() =>
            slot.isAvailable
              ? props.onSlotChanged(slot)
              : {
                  /* PROPS THAT WILL DISPLAY NOT AVAILABLE MESSAGE*/
                }
          }
          className={isMorning ? "hvr-float" : "hvr-sink"}
          date={slot.getDate()}
          isPreferred={slot.isPreferred}
          isDisabled={!slot.isAvailable}
          isSelected={
            props.isSlotSelected &&
            DateUtils.areDatesEqualWithDayPrecision(props.selectedSlot.getDate(), slot.getDate()) &&
            props.selectedSlot.isAfternoon === slot.isAfternoon
          }
          isMorning={isMorning}
        />
      );
    }
  }
}

export default BookingWidget;
