import { CalendarSlot } from "model/calendarSlot";
import { DayInfo } from "model/dayInfo";
import { Reservation } from "model/reservation";
import { ReservationsHandler } from "model/reservationsHandler";
import { Policy } from "./Policy";

export class ReservationsPerSlotPolicy extends ReservationsHandler implements Policy {
  MAX_RESA_MORNING: number = 4;
  MAX_RESA_AFTERNOON: number = 5;

  constructor(reservations: Reservation[]) {
    super(reservations);
  }
  isItPreferredSlot(slot: CalendarSlot, postalCode: string): boolean {
    const slotsRes = this.getSlotRes(slot.dayInfo.date, slot.isAfternoon);
    if (slot.isAfternoon) {
      return slotsRes.length === this.MAX_RESA_AFTERNOON - 1;
    } else {
      return slotsRes.length === this.MAX_RESA_MORNING - 1;
    }
  }
  doesReservationAgreeWithPolicy(slot: CalendarSlot, postalCode: string): boolean {
    const slotsRes = this.getSlotRes(slot.dayInfo.date, slot.isAfternoon);
    if (slot.isAfternoon) {
      return slotsRes.length < this.MAX_RESA_AFTERNOON;
    } else {
      return slotsRes.length < this.MAX_RESA_MORNING;
    }
  }
}
