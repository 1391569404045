import React from "react";
import TextPageHeader from "../sections/TextPageHeader";
import { Strings } from "assets/strings/strings";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import HeaderTextPage from "../widgets/HeaderTextPage";

function TermsOfSalePage() {
  const strings: Strings = useSelector((state: RootState) => state.constants.strings);
  return (
    <div id="terms-sale-page">
      <HeaderTextPage header={strings.terms_of_sale} text={strings.terms_of_sale_body} />
    </div>
  );
}

export default TermsOfSalePage;
