import React, { useState } from "react";
import EdgeInsets from "../graphic_helpers/EdgeInsets";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";

type Props = BasicProps & {
  padding: EdgeInsets;
  children: React.ReactElement | React.ReactElement[];
};

Padding.defaultProps = { ...basicDefaultProps, padding: EdgeInsets.zero() };

function Padding(props: Props) {
  const [style] = useState({ padding: props.padding.toString(), zIndex: props.zIndex });

  return <div style={style}>{props.children}</div>;
}

export default Padding;
