import React, { ReactElement } from "react";
import Material from "ui/generic_components/Material";
import Center from "ui/generic_components/Center";
import SVGIcon from "ui/generic_components/SVGIcon";
import Colors from "ui/graphic_helpers/Colors";
import Text from "ui/generic_components/Text";
import { FontWeight, TextAlignment } from "ui/graphic_helpers/graphic_enums";
import Divider from "ui/generic_components/Divider";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import BorderRadius from "ui/graphic_helpers/BorderRadius";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";
import { Link } from "react-router-dom";
import Padding from "ui/generic_components/Padding";
import SolidColor from "ui/graphic_helpers/SolidColor";
import { ConstantsState } from "store/reducers/stateTypes";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";

type Props = BasicProps & {
  title: string;
  iconName: string;
  color: SolidColor;
  link: string;
  text?: string;
};

ServiceCard.defaultProps = {
  ...basicDefaultProps,
  color: Colors.darkGrey,
  text: "",
};

function ServiceCard(props: Props) {
  const { theme }: ConstantsState = useSelector((state: RootState) => state.constants);

  function render() {
    return (
      <Padding padding={EdgeInsets.symetric({ vertical: 2 })}>
        <Link to={"/services/" + props.link} id={props.id} style={{ textDecoration: 'none' }}>
          <Material
            style={{ height: "260px", verticalAlign: "middle", alignItems: "center" }}
            className="hvr-bob align-items-center"
            cursorOnHover={true}
            padding={EdgeInsets.all(2)}
            borderRadius={BorderRadius.all(7)}
            color={props.color}
            boxShadow={theme.cardDropShadow}>
            {renderContent()}
          </Material>
        </Link>
      </Padding>
    );
  }

  function renderContent(): ReactElement {
    return (
      <>
        <SVGIcon color={theme.accentColor} size={50} name={props.iconName}></SVGIcon>
        <Divider height={1.25} />
        <Text
          color={theme.mainTextColor}
          text={props.title}
          fontWeight={FontWeight.w500}
          fontSize={18}
          textAlign={TextAlignment.Center}></Text>
        {props.text ? (
          <Padding padding={EdgeInsets.only({top: 0.6})}>
          <Text
            color={theme.mainTextColor}
            text={props.text}
            fontWeight={FontWeight.w400}
            fontSize={14}
            textAlign={TextAlignment.Center}></Text></Padding>
        ) : (
          <></>
        )}
      </>
    );
  }

  return render();
}

export default ServiceCard;
