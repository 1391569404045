import React, { ChangeEvent, useEffect } from "react";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";
import { ServiceProvider } from "services/ServiceProvider";
import { useDispatch, useSelector } from "react-redux";
import { sendMailFailed } from "store/actions/BookingSystemActions";
import { RootState } from "store/reducers/RootReducer";
import ContactFormView from "./ContactFormView";
import ContactFormController from "guest_website/controllers/ContactFormController";

type Props = BasicProps & {
  dismiss: () => void;
  goToBooking: () => void;
  isCompany: boolean;
};

ContactForm.defaultProps = {
  ...basicDefaultProps,
};
let controller: ContactFormController;

function ContactForm(props: Props) {
  const contactFormError = useSelector((state: RootState) => state.booking.contactFormError);
  useEffect(() => {
    console.log("in the contact");
    controller = ServiceProvider.getInstance().controllerFactory.createContactFormController({
      email: "",
      subject: "",
      companyName: "",
      message: "",
      isComplain: false,
      type: "quote",
      isCompany: props.isCompany,
    });
  }, []);

  const dispatch = useDispatch();

  return (
    <ContactFormView
      isCompany={props.isCompany}
      contactFormError={contactFormError}
      onCompanyNameChanged={onCompanyNameChanged}
      onEmailChanged={onEmailChanged}
      onMessageChanged={onMessageChanged}
      onSubjectChanged={onSubjectChanged}
      onComplainChanged={onComplainChanged}
      onTypeChanged={onTypeChanged}
      goToBookingSystem={props.goToBooking}
      onSend={onSend}
    />
  );

  function onCompanyNameChanged(event: ChangeEvent<HTMLTextAreaElement>) {
    const name = event.currentTarget.value;
    controller.onCompanyNameChanged(name);
  }

  function onEmailChanged(event: ChangeEvent<HTMLTextAreaElement>) {
    const email = event.currentTarget.value;
    controller.onEmailChanged(email);
  }

  function onSubjectChanged(event: ChangeEvent<HTMLTextAreaElement>) {
    const subject = event.currentTarget.value;
    controller.onSubjectChanged(subject);
  }

  function onMessageChanged(event: ChangeEvent<HTMLTextAreaElement>) {
    const message = event.currentTarget.value;
    controller.onMessageChanged(message);
  }

  function onComplainChanged(isComplain: boolean) {
    controller.onComplainChanged(isComplain);
  }

  function onTypeChanged(type: string) {
    controller.onTypeChanged(type);
  }

  async function onSend() {
    try {
      await controller.onSend();
      fbq('track', 'Contact');
      props.dismiss();
    } catch (e) {
      dispatch(sendMailFailed(e.message));
    }
  }
}

export default ContactForm;
