import React, { useState, CSSProperties } from "react";
import { Navbar, Nav, Modal, Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";
import "../guest_website.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import ContactForm from "../widgets/ContactForm";
import { changeLanguage, switchTheme } from "store/actions/ConstantsActions";
import { Language } from "assets/strings/strings";
import R from "assets/R";
import { ConstantsState } from "store/reducers/stateTypes";
import { resetSendMailError } from "store/actions/BookingSystemActions";
import Padding from "ui/generic_components/Padding";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import MaterialButton from "ui/generic_components/MaterialButton";
import GradientColor from "ui/graphic_helpers/GradientColor";
import Text from "ui/generic_components/Text";
import { TextAlignment, FontWeight } from "ui/graphic_helpers/graphic_enums";
import Colors from "ui/graphic_helpers/Colors";
import Switch from "ui/generic_components/Switch";
import Center from "ui/generic_components/Center";
import BorderRadius from "ui/graphic_helpers/BorderRadius";
import BoxShadow from "ui/graphic_helpers/BoxShadow";
import Border from "ui/graphic_helpers/Border";
import ZipCodeForm from "../widgets/ZipCodeForm";
import BookingForm from "../widgets/BookingForm";
import BookingModalManager from "../widgets/BookingModalManager";
import { useHistory, useParams } from "react-router-dom";
import Material from "ui/generic_components/Material";

function NavigationBar(props: any) {
  let isBooking = window.location.pathname === "/booking";
  let isHome = window.location.pathname === "/";
  console.log();
  const dispatch = useDispatch();
  const history = useHistory();
  const { strings, theme }: ConstantsState = useSelector((state: RootState) => state.constants);
  const [contactFormShown, setContactFormShown] = useState(false);
  const [zipCodeFormShown, setZipCodeFormShown] = useState(false);
  const [bookingSystemShown, setBookingSystemShown] = useState(isBooking);

  const hideContactForm = () => {
    setContactFormShown(false);
    dispatch(resetSendMailError());
  };
  const showContactForm = () => setContactFormShown(true);

  const hideZipCodeForm = () => setZipCodeFormShown(false);

  const showZipCodeForm = () => setZipCodeFormShown(true);

  const showBookingSystem = () => setBookingSystemShown(true);

  const canScrollToServices: boolean = useSelector(
    (state: RootState) => state.navigation.canScrollToServices
  );

  const canScrollToRealisations: boolean = useSelector(
    (state: RootState) => state.navigation.canScrollToRealisations
  );

  const linkStyle = { color: theme.navBarTextColor.toString() };
  const activeLanguageStyle: CSSProperties = {
    color: theme.navBarTextColor.toString(),
    fontWeight: "bolder",
  };
  const navStyle: CSSProperties = {
    backgroundColor: theme.navBarColor.toString(),
    paddingLeft: "4em",
    paddingRight: "4em",
  };

  function render() {
    return (
      <Navbar
        collapseOnSelect
        expand="xl"
        sticky="top"
        style={navStyle}
        variant={theme.navBarVariant}>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {renderBrand()}
          {renderLinks()}
          <Padding padding={EdgeInsets.only({ right: 1 })} />
          {renderThemeSwitcher()}
          {renderLanguagePicker()}
        </Navbar.Collapse>
        {setupContactFormModal()}
        <BookingModalManager
          startBooking={bookingSystemShown}
          onContact={onContact}
          onFinish={() => setBookingSystemShown(false)}
        />
      </Navbar>
    );
  }

  function renderBrand() {
    return (
      <Navbar.Brand href="/" id="brand-logo" style={linkStyle}>
        <img
          alt="phoenix artworks zandstraalfolie logo"
          src={R.IMAGES.logoOnly}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{" "}
        Phoenix Artworks
      </Navbar.Brand>
    );
  }

  function renderReservationButton() {
    return (
      <MaterialButton
        padding={EdgeInsets.symetricHorizontalVertical(1.2, 0.5)}
        onClick={showBookingSystem}
        id="reservation-button"
        color={GradientColor.from2ColorsHorizontal(Colors.turquoise, Colors.lightTurquoise)}
        className="margin-nav-button margin-top-button hvr-shrink cursor-pointer">
        {renderReservationText()}
      </MaterialButton>
    );
  }

  function renderReservationText() {
    return (
      <Text
        text={strings.reservation}
        textAlign={TextAlignment.Center}
        color={Colors.black}
        fontWeight={FontWeight.w500}
        fontSize={16}
      />
    );
  }

  function renderLinks() {
    return (
      <Nav className="mr-auto">
        <Padding padding={EdgeInsets.onlyRight(2)} />
        {renderHomeLink()}
        <Padding padding={EdgeInsets.onlyRight(1.5)} />
        {renderServicesLink()}
        <Padding padding={EdgeInsets.onlyRight(1.5)} />
        {renderRealisationsLink()}
        <Padding padding={EdgeInsets.onlyRight(1.5)} />
        {renderContactButton()}
        <Padding padding={EdgeInsets.onlyRight(1.5)} />
        {renderReservationButton()}
      </Nav>
    );
  }

  function renderHomeLink() {
    return (
      <Nav.Link href="/" id="home-link" style={linkStyle}>
        {strings.home}
      </Nav.Link>
    );
  }

  function renderServicesLink() {
    if (canScrollToServices) {
      return renderLink("services", strings.services);
    } else {
      return renderNavLink("services", strings.services);
    }
  }

  function renderRealisationsLink() {
    if (canScrollToRealisations) {
      return renderLink("realisations", strings.realisations);
    } else {
      return renderNavLink("realisations", strings.realisations);
    }
  }

  function renderLink(tag: string, text: string) {
    return (
      <Link
        id={`${tag}-link`}
        activeClass="active"
        className="cursor-pointer nav-link"
        to={`${tag}-section`}
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        style={linkStyle}>
        {text}
      </Link>
    );
  }

  function renderNavLink(tag: string, text: string) {
    return (
      <Nav.Link href={`/#${tag}`} id={`${tag}-link`} style={linkStyle}>
        {text}
      </Nav.Link>
    );
  }

  function renderContactButton() {
    return (
      <MaterialButton
        padding={EdgeInsets.symetricHorizontalVertical(1.2, 0.5)}
        onClick={showContactForm}
        id="contact-button"
        color={Colors.transparent}
        boxShadow={BoxShadow.none()}
        border={new Border(2.5, Colors.turquoise)}
        className="margin-nav-button hvr-shrink cursor-pointer">
        {renderContactText()}
      </MaterialButton>
    );
  }

  function renderContactText() {
    return (
      <Text
        text={strings.contact}
        textAlign={TextAlignment.Center}
        color={theme.mainTextColor}
        fontWeight={FontWeight.w500}
        fontSize={16}
      />
    );
  }

  function renderLanguagePicker() {
    return (
      <Padding padding={EdgeInsets.symetricHorizontal(0)}>
        <Center>
          <Row className="align-items-center" style={{ margin: 0 }}>
            {renderLanguageLink("NL", Language.nl)}
            {renderLanguageSeparator()}
            {renderLanguageLink("EN", Language.en)}
          </Row>
        </Center>
      </Padding>
    );
  }

  function renderLanguageLink(name: string, language: Language) {
    const isActive: boolean = strings.language_name === language;
    const languageLinkStyle = isActive
      ? activeLanguageStyle
      : { color: theme.secondaryTextColor.toString() };
    return (
      <Nav.Link style={languageLinkStyle} onClick={() => dispatch(changeLanguage(language))}>
        {name}
      </Nav.Link>
    );
  }

  function renderLanguageSeparator() {
    return <span style={{ color: theme.navBarTextColor.toString() }}>|</span>;
  }

  function setupContactFormModal() {
    return (
      <Modal backdrop="static" show={contactFormShown} onHide={hideContactForm}>
        <Modal.Header closeButton />{" "}
        <ContactForm
          isCompany={theme.themeName === "Bedrijf"}
          goToBooking={onBook}
          dismiss={hideContactForm}
        />
      </Modal>
    );
  }

  // function setUpZipCodeFormModal() {
  //   return (
  //     <Modal backdrop="static" show={zipCodeFormShown} onHide={hideZipCodeForm}>
  //       <Modal.Header closeButton /> <ZipCodeForm onContact={onContact} dismiss={hideZipCodeForm} />
  //     </Modal>
  //   );
  // }

  function onContact() {
    hideZipCodeForm();
    showContactForm();
  }

  function onBook() {
    hideContactForm();
    showBookingSystem();
    //showZipCodeForm();
  }

  function renderThemeSwitcher() {
    return (
      <Padding padding={EdgeInsets.symetricVertical(1)}>
        <Center>
          <Row className="align-items-center" style={{ margin: 0 }}>
            {renderSwitchTextIndicator("light", strings.individuals)}
            {renderSwitch()}
            {renderSwitchTextIndicator("dark", strings.companies)}
          </Row>
        </Center>
      </Padding>
    );
  }

  function renderSwitch() {
    return (
      <Switch
        width={55}
        value={theme.id == "dark"}
        buttonRadiusPercent={20}
        borderRadius={BorderRadius.all(7)}
        backgroundColor={Colors.turquoise}
        buttonColor={theme.navBarColor}
        activeColor={Colors.turquoise}
        onChange={(val) => {
          dispatch(switchTheme());
          if (!isHome) {
            history.push("/");
          }
        }}
      />
    );
  }

  function renderSwitchTextIndicator(themeId: string, textString: string) {
    return (
      <Padding padding={EdgeInsets.symetricHorizontal(0.8)}>
        <MaterialButton
          padding={EdgeInsets.symetric({ horizontal: 1, vertical: 0.5 })}
          onClick={() => {
            if (theme.id != themeId) {
              dispatch(switchTheme());
              if (!isHome) {
                history.push("/");
              }
            }
          }}
          color={Colors.transparent}
          border={new Border(2.5, theme.id == themeId ? Colors.turquoise : Colors.transparent)}
          boxShadow={BoxShadow.none()}>
          <Text
            className="small-font-mobile"
            text={textString}
            fontSize={16}
            color={theme.navBarTextColor.toString()}
            fontWeight={FontWeight.w600}
          />
        </MaterialButton>
      </Padding>
    );
  }

  return render();
}

export default NavigationBar;
