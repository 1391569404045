import R from "assets/R";
import DateUtils from "guest_website/helpers/DateUtils";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import { ConstantsState } from "store/reducers/stateTypes";
import { basicDefaultProps, BasicProps } from "ui/BasicProps";
import Divider from "ui/generic_components/Divider";
import MaterialButton from "ui/generic_components/MaterialButton";
import Padding from "ui/generic_components/Padding";
import Text from "ui/generic_components/Text";
import Colors from "ui/graphic_helpers/Colors";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import { FloatAlignment, FontWeight, TextAlignment } from "ui/graphic_helpers/graphic_enums";

type Props = BasicProps & {
  email: string;
  date: Date;
  isMorning: boolean;
  dismiss(): void;
  onConfirm(): void;
};

BookingConfirmation.defaultProps = {
  ...basicDefaultProps,
};

function BookingConfirmation(props: Props) {
  const { strings, theme }: ConstantsState = useSelector((state: RootState) => state.constants);

  return (
    <>
      <Padding padding={EdgeInsets.symetric({ vertical: 2, horizontal: 2 })}>
        <Divider height={2} />
        <img
          style={{ marginLeft: "auto", marginRight: "auto", display: "block" }}
          width={"30%"}
          src={R.IMAGES.logoTurquoisWhite}
          alt="Thank you"
        />
        <Divider height={2} />
        <Text
          textAlign={TextAlignment.Center}
          text={strings.thank_you + "!"}
          fontSize={26}
          fontWeight={FontWeight.w500}
        />
        <Divider height={2} />
        {renderDateText()}
        {renderEmailText()}
        <Divider height={2} />
        {renderFinishButton()}
      </Padding>
    </>
  );

  function renderDateText() {
    return (
      <p style={{ textAlign: "center", color: Colors.blackText.toString() }}>
        {strings.your_reservation + " "}
        <b style={{ color: Colors.blackText.toString() }}>
          {DateUtils.getStrDate(props.date) +
            ", " +
            (props.isMorning ? strings.morning : strings.afternoon)}
        </b>
      </p>
    );
  }

  function renderEmailText() {
    return (
      <p style={{ textAlign: "center", color: Colors.blackText.toString() }}>
        {strings.mail_confirmation}
        <b style={{ color: Colors.blackText.toString() }}>{props.email}</b>
      </p>
    );
  }

  function renderFinishButton() {
    return (
      <MaterialButton
        id="submit-booking-button"
        className="hvr-forward"
        color={Colors.turquoise}
        float={FloatAlignment.Right}
        onClick={props.onConfirm}>
        <Text text={strings.finish} color={Colors.white} />
      </MaterialButton>
    );
  }
}

export default BookingConfirmation;
