import { fireNameSpace } from "firebaseConfig";

export class DayInfo {
  public date: Date;
  public isMorningClose: boolean;
  public isAfternoonClose: boolean;
  public isDayPlanned: boolean;

  constructor(
    date: Date,
    isMorningClose: boolean,
    isAfternoonClose: boolean,
    isDayPlanned: boolean
  ) {
    this.date = date;
    this.isMorningClose = isMorningClose;
    this.isAfternoonClose = isAfternoonClose;
    this.isDayPlanned = isDayPlanned;
  }

  static empty() {
    return new this(new Date(), false, false, false);
  }

  static fromData(data: any): DayInfo {
    return new this(
      data.date.toDate(),
      data.isMorningClose,
      data.isAfternoonClose,
      data.isDayPlanned
    );
  }

  toMap(): any {
    return {
      date: fireNameSpace.Timestamp.fromMillis(this.date.getTime()),
      isMorningClose: this.isMorningClose,
      isAfternoonClose: this.isAfternoonClose,
      isDayPlanned: this.isDayPlanned,
    };
  }
}
