type BorderRadiusProps = {
  topLeft?: number;
  topRight?: number;
  bottomLeft?: number;
  bottomRight?: number;
};

class BorderRadius {
  topLeft: number = 0;
  topRight: number = 0;
  bottomLeft: number = 0;
  bottomRight: number = 0;

  constructor(tl: number, tr: number, br: number, bl: number) {
    this.topLeft = tl;
    this.topRight = tr;
    this.bottomLeft = bl;
    this.bottomRight = br;
  }

  static zero = () => new BorderRadius(0, 0, 0, 0);

  static all(radius: number) {
    return new BorderRadius(radius, radius, radius, radius);
  }

  static only(radiuses: BorderRadiusProps) {
    return new BorderRadius(
      radiuses.topLeft ?? 0,
      radiuses.topRight ?? 0,
      radiuses.bottomRight ?? 0,
      radiuses.bottomLeft ?? 0
    );
  }

  public toString() {
    return `${this.topLeft}px ${this.topRight}px ${this.bottomRight}px ${this.bottomLeft}px`;
  }
}

export default BorderRadius;
