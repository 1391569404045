import React, { ReactNode } from "react";
import SectionTitle from "ui/generic_components/SectionTitle";
import { Row, Container } from "react-bootstrap";
import Divider from "ui/generic_components/Divider";
import Center from "ui/generic_components/Center";
import ServiceCard from "../widgets/ServiceCard";
import SectionSeparator from "ui/generic_components/SectionSeparator";
import { ShapeDivider, DividerShapes } from "ui/generic_components/ShapeDivider";
import ColDimensions from "ui/graphic_helpers/ColDimensions";
import ResponsiveCol from "ui/generic_components/ResponsiveCol";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import R from "assets/R";
import { ConstantsState } from "store/reducers/stateTypes";
import Text from "ui/generic_components/Text";
import { TextAlignment } from "ui/graphic_helpers/graphic_enums";
import Padding from "ui/generic_components/Padding";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";

ServicesSection.defaultProps = basicDefaultProps;

function ServicesSection(props: BasicProps) {
  const { strings, theme }: ConstantsState = useSelector((state: RootState) => state.constants);

  return (
    <div ref={props.reference}>
      <div id="services-section" style={{ backgroundImage: theme.sectionGradient.toString() }}>
        <SectionSeparator />
        <Container>
          <SectionTitle title={strings.our_services} color={theme.mainTextColor} />
          <Padding padding={EdgeInsets.onlyBottom(2)}>
            <Text
              text={strings.services_description}
              color={theme.secondaryTextColor}
              textAlign={TextAlignment.Center}
            />
          </Padding>
          <Center>
            <Divider color={theme.accentColor} />
          </Center>
          {renderServices()}
        </Container>
        <Divider height={3} />
      </div>
      {renderShapeDivider()}
    </div>
  );

  function renderServices(): ReactNode {
    return theme.themeName === "Bedrijf" ? (
      <div>
        <Row className="align-items-center" style={{ margin: 0 }}>
          {/* {renderService(
            "service-1",
            R.ICONS.concept,
            strings.service_title_custom,
            "custom-projects"
          )} */}
          {renderService(
            "service-2",
            R.ICONS.lettering,
            strings.service_title_window,
            "raamfolies-belettering",
            strings.ramfolies_subtext
          )}
          {renderService(
            "service-1",
            R.ICONS.sandblasting,
            strings.service_title_sand,
            "zandstraalfolies-company",
            strings.sandblasting_subtext
          )}
          {/* {renderService(
            "service-3",
            R.ICONS.ad_sign,
            strings.service_title_signs,
            "reclameborden"
          )}
          {renderService("service-4", R.ICONS.bike, strings.service_title_bike, "motor")}
          {renderService("service-5", R.ICONS.wallpaper, strings.service_title_wall, "fotobehang")} */}
          {renderService(
            "service-6",
            R.ICONS.vehicle,
            strings.service_title_vehicle,
            "voertuig-wrapping",
            strings.belettering_subtext
          )}
        </Row>
      </div>
    ) : (
      <div>
        <Row
          className="align-items-center d-flex justify-content-center"
          style={{ marginLeft: "auto", marginRight: "auto" }}>
          {renderService(
            "service-1",
            R.ICONS.sandblasting,
            strings.service_title_sand,
            "zandstraalfolies",
            strings.service_excerpt_sand,
          )}
          {renderService("service-3", R.ICONS.wallpaper, strings.service_title_wall, "thema-raamdecoratie", strings.service_excerpt_wall)}
        </Row>
      </div>
    );
  }

  function renderService(id: string, icon: string, title: string, link: string, subText?: string) {
    return (
      <ResponsiveCol dimensions={ColDimensions.only({ defaultValue: 12, md: 6, lg: 4 })}>
        <ServiceCard
          id={id}
          title={title}
          iconName={icon}
          text={subText}
          color={theme.materialCardColor}
          link={link}
        />
      </ResponsiveCol>
    );
  }

  function renderShapeDivider() {
    return (
      <ShapeDivider
        shape={DividerShapes.TriShadowAsymetricTriangle}
        height={150}
        shapeColor={theme.backgroundColorB}
        dividerColor={theme.backgroundColorA}
      />
    );
  }
}

export default ServicesSection;
