import React, {useState } from "react";
import Padding from "./Padding";
import Color from "../graphic_helpers/Color";
import Colors from "../graphic_helpers/Colors";
import EdgeInsets from "../graphic_helpers/EdgeInsets";

type Props = {
  color: Color;
  thickNess: number;
  width: number;
  height: number;
};

Divider.defaultProps = { color: Colors.transparent, thickNess: 3, width: 60, height: 3 };

function Divider(props: Props) {
  const [style, ] = useState({
    background: props.color.toString(),
    height: props.thickNess,
    width: props.width,
  });

  return (
    <Padding padding={EdgeInsets.onlyBottom(props.height)}>
      <div style={style} />
    </Padding>
  );
}

export default Divider;
