import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "@firebase/storage";
import "firebase/analytics";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyC34PBtpkiPHOTgRlxLtC10A1PdOuFAezA",
  authDomain: "phoenixartworks-3edd7.firebaseapp.com",
  databaseURL: "https://phoenixartworks-3edd7.firebaseio.com",
  projectId: "phoenixartworks-3edd7",
  storageBucket: "phoenixartworks-3edd7.appspot.com",
  messagingSenderId: "288366838490",
  appId: "1:288366838490:web:06276211e07896072c1be3",
  measurementId: "G-XNBJ0E9E4Q",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const firestore = firebase.firestore();
const fireNameSpace = firebase.firestore;
export declare namespace FS {
  type FieldValue = firebase.firestore.FieldValue;
  type Firestore = firebase.firestore.Firestore;
  type Timestamp = firebase.firestore.Timestamp;
}

export { firestore, fireNameSpace };
