import { SEND_MAIL_FAILED, RESET_SEND_MAIL_ERROR, CONFIRM_ZIP_CODE_ERROR } from "./action_types";

export function sendMailFailed(message: string) {
  return { type: SEND_MAIL_FAILED, message }
}

export function resetSendMailError() {
  return { type: RESET_SEND_MAIL_ERROR } 
}

export function confirmZipCodeError(message: string) {
  return { type: CONFIRM_ZIP_CODE_ERROR, message }
}