import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "guest_website/components/pages/HomePage";
import RealisationsPage from "guest_website/components/pages/RealisationsPage";
import ServicePage from "guest_website/components/pages/ServicePage";
import CookiePolicyPage from "guest_website/components/pages/CookiePolicyPage";
import PrivacyPolicyPage from "guest_website/components/pages/PrivacyPolicyPage";
import TermsOfSalePage from "guest_website/components/pages/TermsOfSalePage";
import CookieBar from "guest_website/components/widgets/CookieBar";
import Footer from "guest_website/components/sections/Footer";
import NavigationBar from "guest_website/components/sections/NavigationBar";
import { useDispatch } from "react-redux";
import * as navActions from "store/actions/NavigationActions";

function ApplicationRoutes() {
  const dispatch = useDispatch();
  console.log("totoo");
  return (
    <div className="App">
      <NavigationBar />

      <Switch>
        {cookiePolicyRoute()}
        {privacyPolicyRoute()}
        {termsOfSaleRoute()}
        {homeRoute()}
        {homeBooking()}
        {individualRealisationsRoute()}
        {companyRealisationsRoute()}
        {servicesRoutes()}
      </Switch>
      <CookieBar />
      <Footer />
    </div>
  );

  function homeRoute() {
    return (
      <Route
        exact
        path="/"
        render={() => {
          dispatch(navActions.navigateToHome());
          return <HomePage />;
        }}
      />
    );
  }

  function homeBooking() {
    return (
      <Route
        exact
        path="/booking"
        render={() => {
          dispatch(navActions.navigateToHome());
          return <HomePage />;
        }}
      />
    );
  }

  function individualRealisationsRoute() {
    return (
      <Route
        exact
        path="/realisations/individuals"
        render={() => {
          dispatch(navActions.navigateToRealisations());
          return (
            <RealisationsPage
              title="work-individual-title"
              photoLocation="individual-realisations"
            />
          );
        }}
      />
    );
  }

  function companyRealisationsRoute() {
    return (
      <Route
        exact
        path="/realisations/companies"
        render={() => {
          dispatch(navActions.navigateToRealisations());
          return (
            <RealisationsPage photoLocation="company-realisations" title="work-companies-title" />
          );
        }}
      />
    );
  }

  function servicesRoutes() {
    return (
      <>
        <Route
          exact
          path="/services/service"
          render={() => {
            dispatch(navActions.navigateToService());
            return <ServicePage />;
          }}
        />
        <Route
          exact
          path="/services/custom-projects"
          render={() => {
            dispatch(navActions.navigateToService());
            return (
              <ServicePage
                serviceID="custom-projects"
                imgBottom="/resources/images/kerremans.jpg"
                imgTop="/resources/images/bosman.jpg"
              />
            );
          }}
        />
        <Route
          exact
          path="/services/zandstraalfolies"
          render={() => {
            dispatch(navActions.navigateToService());
            return (
              <ServicePage
                serviceID="sandblasting-films"
                imgBottom="/resources/images/sand-bottom.jpg"
                imgTop="/resources/images/sand-top.jpg"
              />
            );
          }}
        />
        <Route
          exact
          path="/services/zandstraalfolies-company"
          render={() => {
            dispatch(navActions.navigateToService());
            return (
              <ServicePage
                serviceID="sandblasting-films-company"
                imgBottom="/resources/images/f1.jpg"
                imgTop="/resources/images/f2.JPG"
              />
            );
          }}
        />
        
        <Route
          exact
          path="/services/raamfolies-belettering"
          render={() => {
            dispatch(navActions.navigateToService());
            return (
              <ServicePage serviceID="raamfolies" imgBottom="/resources/images/belettering.jpg" />
            );
          }}
        />
        <Route
          exact
          path="/services/reclameborden"
          render={() => {
            dispatch(navActions.navigateToService());
            return (
              <ServicePage
                serviceID="reclameborden"
                imgBottom="/resources/images/platen.jpg"
                imgTop="/resources/images/wegwijzer.jpg"
              />
            );
          }}
        />
        <Route
          exact
          path="/services/motor"
          render={() => {
            dispatch(navActions.navigateToService());
            return <ServicePage serviceID="motor" />;
          }}
        />
        <Route
          exact
          path="/services/thema-raamdecoratie"
          render={() => {
            dispatch(navActions.navigateToService());
            return (
              <ServicePage
                serviceID="fotobehang"
                imgBottom="/resources/images/Wedding.png"
                imgTop="/resources/images/Geboortesticker.jpg"
              />
            );
          }}
        />
        <Route
          exact
          path="/services/voertuig-wrapping"
          render={() => {
            dispatch(navActions.navigateToService());
            return (
              <ServicePage
                serviceID="voertuig-wrapping"
                imgBottom="/resources/images/car_voertuig_2.jpg"
                imgTop="/resources/images/car_voertuig_1.jpg"
              />
            );
          }}
        />
        <Route
          exact
          path="/services/kleurenfolies"
          render={() => {
            dispatch(navActions.navigateToService());
            return <ServicePage serviceID="kleurenfolies" />;
          }}
        />
      </>
    );
  }

  function cookiePolicyRoute() {
    return (
      <Route
        exact
        path="/cookie-policy"
        render={() => {
          dispatch(navActions.navigateToCookie());
          return <CookiePolicyPage />;
        }}
      />
    );
  }

  function privacyPolicyRoute() {
    return (
      <Route
        exact
        path="/privacy-policy"
        render={() => {
          dispatch(navActions.navigateToPrivacy());
          return <PrivacyPolicyPage />;
        }}
      />
    );
  }

  function termsOfSaleRoute() {
    return (
      <Route
        exact
        path="/terms-of-sale"
        render={() => {
          dispatch(navActions.navigateToTerms());
          return <TermsOfSalePage />;
        }}
      />
    );
  }
}

export default ApplicationRoutes;
