import { DataAccessor } from "./DataAccessor";
import { fireNameSpace, FS } from "firebaseConfig";
import fsConstants from "./fsConstants";
import ContactFormData from "services/booking_system/ContactFormData";
import { Reservation } from "model/reservation";
import { DayInfo } from "model/dayInfo";
import { start } from "repl";

export class FirestoreService implements DataAccessor {
  private firestore: FS.Firestore;

  constructor(firestore: FS.Firestore) {
    this.firestore = firestore;
  }

  async getRealisationsPhotonames(docName: string): Promise<string[]> {
    try {
      let snapshot = await this.firestore
        .collection(fsConstants.collections.realisations)
        .doc(docName)
        .get();
      return snapshot.data()!.photoNames.map((e: string) => docName + "/" + e);
    } catch (e) {
      console.log(e);
      throw new Error("Could not get photonames");
    }
  }

  async sendMail(data: ContactFormData): Promise<void> {
    console.log("sendMail");
    try {
      await this.firestore
        .collection(fsConstants.collections.contactMails)
        .doc(data.email + new Date().toUTCString())
        .set(data);
    } catch (e) {
      console.error(e);
      throw new Error("Could not send email");
    }
  }

  async addReservation(reservation: Reservation): Promise<void> {
    console.log("add reservation");
    try {
      await this.firestore
        .collection(fsConstants.collections.reservations)
        .doc()
        .set(reservation.toMap());
      await this.firestore
        .collection(fsConstants.collections.bookingMails)
        .doc()
        .set({
          address: reservation.address + ", " + reservation.postalCode,
          date: fireNameSpace.Timestamp.fromMillis(reservation.date.getTime()),
          isAfternoon: reservation.isAfternoon,
          remarks: reservation.remarks,
          name: reservation.name,
          surname: reservation.surname,
          mail: reservation.mail,
        });
    } catch (e) {
      console.error(e);
      throw new Error("Could not add reservation");
    }
  }

  async getDaysInfo(startDate: Date, endDate: Date): Promise<DayInfo[]> {
    console.log("get days info between dates" + startDate + " and " + endDate);
    try {
      let query = await this.firestore
        .collection(fsConstants.collections.dayInfos)
        .where("date", ">=", fireNameSpace.Timestamp.fromMillis(startDate.getTime()))
        .where("date", "<=", fireNameSpace.Timestamp.fromMillis(endDate.getTime()))
        .get();
      return query.docs.map((doc: firebase.firestore.DocumentData) => DayInfo.fromData(doc.data()));
    } catch (e) {
      console.error(e);
      throw new Error("Could not get days info");
    }
  }

  async getReservationsBetweenDates(startDate: Date, endDate: Date): Promise<Reservation[]> {
    console.log("get reservations between dates " + startDate + " and " + endDate);
    try {
      let query = await this.firestore
        .collection(fsConstants.collections.reservations)
        .where("date", ">=", fireNameSpace.Timestamp.fromMillis(startDate.getTime()))
        .where("date", "<=", fireNameSpace.Timestamp.fromMillis(endDate.getTime()))
        .get();
      var reservations = query.docs.map((doc: firebase.firestore.DocumentData) =>
        Reservation.fromData(doc.data())
      );
      return reservations;
    } catch (e) {
      console.error(e);
      throw new Error("Could not get reservations");
    }
  }
}
