import { DayInfo } from "./dayInfo";

export class CalendarSlot {
  dayInfo: DayInfo;
  isAfternoon: boolean;
  isAvailable: boolean;
  isPreferred: boolean;
  preferenceLevel: number;
  zone: string;

  constructor(dayInfo: DayInfo, isAfternoon: boolean) {
    this.dayInfo = dayInfo;
    this.isAfternoon = isAfternoon;
    this.isAvailable = false;
    this.isPreferred = false;
    this.preferenceLevel = 0;
    this.zone = "0";
  }

  public static empty() {
    return new this(DayInfo.empty(), true);
  }

  public getDate() {
    return this.dayInfo.date;
  }
}
