import { communeFromLocalite, LocaliteInfo } from "assets/geo/LocaliteToCommune";
import { GeoData, postalCodes, PostalInfo } from "assets/geo/postalCodeToZones";
import ZipCodeFormController from "./ZipCodeFormController";

export default class ZipCodeFormControllerImpl implements ZipCodeFormController {
  public zipCode: string;
  public localiteInfo: LocaliteInfo;

  constructor(zip?: string, localiteInfo?: LocaliteInfo, communeInfo?: PostalInfo) {
    if (!zip) {
      this.zipCode = "";
    } else {
      this.zipCode = zip;
    }
    if (!localiteInfo) {
      this.localiteInfo = { commune: "", commune_code: "" };
    } else {
      this.localiteInfo = localiteInfo;
    }
  }

  onZipChanged(zip: string): void {
    if (zip) this.zipCode = zip.trim();
    this.localiteInfo = communeFromLocalite[this.zipCode];
    if (this.localiteInfo) {
      this.zipCode = this.localiteInfo.commune_code;
    } else {
      this.localiteInfo = { commune: "", commune_code: "" };
    }
  }

  checkField(): boolean {
    return this.zipCode.length > 0;
  }

  checkZip(): boolean {
    if (this.localiteInfo) {
      if (this.localiteInfo.commune === "") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  onConfirm(): Promise<void> {
    throw new Error("Method not implemented.");
  }
}
