import React from "react";
import { Col } from "react-bootstrap";
import ColDimensions from "../graphic_helpers/ColDimensions";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";

type Props = BasicProps & {
  dimensions: ColDimensions;
};

ResponsiveCol.defaultProps = basicDefaultProps;

function ResponsiveCol(props: Props) {
  return <Col style={props.style} className={props.dimensions.toString()}>{props.children}</Col>;
}

export default ResponsiveCol;
