import React, { CSSProperties, ReactElement } from "react";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";
import { ConstantsState } from "store/reducers/stateTypes";
import { RootState } from "store/reducers/RootReducer";
import { useSelector } from "react-redux";
import Text from "ui/generic_components/Text";
import { Row } from "react-bootstrap";
import Divider from "ui/generic_components/Divider";
import Padding from "ui/generic_components/Padding";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import ResponsiveCol from "ui/generic_components/ResponsiveCol";
import ColDimensions from "ui/graphic_helpers/ColDimensions";
import { Image } from "react-bootstrap";
import Colors from "ui/graphic_helpers/Colors";
import { FontWeight, TextAlignment } from "ui/graphic_helpers/graphic_enums";

type Props = BasicProps & {
  img: string;
  showBooking: any;
  setBooking: any;
  alt: number;
};

GeneralInformationSection.defaultProps = {
  ...basicDefaultProps,
  alt: 0,
};

function GeneralInformationSection(props: Props) {
  const { strings, theme }: ConstantsState = useSelector((state: RootState) => state.constants);
  const divStyle: CSSProperties = { ...props.style };
  return (
    <div id="general-information-section" style={divStyle}>
      <Row style={{ margin: 0, padding: 0 }}>
        <ResponsiveCol
          style={{ margin: 0, padding: 0 }}
          dimensions={ColDimensions.only({ defaultValue: 12, lg: 6 })}>
          {props.alt === 0
            ? renderLeft()
            : props.alt === 1
            ? renderDisabled()
            : renderAlternatif1()}
        </ResponsiveCol>
        <ResponsiveCol
          style={{ margin: 0, padding: 0 }}
          dimensions={ColDimensions.only({ defaultValue: 12, lg: 6 })}>
          {renderRight()}
        </ResponsiveCol>
      </Row>
      {props.setBooking()}
    </div>
  );

  function renderDisabled() {
    return (
      <div
        style={{
          background: theme.backgroundColorA.toString(),
        }}></div>
    );
  }

  function renderAlternatif1() {
    return (
      <div
        style={{
          background: theme.backgroundColorA.toString(),
        }}>
        <Padding padding={EdgeInsets.only({ left: 2, right: 2, top: 4, bottom: 4 })}>
          {renderInterestedText(props.alt)}
        </Padding>
      </div>
    );
  }

  function renderLeft(): ReactElement {
    return (
      <div
        style={{
          background: theme.backgroundColorA.toString(),
        }}>
        <Padding padding={EdgeInsets.only({ left: 2, right: 2, top: 4, bottom: 4 })}>
          {renderInterestedText(props.alt)}
          <Divider height={3} />
          {renderRequestQuoteText()}
          <Divider height={1} />
          {renderAgreedTimeText()}
          <Divider height={1} />
          {renderAgendaIsFullText()}
        </Padding>
      </div>
    );
  }

  function renderRight() {
    return <Image alt="phoenix artworks raamfolie" fluid src={props.img} id="service-image" />;
  }

  function renderInterestedText(alt: number) {
    return (
      <>
        <Text fontSize={15} text={strings.service_interested_title} color={theme.mainTextColor} />
        <Divider height={1}></Divider>
        <Divider color={Colors.turquoise} height={2}></Divider>
        <Text
          fontSize={15}
          text={alt === 0 ? strings.service_interested_txt : strings.service_interested_alt_1}
          color={theme.mainTextColor}
        />
      </>
    );
  }

  function renderRequestQuoteText() {
    return (
      <>
        <Text
          fontSize={15}
          text={strings.service_how_to_request_title}
          color={theme.mainTextColor}
        />
        <Divider height={1}></Divider>
        <Divider color={Colors.turquoise} height={2}></Divider>
        <div style={{ textAlign: "left" }}>
          <span
            style={{
              fontWeight: FontWeight.normal,
              zIndex: props.zIndex,
              padding: 0,
              margin: 0,
              whiteSpace: "pre-wrap",
              textAlign: TextAlignment.Left,
              fontSize: 15,
              color: theme.mainTextColor.toString(),
            }}>
            {strings.service_how_to_request_1_txt}
          </span>

          <span
            className="cursor-pointer"
            onClick={(e: React.MouseEvent) => {
              props.showBooking();
            }}
            style={{
              fontWeight: FontWeight.normal,
              zIndex: props.zIndex,
              padding: 0,
              margin: 0,
              whiteSpace: "pre-wrap",
              textAlign: TextAlignment.Left,
              fontSize: 15,
              color: Colors.turquoise.toString(),
            }}>
            {strings.service_how_to_request_2_txt}
          </span>
          <span
            style={{
              fontWeight: FontWeight.normal,
              zIndex: props.zIndex,
              padding: 0,
              margin: 0,
              whiteSpace: "pre-wrap",
              textAlign: TextAlignment.Left,
              fontSize: 15,
              color: theme.mainTextColor.toString(),
            }}>
            {strings.service_how_to_request_3_txt}
          </span>
        </div>
      </>
    );
  }

  function renderAgreedTimeText() {
    return (
      <Text fontSize={15} text={strings.service_agreed_time_txt} color={theme.mainTextColor} />
    );
  }

  function renderAgendaIsFullText() {
    return (
      <Text fontSize={15} text={strings.service_full_agenda_txt} color={theme.mainTextColor} />
    );
  }
}

export default GeneralInformationSection;
