import React, { ReactNode, useState, CSSProperties, ReactElement } from "react";
import { Row, Col, Image, Modal } from "react-bootstrap";
import "main/App.css";
import { ShapeDivider, DividerShapes } from "ui/generic_components/ShapeDivider";
import Colors from "ui/graphic_helpers/Colors";
import Text from "ui/generic_components/Text";
import { FontWeight, TextAlignment } from "ui/graphic_helpers/graphic_enums";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import MaterialButton from "ui/generic_components/MaterialButton";
import GradientColor from "ui/graphic_helpers/GradientColor";
import ContactForm from "../widgets/ContactForm";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import { ConstantsState } from "store/reducers/stateTypes";
import { resetSendMailError } from "store/actions/BookingSystemActions";
import Border from "ui/graphic_helpers/Border";
import BoxShadow from "ui/graphic_helpers/BoxShadow";
import Divider from "ui/generic_components/Divider";
import { switchTheme } from "store/actions/ConstantsActions";
import ZipCodeForm from "../widgets/ZipCodeForm";
import BookingModalManager from "../widgets/BookingModalManager";
import Center from "ui/generic_components/Center";
import Padding from "ui/generic_components/Padding";

function Header() {
  const dispatch = useDispatch();
  const { strings, theme }: ConstantsState = useSelector((state: RootState) => state.constants);
  const [contactFormShown, setContactFormShown] = useState(false);
  const [zipCodeFormShown, setZipCodeFormShown] = useState(false);
  const [bookingSystemShown, setBookingSystemShown] = useState(false);

  const hideContactForm = () => {
    setContactFormShown(false);
    dispatch(resetSendMailError());
  };
  const showContactForm = () => setContactFormShown(true);

  const showBookingSystem = () => setBookingSystemShown(true);

  const hideZipCodeForm = () => setZipCodeFormShown(false);

  const showZipCodeForm = () => setZipCodeFormShown(true);

  const indicatorThemeTextStyle: CSSProperties = {
    fontSize: "17px",
    fontWeight: 400,
    color: theme.mainTextColor.toString(),
  };
  const switchButtonTextStyle: CSSProperties = {
    fontSize: "15px",
    fontWeight: 500,
    color: theme.mainTextColor.toString(),
  };

  return (
    <div style={{ backgroundImage: theme.headerGradient.toString() }}>
      <Row className="align-items-center" style={{ margin: 0 }}>
        <Col className="col-12 col-lg-6">{renderLeftSide()}</Col>
        <Col className="col-12 col-lg-6">{renderRightSide()}</Col>
      </Row>
      {renderShapeDivider()}
      {setupContactFormModal()}
      <BookingModalManager
        startBooking={bookingSystemShown}
        onContact={onContact}
        onFinish={() => setBookingSystemShown(false)}
      />
    </div>
  );

  function renderLeftSide(): ReactNode {
    return <Image fluid src={theme.logo} alt="phoenix artwork logo" id="main_logo" />;
  }

  function renderRightSide(): ReactNode {
    return (
      <div className="mr-5 p-5">
        {/* {renderThemeTextualIndicator()} */}
        {renderOldBrand()}
        <Divider height={1} />
        {renderTitleAndDescription()}
        {renderContactForm()}
      </div>
    );
  }

  function renderOldBrand() {
    return (
      <Center>
        <Row className="align-items-center">
          <Text text={strings.old_brand} color={theme.secondaryTextColor} fontSize={16} />
          <Padding padding={EdgeInsets.onlyRight(1)} />
          <img
            alt="zandstraalfolieopramen old logo"
            src="/resources/images/zandstraalfolieopramen.png"
            width="320"
            height="54"
            className="d-inline-block align-top"
          />
          <Text text={strings.header_old_provincies} color={theme.secondaryTextColor} fontSize={16} />
        </Row>
      </Center>
    );
  }

  function renderThemeSwitcher() {
    return (
      <>
        {renderThemeSwitchButton()}
        <Divider height={1} />
        {renderThemeTextualIndicator()}
      </>
    );
  }

  function renderThemeTextualIndicator() {
    return (
      <span style={indicatorThemeTextStyle}>
        {" "}
        {strings.current_theme_indication_text + " "}{" "}
        <b>{theme.id === "dark" ? strings.companies : strings.individuals}</b>{" "}
      </span>
    );
  }

  function renderThemeSwitchButton() {
    return (
      <MaterialButton
        onClick={() => {
          dispatch(switchTheme());
        }}
        className={"hvr-bounce-in"}
        color={Colors.transparent}
        border={new Border(3, Colors.turquoise)}
        boxShadow={BoxShadow.none()}>
        <span style={switchButtonTextStyle}>
          {" "}
          {strings.switch_to + " "}{" "}
          <b>{theme.id === "dark" ? strings.individuals : strings.companies}</b>{" "}
        </span>
      </MaterialButton>
    );
  }

  function renderTitleAndDescription(): ReactNode {
    return (
      <Row className="mb-5">
        {renderTitle()}
        {renderHeaderServices()}
        {renderDescription()}
      </Row>
    );
  }

  function getCompanyServices(): ReactElement {
    return (
      <Row id="company_services">
        <Text
          text={strings.header_zandstraalfolies}
          color={theme.secondaryTextColor}
          textAlign={TextAlignment.Center}
          fontSize={14}
        />
        <Padding padding={EdgeInsets.onlyRight(1)} />
        <Text
          text={strings.header_printfolie}
          color={theme.secondaryTextColor}
          textAlign={TextAlignment.Center}
          fontSize={14}
        />
        <Padding padding={EdgeInsets.onlyRight(1)} />
        <Text
          text={strings.header_stickers}
          color={theme.secondaryTextColor}
          textAlign={TextAlignment.Center}
          fontSize={14}
        />
        <Padding padding={EdgeInsets.onlyRight(1)} />
        <Text
          text={strings.header_belettering}
          color={theme.secondaryTextColor}
          textAlign={TextAlignment.Center}
          fontSize={14}
        />
      </Row>
    );
  }

  function getClientServices(): ReactElement {
    return (
      <Row id="client_services">
        <Text
          text={strings.header_zandstraalfolies}
          color={theme.secondaryTextColor}
          textAlign={TextAlignment.Center}
          fontSize={14}
        />
        <Padding padding={EdgeInsets.onlyRight(1)} />
        <Text
          text={strings.header_kleurenfolies}
          color={theme.secondaryTextColor}
          textAlign={TextAlignment.Center}
          fontSize={14}
        />
        <Padding padding={EdgeInsets.onlyRight(1)} />
        <Text
          text={strings.header_raamdecoratie}
          color={theme.secondaryTextColor}
          textAlign={TextAlignment.Center}
          fontSize={14}
        />
      </Row>
    );
  }

  function renderHeaderServices() {
    let services = theme.themeName === "Bedrijf" ? getCompanyServices() : getClientServices();
    return (
      <>
        <Col className="col-12">
          <Text
            text={strings.header_services_1}

            color={theme.secondaryTextColor}
            textAlign={TextAlignment.Center}
            fontSize={18}
          />
        </Col>
        <Divider height={3} />
        <Col>
          <Center>{services}</Center>
        </Col>
      </>
    );
  }

  function renderTitle(): ReactNode {
    return (
      <Col className="col-12">
        <h1
          className=" huge-title"
          id="header_title"
          style={{ color: theme.mainTextColor.toString()}}>
          <span style={{ textAlign: "center" }}>{strings.header_title}</span>
        </h1>
      </Col>
    );
  }

  function renderDescription(): ReactNode {
    return (
      <Col className="col-12">
        <Text
          fontSize={16}
          text={
            theme.themeName === "Bedrijf"
              ? strings.header_description_company
              : strings.header_description
          }
          color={theme.secondaryTextColor}
          textAlign={TextAlignment.Center}
        />
      </Col>
    );
  }

  function renderContactForm(): ReactNode {
    return (
      <Row className="align-items-center" style={{ margin: 0 }}>
        <Col className="col-1 col-md-2 col-lg-3 no-pad" />
        <Col className="col">{renderContactButton()}</Col>
        <Col className="col-1 col-md-2 col-lg-3 no-pad" />
      </Row>
    );
  }

  function renderContactButton() {
    return (
      <MaterialButton
        padding={EdgeInsets.symetricHorizontalVertical(2.5, 1.5)}
        onClick={showContactForm}
        id="contact"
        color={GradientColor.from2ColorsHorizontal(Colors.turquoise, Colors.lightTurquoise)}
        className="hvr-shrink cursor-pointer">
        {renderContactText()}
      </MaterialButton>
    );
  }

  function renderContactText() {
    return (
      <Text
        text={strings.contact_us_excl}
        textAlign={TextAlignment.Center}
        color={Colors.black}
        fontWeight={FontWeight.w600}
        fontSize={20}
      />
    );
  }

  function renderShapeDivider(): ReactNode {
    return (
      <ShapeDivider
        shape={DividerShapes.InvertedTriShadowAsymetricTriangle}
        dividerColor={Colors.transparent}
        height={150}
        horizontalFlip={true}
        shapeColor={theme.backgroundColorA}
      />
    );
  }

  function setupContactFormModal() {
    return (
      <Modal backdrop="static" show={contactFormShown} onHide={hideContactForm}>
        <Modal.Header closeButton />{" "}
        <ContactForm
          isCompany={theme.themeName === "Bedrijf"}
          goToBooking={onBook}
          dismiss={hideContactForm}
        />
      </Modal>
    );
  }

  function onContact() {
    hideZipCodeForm();
    showContactForm();
  }

  function onBook() {
    hideContactForm();
    showBookingSystem();
    //showZipCodeForm();
  }
}

export default Header;
