import ContactFormData from "../../services/booking_system/ContactFormData";
import ContactFormController from "./ContactFormController";
import { ServiceProvider } from "services/ServiceProvider";

export default class ContactFormControllerImpl implements ContactFormController {
  public formData: ContactFormData;
  constructor(data?: ContactFormData) {
    if (!data) {
      this.formData = {
        email: "",
        subject: "",
        companyName: "",
        message: "",
        isComplain: false,
        type: "quote",
        isCompany: false,
      };
    } else {
      this.formData = data;
    }
  }

  onCompanyNameChanged(name: string): void {
    if (name) this.formData.companyName = name;
  }

  onEmailChanged(email: string): void {
    if (email) this.formData.email = email;
  }
  onSubjectChanged(subject: string): void {
    if (subject) this.formData.subject = subject;
  }
  onMessageChanged(message: string): void {
    if (message) this.formData.message = message;
  }
  onComplainChanged(isComplain: boolean): void {
    if (isComplain !== null) this.formData.isComplain = isComplain;
  }
  onTypeChanged(type: string): void {
    if (type) this.formData.type = type;
  }

  async onSend(): Promise<void> {
    await ServiceProvider.getInstance().bookingSystem.sendMail({
      email: this.formData.email,
      subject: this.formData.subject,
      message: this.formData.message,
      isComplain: this.formData.isComplain,
      type: this.formData.type,
      isCompany: this.formData.isCompany,
      companyName: this.formData.companyName,
    });
  }
}
