import React from "react";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";

type Props = BasicProps & {
  label: string;
  text: string;
  labelClassName: string;
  textClassName: string;
};

LabeledText.defaultProps = {
  ...basicDefaultProps,
  labelClassName:"bold",
  textClassName:"",
}

function LabeledText(props: Props) {
  return (
    <div>
      <p>
        <span id={props.id} className={props.labelClassName} style={props.style}>
          {props.label}
        </span>
        <span className={props.textClassName} style={props.style}>{props.text}</span>
      </p>
    </div>
  );
}

export default LabeledText;
