import { CalendarSlot } from "model/calendarSlot";
import { DayInfo } from "model/dayInfo";
import { Policy } from "./Policy";

export class BreakDayPolicy implements Policy {
  isItPreferredSlot(slot: CalendarSlot, postalCode: string): boolean {
    return false;
  }
  doesReservationAgreeWithPolicy(slot: CalendarSlot, postalCode: string): boolean {
    if (slot.isAfternoon && slot.dayInfo.isAfternoonClose) {
      return false;
    } else if (!slot.isAfternoon && slot.dayInfo.isMorningClose) {
      return false;
    } else {
      return true;
    }
  }
}
