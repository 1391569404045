import { CalendarSlot } from "model/calendarSlot";
import BookingConfirmationFormData from "services/booking_system/BookingConfirmationFormData";
import BookingConfirmationFormDataChecking from "services/booking_system/BookingConfirmationFormDataChecking";
import BookingConfirmationFormController from "./BookingConfirmationFormController";

export default class BookingConfirmationFormControllerImpl
  implements BookingConfirmationFormController {
  public reservationSlot: CalendarSlot;
  public formData: BookingConfirmationFormData;

  constructor(slot: CalendarSlot, formData?: BookingConfirmationFormData) {
    this.reservationSlot = slot;
    this.formData = formData ?? {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      streetname: "",
      streetnumber: "",
      remark: "",
    };
  }

  areFieldsOk(): BookingConfirmationFormDataChecking {
    return {
      isFirstNameOk: this.formData.firstName.length > 0,
      isLastNameOk: this.formData.lastName.length > 0,
      isEmailOk: this.formData.email.length > 0,
      isPhoneOk: this.formData.phone.length > 0,
      isStreetnameOk: this.formData.streetname.length > 0,
      isStreetnumberOk: this.formData.streetnumber.length > 0,
    };
  }

  onFirstNameChanged(name: string): void {
    if (name) this.formData.firstName = name.trim();
  }

  onLastNameChanged(name: string): void {
    if (name) this.formData.lastName = name.trim();
  }

  onStreetnameChanged(name: string): void {
    if (name) this.formData.streetname = name.trim();
  }

  onStreetnumberChanged(number: string): void {
    if (number) this.formData.streetnumber = number.trim();
  }

  onEmailChanged(email: string): void {
    if (email) this.formData.email = email.trim();
  }

  onPhoneChanged(phone: string): void {
    if (phone) this.formData.phone = phone.trim();
  }

  onRemarkChanged(remark: string): void {
    if (remark) this.formData.remark = remark;
  }

  onConfirm(): Promise<void> {
    throw new Error("Method not implemented.");
  }

  getAddress(): string {
    return this.formData.streetname.trim();
    //return this.formData.streetname.trim() + " " + this.formData.streetnumber.trim();
  }
}
