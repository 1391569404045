import React, { ChangeEvent, useState } from "react";
import { Form, Tabs, Tab } from "react-bootstrap";
import Padding from "ui/generic_components/Padding";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";
import MaterialButton from "ui/generic_components/MaterialButton";
import Text from "ui/generic_components/Text";
import { FloatAlignment, FontWeight } from "ui/graphic_helpers/graphic_enums";
import Colors from "ui/graphic_helpers/Colors";
import MaterialTextField from "ui/generic_components/MaterialTextField";
import Divider from "ui/generic_components/Divider";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import "./ContactFormView.css";
import { ConstantsState } from "store/reducers/stateTypes";

type Props = BasicProps & {
  contactFormError: string;
  isCompany: boolean;
  onEmailChanged(event: ChangeEvent<HTMLTextAreaElement>): void;
  onCompanyNameChanged(event: ChangeEvent<HTMLTextAreaElement>): void;
  onSubjectChanged(event: ChangeEvent<HTMLTextAreaElement>): void;
  onMessageChanged(event: ChangeEvent<HTMLTextAreaElement>): void;
  onComplainChanged(isComplain: boolean): void;
  onTypeChanged(type: string): void;
  onSend(): Promise<void>;
  goToBookingSystem(): void;
};

ContactFormView.defaultProps = {
  ...basicDefaultProps,
};

function ContactFormView(props: Props) {
  const { strings, theme }: ConstantsState = useSelector((state: RootState) => state.constants);
  const [key, setKey] = useState("quote");
  var isComplain: boolean = false;

  const render = () => {
    return (
      <Padding padding={EdgeInsets.all(2)}>
        <Text text={strings.send_mail_title} fontSize={20} fontWeight={FontWeight.w500} />
        <Divider height={2} />
        {renderBookingButton()}
        {renderQuestionForm()}
        <Divider height={2} />
      </Padding>
    );
  };

  function renderBookingButton() {
    return (
      <MaterialButton
        className="hvr-shrink"
        color={Colors.turquoise}
        onClick={props.goToBookingSystem}>
        <Text text={strings.wish_to_have_quote} color={Colors.white} />
      </MaterialButton>
    );
  }

  // function renderTabs() {
  //   return (
  //     <Tabs
  //       style={{ flexDirection: "row" }}
  //       id="tab"
  //       className="turquoiseTab"
  //       activeKey={key}
  //       onSelect={(k: string) => {
  //         setKey(k);
  //         props.onTypeChanged(k);
  //       }}>
  //       <Tab eventKey="quote" title={strings.quote}>
  //         {renderQuoteForm()}
  //       </Tab>
  //       <Tab eventKey="question" title={strings.question}>
  //         {renderQuestionForm()}
  //       </Tab>
  //     </Tabs>
  //   );
  // }

  function renderQuoteForm() {
    return (
      <>
        <Divider height={2} />
        <Form>
          {renderEmailField()}
          {renderSubjectField()}
          {renderMessageField()}
          {renderErrorMessage()}
          {renderConfirmButton()}
        </Form>
      </>
    );
  }

  function renderQuestionForm() {
    return (
      <>
        <Divider height={2} />
        <Form>
          <Divider height={1} />
          {renderCompanyNameField()}
          {renderEmailField()}
          {renderSubjectField()}
          {renderMessageField()}
          {renderErrorMessage()}
          {renderComplainCheckBox()}
          {renderConfirmButton()}
        </Form>
      </>
    );
  }

  function renderComplainCheckBox() {
    return (
      <Form.Check
        type="checkbox"
        label={strings.this_is_complain}
        onChange={() => {
          isComplain = !isComplain;
          props.onComplainChanged(isComplain);
        }}
      />
    );
  }

  function renderCompanyNameField() {
    return props.isCompany ? (
      <Form.Group id="email" controlId="email">
        <MaterialTextField
          id="company-field"
          color={theme.accentColor}
          label={strings.send_company_hint}
          onChange={props.onCompanyNameChanged}
        />
      </Form.Group>
    ): (<Divider height={0}/>);
  }

  function renderEmailField() {
    return (
      <Form.Group id="email" controlId="email">
        <MaterialTextField
          id="email-field"
          color={theme.accentColor}
          label={strings.send_mail_address_hint}
          onChange={props.onEmailChanged}
        />
      </Form.Group>
    );
  }

  function renderSubjectField() {
    return (
      <Form.Group controlId="subject">
        <MaterialTextField
          color={theme.accentColor}
          id="subject-field"
          label={strings.send_mail_subject_hint}
          onChange={props.onSubjectChanged}
        />
      </Form.Group>
    );
  }

  function renderMessageField() {
    return (
      <Form.Group controlId="message">
        <MaterialTextField
          color={theme.accentColor}
          id="message-field"
          label={strings.send_mail_message_hint}
          onChange={props.onMessageChanged}
          lines={5}
        />
      </Form.Group>
    );
  }

  function renderErrorMessage() {
    return (
      <Text
        id={props.contactFormError ? "contact-error-message" : ""}
        text={props.contactFormError}
        color={Colors.errorRed}
      />
    );
  }

  function renderConfirmButton() {
    return (
      <MaterialButton
        id="submit-button"
        className="hvr-forward"
        color={Colors.turquoise}
        float={FloatAlignment.Right}
        onClick={props.onSend}>
        <Text text={strings.send_mail_button} color={Colors.white} />
      </MaterialButton>
    );
  }
  return render();
}

export default ContactFormView;
