import React from "react";
import Material from "ui/generic_components/Material";
import Padding from "ui/generic_components/Padding";
import Center from "ui/generic_components/Center";
import Colors from "ui/graphic_helpers/Colors";
import Text from "ui/generic_components/Text";
import { FontWeight, TextAlignment } from "ui/graphic_helpers/graphic_enums";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import BorderRadius from "ui/graphic_helpers/BorderRadius";
import "../guest_website.css";
import { Link } from "react-router-dom";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";
import { ConstantsState } from "store/reducers/stateTypes";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";

type Props = BasicProps & {
  title: string;
  iconName: string;
  destinationUrl: string;
  iconSize: number;
  fontSize: number;
  imageName: string;
  padding: EdgeInsets;
};

WorkTypeCard.defaultProps = {
  ...basicDefaultProps,
  iconSize: 120,
  fontSize: 28.32,
  padding: EdgeInsets.fromLTRB(3, 12.11, 3, 12.11),
};

function WorkTypeCard(props: Props) {

  const { theme }: ConstantsState = useSelector((state: RootState) => state.constants);
  
  return (
    <Material
      zIndex={props.zIndex}
      className="hvr-grow"
      cursorOnHover={true}
      color={Colors.darkGrey}
      borderRadius={BorderRadius.all(10)}
      boxShadow={theme.bigCardDropShadow}
      key={props.key}>
      {renderBody()}
    </Material>
  );

  function renderBody() {
    return (
      <Link to={"/realisations/" + props.destinationUrl} id={props.id}>
        <div className="gray-hover-transition">
          {renderBackgroundDiv()}
          <Padding padding={props.padding}>
            <Center>{renderTitle()}</Center>
          </Padding>
        </div>
      </Link>
    );
  }

  function renderBackgroundDiv() {
    return (
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          borderRadius: BorderRadius.all(10).toString(),
          backgroundImage: `url(${props.imageName})`,
          backgroundSize: "cover",
          zIndex: -10,
        }}
      />
    );
  }

  function renderTitle() {
    return (
      <Text
        color={Colors.white}
        text={props.title}
        fontWeight={FontWeight.w600}
        fontSize={props.fontSize}
        textAlign={TextAlignment.Center}
        zIndex={5}
      />
    );
  }
}

export default WorkTypeCard;
