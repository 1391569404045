import R from "assets/R";
import React, { ChangeEvent, useRef, useState } from "react";
import { Form, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import { ConstantsState } from "store/reducers/stateTypes";
import { basicDefaultProps, BasicProps } from "ui/BasicProps";
import Divider from "ui/generic_components/Divider";
import MaterialButton from "ui/generic_components/MaterialButton";
import MaterialTextField from "ui/generic_components/MaterialTextField";
import Padding from "ui/generic_components/Padding";
import SVGIcon from "ui/generic_components/SVGIcon";
import Text from "ui/generic_components/Text";
import Colors from "ui/graphic_helpers/Colors";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import { FloatAlignment, FontWeight } from "ui/graphic_helpers/graphic_enums";
import "../guest_website.css";

type Props = BasicProps & {
  onZipCodeChanged(event: ChangeEvent<HTMLTextAreaElement>): void;
  onConfirm(): Promise<void>;
  checkField(): boolean;
  checkZip(): boolean;
};

ZipCodeFormView.defaultProps = { ...basicDefaultProps };

function ZipCodeFormView(props: Props) {
  const { strings, theme, localiteData }: ConstantsState = useSelector(
    (state: RootState) => state.constants
  );

  const [zipMissing, setZipMissing] = useState(false);

  const [zipIncorrect, setZipIncorrect] = useState(false);

  const target = useRef(null);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{strings.how_does_it_work}</Popover.Title>
      <Popover.Content>
        {strings.booking_explanations}
      </Popover.Content>
    </Popover>
  );

  return (
    <Padding padding={EdgeInsets.all(2)}>
      {renderTitle()}
      <Divider height={2} />
      {renderZipCodeField()}
      <Divider height={0.5} />
      {renderInfoText()}
      {renderErrorMessage()}
      <Divider height={2} />
      {renderConfirmButton()}
    </Padding>
  );

  function renderTitle() {
    return <Text text={strings.ask_zip_code} fontSize={20} fontWeight={FontWeight.w500} />;
  }

  function renderZipCodeField() {
    return (
      <>
        <Form>
          <Form.Group id="zipcode" controlId="zipcode">
            {zipMissing ? (
              <MaterialTextField
                className="zipCodeList"
                reference={target}
                color={theme.accentColor}
                id="zipcode-field"
                inactiveColor={Colors.errorRed}
                hoverColor={Colors.red}
                lines={1}
                label={strings.zip_code_hint}
                onChange={props.onZipCodeChanged}
              />
            ) : (
              <MaterialTextField
                className="zipCodeList"
                reference={target}
                color={theme.accentColor}
                id="zipcode-field"
                lines={1}
                label={strings.zip_code_hint}
                onChange={props.onZipCodeChanged}
                onSubmit={confirm}
              />
            )}
          </Form.Group>
        </Form>
      </>
    );
  }

  function renderInfoText() {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
          <SVGIcon
            className="cursor-pointer"
            color={Colors.turquoise}
            size={25}
            name={R.ICONS.info}
          />
        </OverlayTrigger>
        <Padding padding={EdgeInsets.only({ left: 0.7 })}>
          <Text text={strings.we_do_free_quotes} fontSize={15} />
        </Padding>
      </div>
    );
  }

  function renderErrorMessage() {
    return (
      <Text
        text={strings.wrong_zip}
        fontSize={15}
        fontWeight={FontWeight.w400}
        color={zipIncorrect ? Colors.errorRed : Colors.transparent}
      />
    );
  }

  function renderConfirmButton() {
    return (
      <MaterialButton
        id="submit-booking-button"
        className="hvr-forward"
        color={Colors.turquoise}
        float={FloatAlignment.Right}
        onClick={confirm}>
        <Text text={strings.confirm} color={Colors.white} />
      </MaterialButton>
    );
  }

  function confirm() {
    setZipIncorrect(!props.checkZip());
    setZipIncorrect(!props.checkZip());
    setZipMissing(!props.checkField());
    if (props.checkField() && props.checkZip()) {
      props.onConfirm();
    }
  }
}

export default ZipCodeFormView;
