import { LocaliteInfo } from "assets/geo/LocaliteToCommune";
import BookingFormController from "guest_website/controllers/BookingFormController";
import { CalendarSlot } from "model/calendarSlot";
import React, { useState } from "react";
import { basicDefaultProps, BasicProps } from "ui/BasicProps";
import Center from "ui/generic_components/Center";
import Padding from "ui/generic_components/Padding";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import BookingFormView from "./BookingFormView";

type Props = BasicProps & {
  onContact: () => void;
  dismiss: () => void;
  onConfirm:() => void;
  postalCode: string;
  isLoading: boolean;
  localiteInfo: LocaliteInfo;
  slotsMatrix: CalendarSlot[][][];
  controller: BookingFormController;
  firstDate: Date,
};

BookingForm.defaultProps = {
  ...basicDefaultProps,
};

function BookingForm(props: Props) {

  const [changes, setChanges] = useState(0);

  if (props.isLoading) {
    return (
      <Center>
        <Padding padding={EdgeInsets.symetric({ vertical: 15 })}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </Padding>
      </Center>
    );
  } else {
    return (
      <>
        <BookingFormView
          slotsMatrix={props.slotsMatrix}
          localiteInfo={props.postalCode}
          isSlotSelected={props.controller.isSlotSelected}
          selectedSlot={props.controller.selectedSlot}
          onConfirm={props.onConfirm}
          onSlotChanged={onSlotChanged}
          firstDate={props.firstDate}
          onContactClicked={props.onContact}
        />
      </>
    );
  }

  function onSlotChanged(slot: CalendarSlot) {
    props.controller.onSlotChanged(slot);
    setChanges(changes + 1);
  }
}

export default BookingForm;
