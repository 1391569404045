import nl from "./nl";
import en from "./en";

interface ObjectWithStringKeys {
  [key: string]: Strings;
}

const strings: ObjectWithStringKeys = {
  nl: nl,
  en: en,
};

export enum Language {
  nl = "nl",
  en = "en",
}

export interface Strings {
  realisaties_private_button:string;
  realisaties_company_button:string;
  realisaties: string;
  realisaties_company: string;
  realisaties_private: string;
  sandblasting_subtext: string;
  ramfolies_subtext: string;
  belettering_subtext: string;
  how_does_it_work: string;
  we_do_free_quotes: string;
  booking_explanations: string;
  wish_to_have_quote: string;
  streetname: string;
  streetnumber: string;
  this_is_complain: string;
  quote: string;
  question: string;
  available: string;
  unavailable: string;
  preferable: string;
  no_available_slot: string;
  dont_hesistate_to_1: string;
  dont_hesistate_to_2: string;
  wrong_zip: string;
  your_reservation: string;
  mail_confirmation: string;
  afternoon: string;
  ask_contact_info: string;
  ask_time_slot: string;
  ask_zip_code: string;
  day_abbr_mon: string;
  day_abbr_tue: string;
  day_abbr_wed: string;
  day_abbr_thu: string;
  day_abbr_fri: string;
  day_abbr_sat: string;
  day_abbr_sun: string;
  day_mon: string;
  day_tue: string;
  day_wed: string;
  day_thu: string;
  day_fri: string;
  day_sat: string;
  day_sun: string;
  finish: string;
  first_name: string;
  language_name: string;
  last_name: string;
  confirm: string;
  contact_us_excl: string;
  home: string;
  services: string;
  realisations: string;
  contact: string;
  our_services: string;
  our_realisations: string;
  individuals: string;
  companies: string;
  cookie_policy_title: string;
  cookie_policy_body: string;
  about_us: string;
  about_us_text: string;
  label_address: string;
  morning: string;
  no_date_selected: string;
  phone: string;
  privacy_policy: string;
  privacy_policy_body: string;
  remark: string;
  terms_of_sale: string;
  terms_of_sale_body: string;
  thank_you: string;
  to: string;
  selected_date: string;
  send_mail_unknown_error: string;
  send_mail_invalid_address: string;
  send_mail_title: string;
  send_mail_address_hint: string;
  send_mail_subject_hint: string;
  send_mail_message_hint: string;
  send_mail_button: string;
  send_mail_empty_field_error: string;
  current_theme_indication_text: string;
  switch_to: string;
  header_title: string;
  header_description: string;
  reservation: string;
  services_description: string;
  service_general_body: string;
  service_title_custom: string;
  service_body_custom: string;
  service_excerpt_custom: string;
  service_title_window: string;
  service_body_window: string;
  service_excerpt_window: string;
  service_title_signs: string;
  service_body_signs: string;
  service_excerpt_signs: string;
  service_title_bike: string;
  service_body_bike: string;
  service_excerpt_bike: string;
  service_title_wall: string;
  service_body_wall: string;
  service_excerpt_wall: string;
  service_title_vehicle: string;
  service_body_vehicle: string;
  service_body_vehicle2: string;
  service_excerpt_vehicle: string;
  service_title_sand: string;
  service_body_sand: string;
  service_excerpt_sand: string;
  service_title_colour: string;
  service_body_colour: string;
  service_excerpt_colour: string;
  service_interested_title: string;
  service_interested_alt_1: string;
  service_interested_txt: string;
  service_how_to_request_title: string;
  service_how_to_request_1_txt: string;
  service_how_to_request_2_txt: string;
  service_how_to_request_3_txt: string;
  service_agreed_time_txt: string;
  service_full_agenda_txt: string;
  warning: string;
  zip_code_hint: string;
  opmerking_msg: string;
  send_company_hint: string;
  service_body_sand_company: string;
  service_fotobehang_t1: string;
  service_fotobehang_t2: string;
  service_fotobehang_b1: string;
  service_fotobehang_b2: string;
  header_description_company: string;
  old_brand: string;
  header_services_1: string;
  header_zandstraalfolies: string;
  header_printfolie: string;
  header_belettering: string;
  header_voertuig: string;
  header_stickers: string;
  header_kleurenfolies:string;
  header_raamdecoratie:string;
  header_carwrapping: string;
  header_old_provincies: string;
}

export default strings;
