import { useRef, useCallback } from "react";

export default function useInfiniteScroll(
  fetchData: () => void,
  loading: boolean,
  hasMore: boolean
) {
  const observer = useRef<IntersectionObserver>();

  const lastImageRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (loading) return;
        if (entries[0].isIntersecting && hasMore) {
          fetchData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, loading, fetchData]
  );

  return lastImageRef;
}
