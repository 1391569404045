import React from "react";
import TextPageHeader from "../sections/TextPageHeader";
import { Strings } from "assets/strings/strings";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import HeaderTextPage from "../widgets/HeaderTextPage";

function PrivacyPolicyPage() {
  const strings: Strings = useSelector((state: RootState) => state.constants.strings);
  console.log("yoyo");
  return (
    <div id="privacy-policy-page">
      <HeaderTextPage header={strings.privacy_policy} text = {"The website “zandstraalfoliesopramen.be” and the associated internet services are made available to you by Zandstraalfolies op ramen BV, with its registered office in Menen. Sandblasting films on Windows BV, registered in the trade register of the Chamber of Commerce. Sandblasting films on windows BV registered in the trade register of the Chamber of Commerce of the Province of West Flanders under number BE 0738.548.694. The following policy applies with regard to the protection of your privacy.\
\n Sandblast films on windows BV guarantees as far as possible the protection of the privacy of all visitors to the site. This means that we also demand maximum efforts from our cooperation partners when monitoring personal data.\
Below you can read which personal data we process, what we do with this personal data, within which legal framework this happens and what you can do if you have any questions.\
\n\
\n\
Processing of personal data\n\
\n\
In the context of our services, Zandstraalfolies op ramen BV records data (name, place of residence and address, your telephone and fax numbers, your e-mail address). Zandstraalfolies op ramen BV uses this information for the implementation of the agreement in question, for its other services and to keep customers informed of (new) products and services of Bedrijfsnaam of the companies working with it in a group or of carefully selected third parties. Zandstraalfolies op ramen BV tries to take your preferences into account. If you do not appreciate information about (new) products and services, you can report this in writing to Zandstraalfolies op ramen BV, with the subject “privacy policy”. We will have your data blocked for this.\
\n\
\n\
Delete your data?\n\
\n\
We store your data on a (secure) Secure Server. Do you want us to remove your data from our database? You can also send an e-mail to the office of your region. Of course you can also let us know in writing. Our address is Sandblast Foils on Windows BV, E. Anseelstraat 23 8930 Sandblast Foils on Windows BV will meet your request within 7 working days.\
\n\
\n\
Do you have any questions about our privacy policy?\
\n\
\n\
Send an e-mail to the office of your region. You can also use this e-mail address if you have questions about your data or if you want to change it. Our customer service is happy to assist you.\
\n\
\n\
Changes\n\
\n\
We reserve the right to change our privacy policy at any time. However, you will always find the most recent version on this page. Therefore, regularly check our privacy policy on this website.\
\n\
PhoenixArtworks BV\
\n\
Leemstraat 26 Map box A, 3272 Scherpenheuvel-Zichem\
\n\
contact@phoenix-artworks.be"} />
    </div>
  );
}

export default PrivacyPolicyPage;
