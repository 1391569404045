export type BasicProps = {
  id: string;
  className: string;
  zIndex: number | "auto";
  children: React.ReactElement[] | React.ReactElement;
  reference?: React.RefObject<any>,
  style: React.CSSProperties,
  key: string,

}

export const basicDefaultProps: BasicProps = {
  id: "",
  className: "",
  zIndex: "auto",
  children: [],
  style: {},
  key: "",
}