export default class DateUtils {
  static getStrDate(date: Date): string {
    return (
      (date.getDate() < 10 ? "0" : "") +
      date.getDate().toString() +
      "/" +
      (date.getMonth() + 1 < 10 ? "0" : "") +
      (date.getMonth() + 1).toString() +
      "/" +
      date.getFullYear().toString()
    );
  }

  static getMonday(date: Date): Date {
    let d = new Date(date);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  static createDateJustAfterMidnight(date: Date): Date {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 1);
    return newDate;
  }

  static createDateJustBeforeMidnight(date: Date): Date {
    const newDate = new Date(date);
    newDate.setHours(23, 59, 1);
    return newDate;
  }

  static addDays(date: Date, days: number): Date {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  static dateBetweenWindow(date: Date, startWindow: Date, endWindow: Date) {
    return date >= startWindow && date <= endWindow;
  }
  static areDatesEqualWithDayPrecision(date1: Date, date2: Date): boolean {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }
}
