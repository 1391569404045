import React, { ChangeEvent, CSSProperties } from "react";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";
import "./MaterialTextField.css";
import Colors from "../graphic_helpers/Colors";
import Color from "ui/graphic_helpers/Color";
import SolidColor from "ui/graphic_helpers/SolidColor";

type Props = BasicProps & {
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  lines: number;
  color: Color;
  inactiveColor: Color;
  hoverColor: Color;
  onSubmit?: () => void;
};

MaterialTextField.defaultProps = {
  ...basicDefaultProps,
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => {},
  lines: 1,
  inactiveColor: new SolidColor(0, 0, 0, 0.6),
  hoverColor: new SolidColor(0, 0, 0, 0.87),
};

function MaterialTextField(props: Props) {
  let lineCount = 0;

  return (
    <label
      ref={props.reference}
      style={getStyle()}
      className={props.className + " pure-material-textfield-outlined --"}>
      <textarea
        id={props.id}
        placeholder=" "
        onChange={props.onChange}
        onKeyDown={handleTextArea}
        rows={props.lines}
        style={{ resize: "none" }}
      />
      <span>{props.label}</span>
    </label>
  );

  function handleTextArea(e: React.KeyboardEvent) {
    if (e.keyCode == 13) {
      lineCount++;
      if (props.onSubmit) {
        props.onSubmit();
      }
    }
    if (lineCount >= props.lines) {
      e.preventDefault();
      return false;
    }
  }

  function getStyle(): CSSProperties {
    return {
      display: "block",
      "--color": props.color.toString(),
      "--inactivecolor": props.inactiveColor.toString(),
      "--onhovercolor": props.hoverColor.toString(),
      background: Colors.transparent.toString(),
    } as CSSProperties;
  }
}

export default MaterialTextField;
