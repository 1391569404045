import React, { ReactNode } from "react";
import SectionTitle from "ui/generic_components/SectionTitle";
import Padding from "ui/generic_components/Padding";
import { Container, Image, Row, Col } from "react-bootstrap";
import Divider from "ui/generic_components/Divider";
import { TextAlignment } from "ui/graphic_helpers/graphic_enums";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import SectionSeparator from "ui/generic_components/SectionSeparator";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import { ConstantsState } from "store/reducers/stateTypes";
import Text from "ui/generic_components/Text";
import "../guest_website.css";
import Center from "ui/generic_components/Center";

function AboutUsSection() {
  const { strings, theme }: ConstantsState = useSelector((state: RootState) => state.constants);

  return (
    <div style={{ backgroundColor: theme.backgroundColorA.toString() }}>
      <SectionSeparator />
      <Container>
        <Row className="align-items-center" style={{ margin: 0 }}>
          <Col className="col-12 col-lg-12">{renderRight()}</Col>
        </Row>
      </Container>
      <SectionSeparator />
    </div>
  );

  function renderLeft(): ReactNode {
    return (
      <Image
        fluid
        src="/resources/images/image_placeholder_about_us.jpg"
        alt="phoenix artwork team"
      />
    );
  }

  function renderRight(): ReactNode {
    return (
      <Padding padding={EdgeInsets.onlyLeft(3)}>
        <SectionTitle
          className="about-us"
          title={strings.about_us}
          alignment={TextAlignment.Center}
          color={theme.mainTextColor.toString()}
        />
        <Center>
          <Divider color={theme.accentColor} />
        </Center>
        <Text text={strings.about_us_text} color={theme.secondaryTextColor} />
      </Padding>
    );
  }
}

export default AboutUsSection;
