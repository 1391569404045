const urlRetriever = (MAX_NEW_PICS = 1000) => {
  const hasMoreUrlsToRetrieve = (photoNames: string[], picUrls: string[]) => {
    return calculateNumberOfNewPicsToLoad(photoNames, picUrls) !== 0;
  };

  const retrieveMoreUrls = (photoNames: string[], picUrls: string[]): string[] => {
    const numberOfNewPics = calculateNumberOfNewPicsToLoad(photoNames, picUrls);
    const newPicNames = getPicNamesToLoad(photoNames, picUrls, numberOfNewPics);
    return getUrlsFromPicNames(newPicNames);
  };

  const calculateNumberOfNewPicsToLoad = (photoNames: string[], picUrls: string[]): number => {
    return Math.min(
      Math.max(photoNames.length - picUrls.length, 0),
      Math.max(Math.min(photoNames.length - 1, MAX_NEW_PICS), 0)
    );
  };

  const getPicNamesToLoad = (
    photoNames: string[],
    picUrls: string[],
    numberOfNewPics: number
  ): string[] => {
    const startIdx = picUrls.length;
    return photoNames.slice(startIdx, startIdx + numberOfNewPics);
  };

  const getUrlFromPicName = (photoName: string): string => {
    return "/resources/images/realisations/" + photoName;
  };

  const getUrlsFromPicNames = (photoNames: string[]): string[] => {
    return photoNames.map((name) => getUrlFromPicName(name));
  };
  return { hasMoreUrlsToRetrieve, retrieveMoreUrls, getUrlFromPicName };
};

export default urlRetriever;
