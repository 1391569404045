import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import { fetchRealisations, loadMoreRealisations, resetRealisations } from "store/actions/RealisationsActions";
import { ServiceProvider } from "services/ServiceProvider";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";
import { RealisationsPageView } from "./RealisationsPageView";
import useInfiniteScroll from "ui/generic_hooks/useInfiniteScroll";
import urlPicRetriever from "../../helpers/urlPicRetriever";

type Props = BasicProps & {
  title: string;
  photoLocation: string;
};

RealisationsPage.defaultProps = {
  ...basicDefaultProps,
};

const { hasMoreUrlsToRetrieve, retrieveMoreUrls } = urlPicRetriever();

function RealisationsPage(props: Props) {
  const photoNames: string[] = useSelector((state: RootState) => state.realisations.photoNames);
  const picUrls: string[] = useSelector((state: RootState) => state.realisations.loadedPhotoUrls);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const loadMore = useCallback(() => {
    if (hasMoreUrlsToRetrieve(photoNames, picUrls)) {
      const newUrls = retrieveMoreUrls(photoNames, picUrls);
      dispatch(loadMoreRealisations(newUrls));
    } else {
      setHasMore(false);
    }
  }, [photoNames, picUrls, dispatch]);

  const lastImageRef = useInfiniteScroll(loadMore, loading, hasMore);

  useEffect(() => {
    const fetchAndLoadRealisations = async (photoLocation: string, initUrls: string[]) => {
      let dataAccessor = ServiceProvider.getInstance().dataAccessor;
      let newPhotoNames = await dataAccessor.getRealisationsPhotonames(photoLocation);
      dispatch(fetchRealisations(newPhotoNames));
      dispatch(loadMoreRealisations(retrieveMoreUrls(newPhotoNames, initUrls)));
    };
    setLoading(true);
    const init = async () => {
      await fetchAndLoadRealisations(props.photoLocation, picUrls);
      setLoading(false);
    };
    init();
    return () => {
      dispatch(resetRealisations());
    }
    // eslint-disable-next-line
  }, []);

  return <RealisationsPageView id={props.title} picUrls={picUrls} lastImageRef={lastImageRef} />;
}

export default RealisationsPage;
