import { CalendarSlotController } from "controller/calendarSlotController";
import BookingConfirmationFormController from "guest_website/controllers/BookingConfirmationFormController";
import { CalendarSlot } from "model/calendarSlot";
import { Reservation } from "model/reservation";
import React, { ChangeEvent } from "react";
import BookingConfirmationFormDataChecking from "services/booking_system/BookingConfirmationFormDataChecking";
import { DataAccessor } from "services/firestore/DataAccessor";
import { ServiceProvider } from "services/ServiceProvider";
import { basicDefaultProps, BasicProps } from "ui/BasicProps";
import BookingConfirmationFormView from "./BookingConfirmationFormView";

type Props = BasicProps & {
  reservationSlot: CalendarSlot;
  slotController: CalendarSlotController;
  zipCode: string;
  onConfirm(): void;
  dismiss(): void;
  controller: BookingConfirmationFormController;
};

BookingConfirmationForm.defaultProps = {
  ...basicDefaultProps,
};

function BookingConfirmationForm(props: Props) {
  return (
    <>
      <BookingConfirmationFormView
        onFirstNameChanged={onFirstNameChanged}
        onLastNameChanged={onLastNameChanged}
        onEmailChanged={onEmailChanged}
        onPhoneChanged={onPhoneChanged}
        onStreetChanged={onStreetnameChanged}
        onStreetNumberChanged={onStreetnumberChanged}
        onRemarkChanged={onRemarkChanged}
        onConfirm={onConfirm}
        reservedSlot={props.reservationSlot}
        checkFields={checkFields}
      />
    </>
  );

  function checkFields(): BookingConfirmationFormDataChecking {
    return props.controller.areFieldsOk();
  }

  function onFirstNameChanged(event: ChangeEvent<HTMLTextAreaElement>) {
    const firstName = event.currentTarget.value;
    props.controller.onFirstNameChanged(firstName);
  }

  function onLastNameChanged(event: ChangeEvent<HTMLTextAreaElement>) {
    const lastName = event.currentTarget.value;
    props.controller.onLastNameChanged(lastName);
  }

  function onStreetnameChanged(event: ChangeEvent<HTMLTextAreaElement>) {
    const streetname = event.currentTarget.value;
    props.controller.onStreetnameChanged(streetname);
  }

  function onStreetnumberChanged(event: ChangeEvent<HTMLTextAreaElement>) {
    const streetnumber = event.currentTarget.value;
    props.controller.onStreetnumberChanged(streetnumber);
  }

  function onEmailChanged(event: ChangeEvent<HTMLTextAreaElement>) {
    const email = event.currentTarget.value;
    props.controller.onEmailChanged(email);
  }

  function onPhoneChanged(event: ChangeEvent<HTMLTextAreaElement>) {
    const phone = event.currentTarget.value;
    props.controller.onPhoneChanged(phone);
  }

  function onRemarkChanged(event: ChangeEvent<HTMLTextAreaElement>) {
    const remark = event.currentTarget.value;
    props.controller.onRemarkChanged(remark);
  }

  async function onConfirm() {
    const reservation = new Reservation(
      false,
      props.reservationSlot.isAfternoon,
      props.reservationSlot.getDate(),
      props.controller.formData.firstName,
      props.controller.formData.lastName,
      props.controller.formData.phone,
      props.controller.formData.remark,
      props.controller.formData.email,
      0,
      "0",
      props.zipCode.trim(),
      "0",
      props.controller.getAddress(),
      Reservation.DEFAULT_MEETING_TIME_IN_MINUTES
    );
    props.slotController.finaliseReservation(reservation);
    const dataAccessor: DataAccessor = ServiceProvider.getInstance().dataAccessor;
    try {
      await dataAccessor.addReservation(reservation);
      console.log("success");
      fbq('track', 'Lead')
      props.onConfirm();
      // setConfirmationShown(true);
      // SHOW NEXT
    } catch (_) {
      // SHOW ERROR
    }
  }
}

export default BookingConfirmationForm;
