import React, { ReactNode, CSSProperties } from "react";
import { Row, Col, Image, Container, Nav } from "react-bootstrap";
import LabeledText from "ui/generic_components/LabeledText";
import Padding from "ui/generic_components/Padding";
import { Link } from "react-router-dom";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import { ConstantsState } from "store/reducers/stateTypes";
import ResponsiveCol from "ui/generic_components/ResponsiveCol";
import ColDimensions from "ui/graphic_helpers/ColDimensions";
import Center from "ui/generic_components/Center";
import Text from "ui/generic_components/Text";
import { FontWeight } from "ui/graphic_helpers/graphic_enums";
import "../guest_website.css";
import Colors from "ui/graphic_helpers/Colors";
import SVGIcon from "ui/generic_components/SVGIcon";
import R from "assets/R";
import Divider from "ui/generic_components/Divider";

function Footer() {
  const { strings, theme }: ConstantsState = useSelector((state: RootState) => state.constants);

  return (
    <div style={{ background: theme.footerColor.toString() }}>
      <Padding padding={EdgeInsets.fromLTRB(0, 3, 0, 1)}>
        <Container>
          <Row className="align-items-center" style={{ margin: 0 }}>
            <Col sm={0} xl={6}>
              {renderLeft()}
            </Col>
            <Col xl={6}>{renderRight()}</Col>
          </Row>
          {renderCopyright()}
        </Container>
      </Padding>
    </div>
  );

  function renderLeft(): ReactNode {
    return (
      <Image
        style={{ height: "300px" }}
        className="footer-image"
        fluid
        id="footer_logo"
        src={theme.logo}
        alt="phoenix artwork logo"
      />
    );
  }

  function renderRight(): ReactNode {
    return (
      <div style={{ textAlign: "left" }}>
        {renderLabeledTexts()}
        <Divider height={1}/>
        {renderLinks()}
      </div>
    );
  }

  function renderLabeledTexts(): ReactNode {
    const labeledTextStyle: CSSProperties = { color: theme.footerTextColor.toString() };
    return (
      <>
        <LabeledText
          id="address"
          label={strings.label_address}
          text="Leemstraat 26A, 3272 Scherpenheuvel-Zichem"
          style={labeledTextStyle}
        />
        <LabeledText
          id="email"
          label="E-mail: "
          text="contact@phoenix-artworks.be"
          style={labeledTextStyle}
        />
        <LabeledText id="tel" label="Tel: " text="0489 50 31 27" style={labeledTextStyle} />
        <LabeledText id="vat" label="VAT: " text=" BE 0760.477.030" style={labeledTextStyle} />
        <a target="_blank" href="https://www.facebook.com/PhoenixArtworksTestelt ">
          <SVGIcon className="hvr-bob" color={Colors.black} size={26} name={R.ICONS.facebook} />
        </a>
      </>
    );
  }

  function renderLinks(): ReactNode {
    const linksStyle: CSSProperties = { fontSize: 10, color: theme.accentColor.toString() };
    return (
      <Row style={{ margin: 0 }}>
        <Link to={"/privacy-policy"} id="privacy-policy-link" style={linksStyle}>
          {strings.privacy_policy}
        </Link>
        <Padding padding={EdgeInsets.only({ right: 1 })} />
        <Link to="/terms-of-sale" style={linksStyle} id="terms-sale-link">
          {strings.terms_of_sale}
        </Link>
      </Row>
    );
  }

  function renderCopyright(): ReactNode {
    return (
      <Padding padding={EdgeInsets.onlyTop(2)}>
        <Center>
          <Row>{renderCopyrightText()}</Row>
        </Center>
      </Padding>
    );
  }

  function renderCopyrightText(): ReactNode {
    const linkStyle: CSSProperties = {
      fontSize: 12,
      color: theme.mainTextColor.toString(),
      fontWeight: FontWeight.w600,
    };
    return (
      <>
        <Text
          text={"Designed by "}
          color={theme.mainTextColor}
          fontWeight={FontWeight.w400}
          fontSize={12}
        />
        <Padding padding={EdgeInsets.only({ right: 0.25 })} />
        <a href="https://istarii.com" style={linkStyle}>
          istarii
        </a>{" "}
      </>
    );
  }
}

export default Footer;
