import { FETCH_REALISATIONS, LOAD_MORE_REALISATIONS, RESET, RESET_REALISATIONS } from "../actions/action_types";
import { RealisationsState } from "./stateTypes";

const initialState: RealisationsState = {
  photoNames: [],
  loadedPhotoUrls: [],
};

function RealisationsReducer(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_REALISATIONS:
      return { ...state, photoNames: action.photoNames, loadedPhotoUrls: [] };
    case LOAD_MORE_REALISATIONS:
      return {
        ...state,
        loadedPhotoUrls: state.loadedPhotoUrls.concat(action.realisationUrls),
      };
    case RESET_REALISATIONS:
      return {
        ...initialState,
      }
    case RESET:
      return {
        ...initialState,
      };
    default:
      return { ...state };
  }
}

export default RealisationsReducer;
