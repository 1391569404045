import React, { CSSProperties } from "react";
import { TextAlignment, FontWeight } from "../graphic_helpers/graphic_enums";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";
import Color from "../graphic_helpers/Color";
import Colors from "../graphic_helpers/Colors";

type Props = BasicProps & {
  title: string;
  alignment?: TextAlignment;
  color: Color;
  fontSize: number;
  fontWeight: FontWeight;
};

SectionTitle.defaultProps = {
  ...basicDefaultProps,
  alignment: TextAlignment.Center,
  color: Colors.white,
  fontSize: 36,
  fontWeight: FontWeight.w400,
};

function SectionTitle(props: Props) {
  return (
    <p id={props.id} style={getStyle()} className={props.className}>
      {props.title}
    </p>
  );

  function getStyle(): CSSProperties {
    return {
      textAlign: props.alignment,
      fontSize: props.fontSize,
      fontWeight: props.fontWeight,
      color: props.color.toString()
    };
  }
}

export default SectionTitle;
