import React, { ReactElement, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import ResponsiveCol from "../../../ui/generic_components/ResponsiveCol";
import ColDimensions from "../../../ui/graphic_helpers/ColDimensions";
import { Image } from "react-bootstrap";
import Text from "../../../ui/generic_components/Text";
import Colors from "../../../ui/graphic_helpers/Colors";
import ServicesSection from "../sections/ServicesSection";
import Divider from "../../../ui/generic_components/Divider";
import Padding from "../../../ui/generic_components/Padding";
import SectionTitle from "../../../ui/generic_components/SectionTitle";
import { FontWeight, TextAlignment } from "../../../ui/graphic_helpers/graphic_enums";
import EdgeInsets from "../../../ui/graphic_helpers/EdgeInsets";
import { useDispatch, useSelector } from "react-redux";
import { ConstantsState } from "store/reducers/stateTypes";
import { RootState } from "store/reducers/RootReducer";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";
import { Strings } from "assets/strings/strings";
import GeneralInformationSection from "../sections/GeneralInformationSection";
import ZipCodeForm from "../widgets/ZipCodeForm";
import BookingModalManager from "../widgets/BookingModalManager";
import ContactForm from "../widgets/ContactForm";
import { resetSendMailError } from "store/actions/BookingSystemActions";

type Props = BasicProps & {
  img: string;
  serviceID: string;
  imgTop: string;
  imgBottom: string;
};

ServicePage.defaultProps = {
  ...basicDefaultProps,
  img: "/resources/images/service_kantoor.jpg",
  imgTop: "/resources/images/service_kantoor.jpg",
  imgBottom: "/resources/images/service_kantoor.jpg",
  serviceID: "service",
};

function ServicePage(props: Props) {
  const { strings, theme }: ConstantsState = useSelector((state: RootState) => state.constants);
  const [zipCodeFormShown, setZipCodeFormShown] = useState(false);
  const [contactFormShown, setContactFormShown] = useState(false);
  const showContactForm = () => setContactFormShown(true);
  const showZipCodeForm = () => setZipCodeFormShown(true);
  const hideZipCodeForm = () => setZipCodeFormShown(false);
  const dispatch = useDispatch();

  const { title, text }: { title: string; text: string } = getServicesTexts(
    strings,
    props.serviceID
  );

  const hideContactForm = () => {
    setContactFormShown(false);
    dispatch(resetSendMailError());
  };

  return (
    <div style={{ backgroundColor: theme.backgroundColorA.toString() }}>
      <div style={{ backgroundColor: theme.backgroundColorA.toString() }}>
        {renderBody(props.serviceID)}
      </div>
      <GeneralInformationSection
        img={props.imgBottom}
        alt={getTextStatus(props.serviceID)}
        setBooking={setUpZipCodeFormModal}
        showBooking={showZipCodeForm}
        style={{ backgroundColor: theme.backgroundColorA.toString() }}
      />
      <ServicesSection />
      <div style={{ backgroundColor: theme.backgroundColorA.toString() }}>
        <Divider height={10} />
      </div>
      {setupContactFormModal()}
      {setUpZipCodeFormModal()}
    </div>
  );

  function getTextStatus(id: string) {
    switch (id) {
      case "custom-projects":
        return 0;
      case "sandblasting-films":
        return 0;
      case "raamfolies":
        return 0;
      case "reclameborden":
        return 0;
      case "motor":
        return 2;
      case "fotobehang":
        return 0;
      case "voertuig-wrapping":
        return 0;
      case "kleurenfolies":
        return 0;
      default:
        return 0;
    }
  }

  function renderBody(id: string) {
    return (
      <Row className="align-items-center" style={{ margin: 0 }}>
        <ResponsiveCol
          style={{ margin: 0, padding: 0 }}
          dimensions={ColDimensions.only({ defaultValue: 12, lg: 6 })}>
          {renderLeft()}
        </ResponsiveCol>
        <ResponsiveCol
          style={{ margin: 0, padding: 0 }}
          dimensions={ColDimensions.only({ defaultValue: 12, lg: 6 })}>
          {renderRight(id)}
        </ResponsiveCol>
      </Row>
    );
  }

  function renderLeft() {
    return <Image alt="phoenixartworks zandstraalfolie" fluid src={props.imgTop} id="service-image" />;
  }

  function renderRight(id: string) {
    return (
      <Padding padding={EdgeInsets.only({ left: 4, right: 2, top: 2, bottom: 2 })}>
        <SectionTitle
          title={title}
          id="service-title"
          alignment={TextAlignment.Left}
          color={theme.mainTextColor}
        />
        <Divider color={Colors.turquoise}/>
        {renderTextRight(id)}
      </Padding>
    );
  }

  function renderTextRight(id: string): ReactElement {
    switch (id) {
      case "voertuig-wrapping":
        return props.serviceID === "voertuig-wrapping" ? (
          <div style={{ textAlign: "left" }}>
            <span
              style={{
                fontWeight: FontWeight.normal,
                zIndex: props.zIndex,
                padding: 0,
                margin: 0,
                whiteSpace: "pre-wrap",
                textAlign: TextAlignment.Left,
                fontSize: 15,
                color: theme.mainTextColor.toString(),
              }}>
              {strings.service_body_vehicle}
            </span>
            <span
              className="cursor-pointer"
              onClick={(e: React.MouseEvent) => {
                console.log("yo");
                showContactForm();
              }}
              style={{
                fontWeight: FontWeight.normal,
                zIndex: props.zIndex,
                padding: 0,
                margin: 0,
                whiteSpace: "pre-wrap",
                textAlign: TextAlignment.Left,
                fontSize: 15,
                color: Colors.turquoise.toString(),
              }}>
              {strings.service_body_vehicle2}
            </span>
          </div>
        ) : (
          <span />
        );
      case "fotobehang":
        return (
        <>
        <Text text={strings.service_fotobehang_t1} color={theme.mainTextColor} id="service-description-t1" />
        <Divider height={1}/>
        <Divider color={Colors.turquoise} />
        <Text text={strings.service_fotobehang_b1} color={theme.mainTextColor} id="service-description-b1" />
        <Divider height={5}/>
        <Text text={strings.service_fotobehang_t2} color={theme.mainTextColor} id="service-description-t2" />
        <Divider height={1}/>
        <Divider color={Colors.turquoise} />
        <Text text={strings.service_fotobehang_b2} color={theme.mainTextColor} id="service-description-b2" />
        </>);
      default:
        return <Text text={text} color={theme.mainTextColor} id="service-description" />;
    }
  }

  function setupContactFormModal() {
    return (
      <Modal backdrop="static" show={contactFormShown} onHide={hideContactForm}>
        <Modal.Header closeButton />{" "}
        <ContactForm
          isCompany={theme.themeName === "Bedrijf"}
          goToBooking={onBook}
          dismiss={hideContactForm}
        />
      </Modal>
    );
  }

  function setUpZipCodeFormModal() {
    return (
      <BookingModalManager
        startBooking={zipCodeFormShown}
        onContact={onContact}
        onFinish={() => setZipCodeFormShown(false)}
      />
    );
    // return (
    //   <Modal backdrop="static" show={zipCodeFormShown} onHide={hideZipCodeForm}>
    //     <Modal.Header closeButton /> <ZipCodeForm onContact={onContact} dismiss={hideZipCodeForm} />
    //   </Modal>
    // );
  }

  function onContact() {
    hideZipCodeForm();
    showContactForm();
  }

  function onBook() {
    hideContactForm();
    showZipCodeForm();
    //showZipCodeForm();
  }
}

function getServicesTexts(strings: Strings, id: string): { title: string; text: string } {
  let title: string;
  let text: string;
  switch (id) {
    case "custom-projects":
      title = strings.service_title_custom;
      text = strings.service_body_custom;
      break;
    case "sandblasting-films":
      title = strings.service_title_sand;
      text = strings.service_body_sand;
      break;
    case "sandblasting-films-company":
      title = strings.service_title_sand;
      text = strings.service_body_sand_company;
      break;
    case "raamfolies":
      title = strings.service_title_window;
      text = strings.service_body_window;
      break;
    case "reclameborden":
      title = strings.service_title_signs;
      text = strings.service_body_signs;
      break;
    case "motor":
      title = strings.service_title_bike;
      text = strings.service_body_bike;
      break;
    case "fotobehang":
      title = strings.service_title_wall;
      text = strings.service_body_wall;
      break;
    case "voertuig-wrapping":
      title = strings.service_title_vehicle;
      text = strings.service_body_vehicle;
      break;
    case "kleurenfolies":
      title = strings.service_title_colour;
      text = strings.service_title_colour;
      break;
    default:
      title = "Loremo ipsum";
      text =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
      break;
  }
  return { title, text };
}

export default ServicePage;
