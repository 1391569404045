import {
  NAVIGATE_TO_HOME,
  NAVIGATE_TO_REALISATIONS,
  NAVIGATE_TO_SERVICE,
  NAVIGATE_TO_INFORMATION,
  NAVIGATE_TO_PRIVACY,
  NAVIGATE_TO_COOKIE,
  NAVIGATE_TO_TERMS,
} from "./action_types";

export function navigateToHome() {
  return { type: NAVIGATE_TO_HOME };
}

export function navigateToService() {
  return { type: NAVIGATE_TO_SERVICE };
}

export function navigateToRealisations() {
  return { type: NAVIGATE_TO_REALISATIONS };
}

export function navigateToInformation() {
  return { type: NAVIGATE_TO_INFORMATION };
}

export function navigateToPrivacy() {
  return { type: NAVIGATE_TO_PRIVACY };
}

export function navigateToCookie() {
  return { type: NAVIGATE_TO_COOKIE };
}

export function navigateToTerms() {
  return { type: NAVIGATE_TO_TERMS };
}
