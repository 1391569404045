import { CalendarSlotController } from "controller/calendarSlotController";
import DateUtils from "guest_website/helpers/DateUtils";
import { CalendarSlot } from "model/calendarSlot";
import { DayInfo } from "model/dayInfo";
import { Reservation } from "model/reservation";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ServiceProvider } from "services/ServiceProvider";
import { basicDefaultProps, BasicProps } from "ui/BasicProps";
import BookingConfirmation from "./BookingConfirmation";
import BookingConfirmationForm from "./BookingConfirmationForm";
import BookingForm from "./BookingForm";
import ZipCodeForm from "./ZipCodeForm";

type Props = BasicProps & {
  onContact: () => void;
  startBooking: boolean;
  onFinish: () => void;
};

BookingModalManager.defaultProps = {
  ...basicDefaultProps,
};

function BookingModalManager(props: Props) {
  const MAX_WEEKS = 6;
  const firstDate: Date = DateUtils.getMonday(new Date());

  const [zipCodeFormController, setZipCodeFormController] = useState(
    ServiceProvider.getInstance().controllerFactory.createZipCodeFormController()
  );
  const [bookingFormController, setBookingFormController] = useState(
    ServiceProvider.getInstance().controllerFactory.createBookingFormController()
  );
  const [bookingConfirmationFormController, setBookingConfirmationFormController] = useState(
    ServiceProvider.getInstance().controllerFactory.createBookingConfirmationFormController()
  );

  const [loading, setLoading] = useState(false);

  const [slotController, setSlotController] = useState(new CalendarSlotController([], []));
  const [slotsMatrix, setSlotMatrix] = useState<CalendarSlot[][][]>([[[]]]);

  const [zipCodeFormShown, setZipCodeFormShown] = useState(true);
  const [bookingFormShown, setBookingFormShown] = useState(false);
  const [bookingConfirmationFormShown, setBookingConfirmationFormShown] = useState(false);
  const [bookingConfirmationMessageShown, setBookingConfirmationMessageShown] = useState(false);

  const hideZipCodeForm = () => {props.onFinish()};
  const showZipCodeForm = () => setZipCodeFormShown(true);
  const hideBookingForm = () => setBookingFormShown(false);
  const showBookingForm = () => setBookingFormShown(true);
  const hideBookingConfirmationForm = () => setBookingConfirmationFormShown(false);
  const showBookingConfirmationForm = () => setBookingConfirmationFormShown(true);
  const hideBookingConfirmationMessage = () => setBookingConfirmationMessageShown(false);
  const showBookingConfirmationMessage = () => setBookingConfirmationMessageShown(true);

  return (
    <>
      {setUpZipCodeFormModal()}
      {setUpBookingFormModal()}
      {setUpBookingConfirmationFormModal()}
      {setUpConfirmationModal()}
    </>
  );

  function setUpZipCodeFormModal() {
    return (
      <Modal backdrop="static" show={props.startBooking} onHide={hideZipCodeForm}>
        <Modal.Header closeButton />{" "}
        <ZipCodeForm
          onContact={props.onContact}
          onConfirm={goToBookingForm}
          controller={zipCodeFormController}
        />
      </Modal>
    );
  }

  function setUpBookingFormModal() {
    return (
      <Modal
        backdrop="static"
        size="xl"
        show={bookingFormShown}
        onHide={hideBookingForm}>
        <Modal.Header closeButton />{" "}
        <BookingForm
          onContact={props.onContact}
          localiteInfo={zipCodeFormController.localiteInfo}
          postalCode={zipCodeFormController.localiteInfo.commune_code}
          onConfirm={goToBookingConfirmationForm}
          dismiss={hideBookingForm}
          isLoading={loading}
          firstDate={firstDate}
          slotsMatrix={slotsMatrix}
          controller={bookingFormController}
        />
      </Modal>
    );
  }

  function setUpBookingConfirmationFormModal() {
    return (
      <Modal
        backdrop="static"
        size="xl"
        show={bookingConfirmationFormShown}
        onHide={hideBookingConfirmationForm}>
        <Modal.Header closeButton />
        <BookingConfirmationForm
          zipCode={zipCodeFormController.zipCode}
          slotController={slotController}
          dismiss={hideBookingConfirmationForm}
          reservationSlot={bookingFormController.selectedSlot}
          onConfirm={goToBookingConfirmationMessage}
          controller={bookingConfirmationFormController}
        />
      </Modal>
    );
  }

  function setUpConfirmationModal() {
    return (
      <Modal
        backdrop="static"
        size="xl"
        show={bookingConfirmationMessageShown}
        onHide={hideBookingConfirmationMessage}>
        <Modal.Header closeButton />
        <BookingConfirmation
          email={bookingConfirmationFormController.formData.email}
          dismiss={hideBookingConfirmationMessage}
          isMorning={!bookingFormController.selectedSlot.isAfternoon}
          onConfirm={hideBookingConfirmationMessage}
          date={bookingFormController.selectedSlot.getDate()}
        />
      </Modal>
    );
  }

  async function goToBookingForm() {
    hideZipCodeForm();
    setLoading(true);
    showBookingForm();
    await initSlotController(zipCodeFormController.zipCode);
    setLoading(false);
  }

  function goToBookingConfirmationForm() {
    hideBookingForm();
    showBookingConfirmationForm();
  }

  function goToBookingConfirmationMessage() {
    hideBookingConfirmationForm();
    showBookingConfirmationMessage();
  }

  async function initSlotController(postalCode: string) {
    let dataAccessor = ServiceProvider.getInstance().dataAccessor;
    let reservations: Reservation[] = await dataAccessor.getReservationsBetweenDates(
      DateUtils.createDateJustAfterMidnight(firstDate),
      DateUtils.createDateJustBeforeMidnight(DateUtils.addDays(firstDate, 7 * MAX_WEEKS))
    );
    let dayInfos: DayInfo[] = await dataAccessor.getDaysInfo(
      DateUtils.createDateJustAfterMidnight(firstDate),
      DateUtils.createDateJustBeforeMidnight(DateUtils.addDays(firstDate, 7 * MAX_WEEKS))
    );
    var tempSlotController = new CalendarSlotController(reservations, dayInfos);
    let tempSlotsMatrix: CalendarSlot[][][] = [];
    for (let i: number = 0; i < MAX_WEEKS; i++) {
      const weekStartingDate = DateUtils.addDays(firstDate, i * 7);
      tempSlotsMatrix[i] = [];
      let weekSlots = tempSlotController.retrieveActualisedCalendarSlots(
        weekStartingDate,
        postalCode
      );
      for (let j: number = 0; j < 7; j++) {
        tempSlotsMatrix[i].push(
          weekSlots.filter((slot) => {
            return DateUtils.areDatesEqualWithDayPrecision(
              slot.dayInfo.date,
              DateUtils.addDays(weekStartingDate, j)
            );
          })
        );
      }
    }
    setSlotMatrix(tempSlotsMatrix);
    setSlotController(tempSlotController);
  }
}

export default BookingModalManager;
