import { Strings } from "assets/strings/strings";
import DateUtils from "guest_website/helpers/DateUtils";
import { CalendarSlot } from "model/calendarSlot";
import React, { ChangeEvent, useState } from "react";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import BookingConfirmationFormDataChecking from "services/booking_system/BookingConfirmationFormDataChecking";
import { RootState } from "store/reducers/RootReducer";
import { ConstantsState } from "store/reducers/stateTypes";
import { basicDefaultProps, BasicProps } from "ui/BasicProps";
import Divider from "ui/generic_components/Divider";
import MaterialButton from "ui/generic_components/MaterialButton";
import MaterialTextField from "ui/generic_components/MaterialTextField";
import Padding from "ui/generic_components/Padding";
import ResponsiveCol from "ui/generic_components/ResponsiveCol";
import Text from "ui/generic_components/Text";
import ColDimensions from "ui/graphic_helpers/ColDimensions";
import Colors from "ui/graphic_helpers/Colors";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import { FloatAlignment, FontWeight } from "ui/graphic_helpers/graphic_enums";

type Props = BasicProps & {
  reservedSlot: CalendarSlot;
  onFirstNameChanged(event: ChangeEvent): void;
  onLastNameChanged(event: ChangeEvent): void;
  onStreetChanged(event: ChangeEvent): void;
  onStreetNumberChanged(event: ChangeEvent): void;
  onEmailChanged(event: ChangeEvent): void;
  onPhoneChanged(event: ChangeEvent): void;
  onRemarkChanged(event: ChangeEvent): void;
  onConfirm(): Promise<void>;
  checkFields(): BookingConfirmationFormDataChecking;
};

BookingConfirmationFormView.defaultProps = {
  ...basicDefaultProps,
};

function BookingConfirmationFormView(props: Props) {
  const { strings, theme }: ConstantsState = useSelector((state: RootState) => state.constants);

  const [firstNameMissing, setFirstNameMissing] = useState(false);
  const [lastNameMissing, setLastNameMissing] = useState(false);
  const [streetnameMissing, setStreetnameMissing] = useState(false);
  const [streetnumberMissing, setStreetnumberMissing] = useState(false);
  const [emailMissing, setEmailMissing] = useState(false);
  const [phoneMissing, setPhoneMissing] = useState(false);

  const dayStrings = [
    strings.day_sun,
    strings.day_mon,
    strings.day_tue,
    strings.day_wed,
    strings.day_thu,
    strings.day_fri,
    strings.day_sat,
  ];

  return (
    <>
      <Padding padding={EdgeInsets.all(2)}>
        {renderTitle()}
        <Divider height={2} />
        {renderReservedDate()}
        <Divider height={1.8} />
        {renderNameFields()}
        <Divider height={1.8} />
        {renderContactFields()}
        <Divider height={1.8} />
        {renderAddressFields()}
        <Divider height={1.8} />
        {renderRemarkField()}
        <Divider height={1.8} />
        {renderConfirmButton()}
      </Padding>
    </>
  );

  function renderTitle() {
    return <Text text={strings.ask_contact_info} fontSize={20} fontWeight={FontWeight.w500} />;
  }

  function renderReservedDate() {
    return (
      <>
        <Text text={strings.selected_date} fontSize={17} fontWeight={FontWeight.w500} />
        <Divider height={0.3} />
        <Text
          text={
            dayStrings[props.reservedSlot.getDate().getDay()] +
            " " +
            DateUtils.getStrDate(props.reservedSlot.getDate()) +
            ", " +
            (props.reservedSlot.getDate().getHours() > 12 ? strings.afternoon : strings.morning)
          }
          fontSize={16}
          fontWeight={FontWeight.w400}
        />
      </>
    );
  }

  function renderNameFields() {
    return (
      <Row>
        <ResponsiveCol
          dimensions={ColDimensions.only({ defaultValue: 12, xl: 6, sm: 12, md: 12, lg: 6 })}>
          {renderTextField(
            "firstname-field",
            strings.first_name + " *",
            firstNameMissing,
            props.onFirstNameChanged
          )}
        </ResponsiveCol>
        <ResponsiveCol
          dimensions={ColDimensions.only({ defaultValue: 12, xl: 6, sm: 12, md: 12, lg: 6 })}>
          {renderTextField(
            "lastname-field",
            strings.last_name + " *",
            lastNameMissing,
            props.onLastNameChanged
          )}
        </ResponsiveCol>
      </Row>
    );
  }

  function renderContactFields() {
    return (
      <Row>
        <ResponsiveCol
          dimensions={ColDimensions.only({ defaultValue: 12, xl: 6, sm: 12, md: 12, lg: 6 })}>
          {renderTextField(
            "email-field",
            strings.send_mail_address_hint.replace(":", "") + " *",
            emailMissing,
            props.onEmailChanged
          )}
        </ResponsiveCol>
        <ResponsiveCol
          dimensions={ColDimensions.only({ defaultValue: 12, xl: 6, sm: 12, md: 12, lg: 6 })}>
          {renderTextField(
            "lastname-field",
            strings.phone + " *",
            phoneMissing,
            props.onPhoneChanged
          )}
        </ResponsiveCol>
      </Row>
    );
  }

  function renderAddressFields() {
    return (
      <Row>
        <ResponsiveCol
          dimensions={ColDimensions.only({ defaultValue: 12, xl: 6, sm: 12, md: 12, lg: 6 })}>
          {renderTextField(
            "street-field",
            strings.streetname + " *",
            streetnameMissing,
            props.onStreetChanged
          )}
        </ResponsiveCol>
      </Row>
    );
  }

  function renderRemarkField() {
    return (
      <Row>
        <ResponsiveCol
          dimensions={ColDimensions.only({ defaultValue: 12, xl: 6, sm: 12, md: 12, lg: 6 })}>
          <MaterialTextField
            color={theme.accentColor}
            lines={5}
            id="remark-field"
            label={strings.remark}
            onChange={props.onRemarkChanged}
          />
        </ResponsiveCol>
        <ResponsiveCol
          dimensions={ColDimensions.only({
            defaultValue: 12,
            xl: 6,
            sm: 12,
            md: 12,
            lg: 6,
          })}></ResponsiveCol>
      </Row>
    );
  }

  function renderTextField(
    id: string,
    label: string,
    isMissing: boolean,
    onChange: (event: ChangeEvent) => void
  ) {
    return isMissing ? (
      <MaterialTextField
        color={theme.accentColor}
        inactiveColor={Colors.errorRed}
        hoverColor={Colors.errorRed}
        id={id}
        label={label}
        onChange={onChange}
      />
    ) : (
      <MaterialTextField color={theme.accentColor} id={id} label={label} onChange={onChange} />
    );
  }

  function renderConfirmButton() {
    return (
      <Row>
        <ResponsiveCol
          dimensions={ColDimensions.only({ defaultValue: 12, xl: 6, sm: 12, md: 12, lg: 6 })}>
          <Text text={strings.opmerking_msg} color={Colors.anthracite} fontSize={14}/>
        </ResponsiveCol>
        <ResponsiveCol
          dimensions={ColDimensions.only({ defaultValue: 12, xl: 6, sm: 12, md: 12, lg: 6 })}>
          <MaterialButton
            id="submit-booking-button"
            className="hvr-forward"
            color={Colors.turquoise}
            float={FloatAlignment.Right}
            onClick={onConfirm}>
            <Text text={strings.confirm} color={Colors.white} />
          </MaterialButton>
        </ResponsiveCol>
      </Row>
    );
  }

  function onConfirm() {
    const checks: BookingConfirmationFormDataChecking = props.checkFields();
    setFirstNameMissing(!checks.isFirstNameOk);
    setLastNameMissing(!checks.isLastNameOk);
    setEmailMissing(!checks.isEmailOk);
    setPhoneMissing(!checks.isPhoneOk);
    setStreetnameMissing(!checks.isStreetnameOk);
    //setStreetnumberMissing(!checks.isStreetnumberOk);
    if (allFieldsOk(checks)) {
      props.onConfirm();
    }
  }

  function allFieldsOk(checks: BookingConfirmationFormDataChecking): boolean {
    return checks.isFirstNameOk && checks.isLastNameOk && checks.isEmailOk && checks.isPhoneOk;
  }
}

export default BookingConfirmationFormView;
