import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import { ConstantsState } from "store/reducers/stateTypes";
import TextPageHeader from "../sections/TextPageHeader";
import HeaderTextPage from "../widgets/HeaderTextPage";

function CookiePolicyPage() {
  const { strings, theme }: ConstantsState = useSelector((state: RootState) => state.constants);

  return (
    <div id="cookie-policy-page">
      <HeaderTextPage header={strings.cookie_policy_title} text = {strings.cookie_policy_body} />
    </div>
  );
}

export default CookiePolicyPage;
