export interface PostalInfo {
  province: string;
  commune: string;
  zone: string;
  tunnels: string[];
}
export type GeoData = { [id: string]: PostalInfo };
export const postalCodes: GeoData = {
  "2430": { province: "Anvers", commune: "LAAKDAL", zone: "1", tunnels: [] },
  "3850": { province: "Limbourg", commune: "NIEUWERKERKEN", zone: "5", tunnels: [] },
  "3700": { province: "Limbourg", commune: "TONGEREN", zone: "5", tunnels: [] },
  "3830": { province: "Limbourg", commune: "WELLEN", zone: "5", tunnels: [] },
  "1790": { province: "Brabant Flamand", commune: "AFFLIGEM", zone: "8", tunnels: [] },
  "2490": { province: "Anvers", commune: "BALEN", zone: "11", tunnels: [] },
  "3190": { province: "Brabant Flamand", commune: "BOORTMEERBEEK", zone: "9", tunnels: [] },
  "1640": {
    province: "Brabant Flamand",
    commune: "Sint-Genesius-Rode",
    zone: "7",
    tunnels: [],
  },
  "2980": { province: "Anvers", commune: "ZOERSEL", zone: "12", tunnels: [] },
  "2018": { province: "Anvers", commune: "ANTWERPEN", zone: "13", tunnels: [] },
  "2960": { province: "Anvers", commune: "BRECHT", zone: "13", tunnels: [] },
  "3960": { province: "Limbourg", commune: "BREE", zone: "3", tunnels: [] },
  "1570": { province: "Brabant Flamand", commune: "GALMAARDEN", zone: "7", tunnels: [] },
  "3870": { province: "Limbourg", commune: "HEERS", zone: "5", tunnels: [] },
  "3717": { province: "Limbourg", commune: "HERSTAPPE", zone: "5", tunnels: [] },
  "3050": { province: "Brabant Flamand", commune: "OUD-HEVERLEE", zone: "6", tunnels: [] },
  "3520": { province: "Limbourg", commune: "ZONHOVEN", zone: "4", tunnels: [] },
  "3360": { province: "Brabant Flamand", commune: "BIERBEEK", zone: "6", tunnels: [] },
  "1547": { province: "Brabant Flamand", commune: "Bever", zone: "7", tunnels: [] },
  "1850": { province: "Brabant Flamand", commune: "GRIMBERGEN", zone: "8", tunnels: [] },
  "1880": {
    province: "Brabant Flamand",
    commune: "KAPELLE-OP-DEN-BOS",
    zone: "8",
    tunnels: [],
  },
  "1785": { province: "Brabant Flamand", commune: "MERCHTEM", zone: "8", tunnels: [] },
  "2640": { province: "Anvers", commune: "MORTSEL", zone: "10", tunnels: [] },
  "3380": { province: "Brabant Flamand", commune: "GLABBEEK", zone: "6", tunnels: [] },
  "3930": { province: "Limbourg", commune: "HAMONT-ACHEL", zone: "3", tunnels: [] },
  "2320": { province: "Anvers", commune: "HOOGSTRATEN", zone: "12", tunnels: [] },
  "2235": { province: "Anvers", commune: "HULSHOUT", zone: "10", tunnels: [] },
  "3620": { province: "Limbourg", commune: "LANAKEN", zone: "4", tunnels: [] },
  "1840": { province: "Brabant Flamand", commune: "LONDERZEEL", zone: "8", tunnels: [] },
  "3120": { province: "Brabant Flamand", commune: "TREMELO", zone: "9", tunnels: [] },
  "1980": { province: "Brabant Flamand", commune: "ZEMST", zone: "9", tunnels: [] },
  "3590": { province: "Limbourg", commune: "DIEPENBEEK", zone: "4", tunnels: [] },
  "1500": { province: "Brabant Flamand", commune: "HALLE", zone: "7", tunnels: [] },
  "3730": { province: "Limbourg", commune: "HOESELT", zone: "5", tunnels: [] },
  "2550": { province: "Anvers", commune: "KONTICH", zone: "10", tunnels: [] },
  "1820": {
    province: "Brabant Flamand",
    commune: "STEENOKKERZEEL",
    zone: "9",
    tunnels: [],
  },
  "1930": { province: "Brabant Flamand", commune: "ZAVENTEM", zone: "9", tunnels: [] },
  "3290": { province: "Brabant Flamand", commune: "DIEST", zone: "1", tunnels: [] },
  "2910": { province: "Anvers", commune: "ESSEN", zone: "13", tunnels: [] },
  "1755": { province: "Brabant Flamand", commune: "GOOIK", zone: "7", tunnels: [] },
  "2450": { province: "Anvers", commune: "MEERHOUT", zone: "11", tunnels: [] },
  "1760": { province: "Brabant Flamand", commune: "ROOSDAAL", zone: "7", tunnels: [] },
  "1600": {
    province: "Brabant Flamand",
    commune: "SINT-PIETERS-LEEUW",
    zone: "7",
    tunnels: [],
  },
  "3800": { province: "Limbourg", commune: "SINT-TRUIDEN", zone: "5", tunnels: [] },
  "2940": { province: "Anvers", commune: "STABROEK", zone: "13", tunnels: [] },
  "3460": { province: "Brabant Flamand", commune: "BEKKEVOORT", zone: "1", tunnels: [] },
  "3140": { province: "Brabant Flamand", commune: "KEERBERGEN", zone: "9", tunnels: [] },
  "2250": { province: "Anvers", commune: "OLEN", zone: "10", tunnels: [] },
  "3550": { province: "Limbourg", commune: "HEUSDEN-ZOLDER", zone: "2", tunnels: [] },
  "3560": { province: "Limbourg", commune: "LUMMEN", zone: "2", tunnels: [] },
  "2520": { province: "Anvers", commune: "RANST", zone: "10", tunnels: [] },
  "2840": { province: "Anvers", commune: "RUMST", zone: "10", tunnels: [] },
  "2830": { province: "Anvers", commune: "WILLEBROEK", zone: "8", tunnels: [] },
  "2340": { province: "Anvers", commune: "BEERSE", zone: "12", tunnels: [] },
  "2820": { province: "Anvers", commune: "BONHEIDEN", zone: "9", tunnels: [] },
  "2220": { province: "Anvers", commune: "HEIST-OP-DEN-BERG", zone: "10", tunnels: [] },
  "2950": { province: "Anvers", commune: "KAPELLEN", zone: "13", tunnels: [] },
  "3400": { province: "Brabant Flamand", commune: "LANDEN", zone: "5", tunnels: [] },
  "1630": { province: "Brabant Flamand", commune: "LINKEBEEK", zone: "7", tunnels: [] },
  "3200": { province: "Brabant Flamand", commune: "AARSCHOT", zone: "1", tunnels: [] },
  "1620": { province: "Brabant Flamand", commune: "DROGENBOS", zone: "7", tunnels: [] },
  "3890": { province: "Limbourg", commune: "GINGELOM", zone: "5", tunnels: [] },
  "3945": { province: "Limbourg", commune: "HAM", zone: "2", tunnels: [] },
  "1560": { province: "Brabant Flamand", commune: "HOEILAART", zone: "6", tunnels: [] },
  "2390": { province: "Anvers", commune: "MALLE", zone: "12", tunnels: [] },
  "3770": { province: "Limbourg", commune: "RIEMST", zone: "4", tunnels: [] },
  "1970": {
    province: "Brabant Flamand",
    commune: "WEZEMBEEK-OPPEM",
    zone: "6",
    tunnels: [],
  },
  "3060": { province: "Brabant Flamand", commune: "BERTEM", zone: "6", tunnels: [] },
  "3740": { province: "Limbourg", commune: "BILZEN", zone: "4", tunnels: [] },
  "1700": { province: "Brabant Flamand", commune: "DILBEEK", zone: "7", tunnels: [] },
  "2570": { province: "Anvers", commune: "DUFFEL", zone: "10", tunnels: [] },
  "3040": { province: "Brabant Flamand", commune: "HULDENBERG", zone: "6", tunnels: [] },
  "2860": { province: "Anvers", commune: "SINT-KATELIJNE-WAVER", zone: "9", tunnels: [] },
  "3720": { province: "Limbourg", commune: "KORTESSEM", zone: "5", tunnels: [] },
  "1780": { province: "Brabant Flamand", commune: "WEMMEL", zone: "8", tunnels: [] },
  "2630": { province: "Anvers", commune: "AARTSELAAR", zone: "8", tunnels: [] },
  "2000": { province: "Anvers", commune: "ANTWERPEN", zone: "13", tunnels: [] },
  "2387": { province: "Anvers", commune: "BAARLE-HERTOG", zone: "12", tunnels: [] },
  "3530": { province: "Limbourg", commune: "HOUTHALEN-HELCHTEREN", zone: "3", tunnels: [] },
  "3350": { province: "Brabant Flamand", commune: "LINTER", zone: "5", tunnels: [] },
  "3680": { province: "Limbourg", commune: "MAASEIK", zone: "3", tunnels: [] },
  "1860": { province: "Brabant Flamand", commune: "MEISE", zone: "8", tunnels: [] },
  "2970": { province: "Anvers", commune: "SCHILDE", zone: "13", tunnels: [] },
  "2900": { province: "Anvers", commune: "SCHOTEN", zone: "13", tunnels: [] },
  "3080": { province: "Brabant Flamand", commune: "TERVUREN", zone: "6", tunnels: [] },
  "3580": { province: "Limbourg", commune: "BERINGEN", zone: "2", tunnels: [] },
  "3650": { province: "Limbourg", commune: "DILSEN-STOKKEM", zone: "4", tunnels: [] },
  "1750": { province: "Brabant Flamand", commune: "LENNIK", zone: "7", tunnels: [] },
  "2310": { province: "Anvers", commune: "RIJKEVORSEL", zone: "12", tunnels: [] },
  "1740": { province: "Brabant Flamand", commune: "TERNAT", zone: "7", tunnels: [] },
  "2290": { province: "Anvers", commune: "VORSELAAR", zone: "12", tunnels: [] },
  "3690": { province: "Limbourg", commune: "ZUTENDAAL", zone: "4", tunnels: [] },
  "2070": { province: "Anvers", commune: "ZWIJNDRECHT", zone: "13", tunnels: [] },
  "2020": { province: "Anvers", commune: "ANTWERPEN", zone: "13", tunnels: [] },
  "3500": { province: "Limbourg", commune: "HASSELT", zone: "2", tunnels: [] },
  "2270": { province: "Anvers", commune: "HERENTHOUT", zone: "10", tunnels: [] },
  "2275": { province: "Anvers", commune: "LILLE", zone: "12", tunnels: [] },
  "1830": { province: "Brabant Flamand", commune: "MACHELEN", zone: "9", tunnels: [] },
  "2360": { province: "Anvers", commune: "OUD-TURNHOUT", zone: "12", tunnels: [] },
  "3370": { province: "Brabant Flamand", commune: "BOUTERSEM", zone: "6", tunnels: [] },
  "2460": { province: "Anvers", commune: "KASTERLEE", zone: "11", tunnels: [] },
  "3070": { province: "Brabant Flamand", commune: "KORTENBERG", zone: "9", tunnels: [] },
  "2110": { province: "Anvers", commune: "WIJNEGEM", zone: "13", tunnels: [] },
  "3840": { province: "Limbourg", commune: "BORGLOON", zone: "5", tunnels: [] },
  "2880": { province: "Anvers", commune: "BORNEM", zone: "8", tunnels: [] },
  "2150": { province: "Anvers", commune: "BORSBEEK", zone: "13", tunnels: [] },
  "3970": { province: "Limbourg", commune: "LEOPOLDSBURG", zone: "3", tunnels: [] },
  "2547": { province: "Anvers", commune: "LINT", zone: "10", tunnels: [] },
  "3920": { province: "Limbourg", commune: "LOMMEL", zone: "11", tunnels: [] },
  "2040": { province: "Anvers", commune: "ANTWERPEN", zone: "13", tunnels: [] },
  "3450": { province: "Brabant Flamand", commune: "GEETBETS", zone: "2", tunnels: [] },
  "3940": { province: "Limbourg", commune: "HECHTEL-EKSEL", zone: "3", tunnels: [] },
  "3220": { province: "Brabant Flamand", commune: "HOLSBEEK", zone: "6", tunnels: [] },
  "3900": { province: "Limbourg", commune: "PELT", zone: "3", tunnels: [] },
  "2627": { province: "Anvers", commune: "SCHELLE", zone: "8", tunnels: [] },
  "3980": { province: "Limbourg", commune: "TESSENDERLO", zone: "1", tunnels: [] },
  "3300": { province: "Brabant Flamand", commune: "TIENEN", zone: "6", tunnels: [] },
  "2050": { province: "Anvers", commune: "ANTWERPEN", zone: "13", tunnels: [] },
  "3950": { province: "Limbourg", commune: "BOCHOLT", zone: "3", tunnels: [] },
  "2280": { province: "Anvers", commune: "GROBBENDONK", zone: "12", tunnels: [] },
  "2620": { province: "Anvers", commune: "HEMIKSEM", zone: "8", tunnels: [] },
  "1540": { province: "Brabant Flamand", commune: "HERNE", zone: "7", tunnels: [] },
  "1910": { province: "Brabant Flamand", commune: "KAMPENHOUT", zone: "9", tunnels: [] },
  "3470": { province: "Brabant Flamand", commune: "KORTENAKEN", zone: "2", tunnels: [] },
  "2330": { province: "Anvers", commune: "MERKSPLAS", zone: "12", tunnels: [] },
  "2530": { province: "Anvers", commune: "BOECHOUT", zone: "10", tunnels: [] },
  "2930": { province: "Anvers", commune: "BRASSCHAAT", zone: "13", tunnels: [] },
  "3790": { province: "Limbourg", commune: "Voeren", zone: "4", tunnels: [] },
  "3600": { province: "Limbourg", commune: "GENK", zone: "4", tunnels: [] },
  "3000": { province: "Brabant Flamand", commune: "LEUVEN", zone: "6", tunnels: [] },
  "1745": { province: "Brabant Flamand", commune: "OPWIJK", zone: "8", tunnels: [] },
  "2580": { province: "Anvers", commune: "PUTTE", zone: "9", tunnels: [] },
  "3270": {
    province: "Brabant Flamand",
    commune: "SCHERPENHEUVEL-ZICHEM",
    zone: "1",
    tunnels: [],
  },
  "3440": { province: "Brabant Flamand", commune: "ZOUTLEEUW", zone: "5", tunnels: [] },
  "3545": { province: "Limbourg", commune: "HALEN", zone: "2", tunnels: [] },
  "3020": { province: "Brabant Flamand", commune: "HERENT", zone: "9", tunnels: [] },
  "3320": { province: "Brabant Flamand", commune: "HOEGAARDEN", zone: "6", tunnels: [] },
  "1800": { province: "Brabant Flamand", commune: "VILVOORDE", zone: "9", tunnels: [] },
  "2480": { province: "Anvers", commune: "DESSEL", zone: "11", tunnels: [] },
  "2650": { province: "Anvers", commune: "EDEGEM", zone: "10", tunnels: [] },
  "3210": { province: "Brabant Flamand", commune: "LUBBEEK", zone: "6", tunnels: [] },
  "2470": { province: "Anvers", commune: "RETIE", zone: "11", tunnels: [] },
  "2350": { province: "Anvers", commune: "VOSSELAAR", zone: "12", tunnels: [] },
  "3665": { province: "Limbourg", commune: "AS", zone: "4", tunnels: [] },
  "2440": { province: "Anvers", commune: "GEEL", zone: "11", tunnels: [] },
  "3150": { province: "Brabant Flamand", commune: "HAACHT", zone: "9", tunnels: [] },
  "2200": { province: "Anvers", commune: "HERENTALS", zone: "10", tunnels: [] },
  "3540": { province: "Limbourg", commune: "HERK-DE-STAD", zone: "2", tunnels: [] },
  "2920": { province: "Anvers", commune: "KALMTHOUT", zone: "13", tunnels: [] },
  "2845": { province: "Anvers", commune: "NIEL", zone: "13", tunnels: [] },
  "3570": { province: "Limbourg", commune: "ALKEN", zone: "5", tunnels: [] },
  "1950": { province: "Brabant Flamand", commune: "KRAAINEM", zone: "6", tunnels: [] },
  "3630": { province: "Limbourg", commune: "MAASMECHELEN", zone: "4", tunnels: [] },
  "3670": { province: "Limbourg", commune: "OUDSBERGEN", zone: "3", tunnels: [] },
  "2870": { province: "Anvers", commune: "PUURS-SINT-AMANDS", zone: "8", tunnels: [] },
  "3110": { province: "Brabant Flamand", commune: "ROTSELAAR", zone: "9", tunnels: [] },
  "2990": { province: "Anvers", commune: "WUUSTWEZEL", zone: "13", tunnels: [] },
  "2370": { province: "Anvers", commune: "ARENDONK", zone: "11", tunnels: [] },
  "1730": { province: "Brabant Flamand", commune: "ASSE", zone: "8", tunnels: [] },
  "1650": { province: "Brabant Flamand", commune: "BEERSEL", zone: "7", tunnels: [] },
  "2590": { province: "Anvers", commune: "BERLAAR", zone: "10", tunnels: [] },
  "3640": { province: "Limbourg", commune: "KINROOI", zone: "3", tunnels: [] },
  "1770": { province: "Brabant Flamand", commune: "LIEDEKERKE", zone: "7", tunnels: [] },
  "2240": { province: "Anvers", commune: "ZANDHOVEN", zone: "12", tunnels: [] },
  "2060": { province: "Anvers", commune: "ANTWERPEN", zone: "13", tunnels: [] },
  "2850": { province: "Anvers", commune: "BOOM", zone: "8", tunnels: [] },
  "2500": { province: "Anvers", commune: "LIER", zone: "10", tunnels: [] },
  "2800": { province: "Anvers", commune: "MECHELEN", zone: "9", tunnels: [] },
  "2560": { province: "Anvers", commune: "NIJLEN", zone: "10", tunnels: [] },
  "3090": { province: "Brabant Flamand", commune: "OVERIJSE", zone: "6", tunnels: [] },
  "2030": { province: "Anvers", commune: "ANTWERPEN", zone: "13", tunnels: [] },
  "3130": { province: "Brabant Flamand", commune: "BEGIJNENDIJK", zone: "9", tunnels: [] },
  "2540": { province: "Anvers", commune: "HOVE", zone: "10", tunnels: [] },
  "2400": { province: "Anvers", commune: "MOL", zone: "11", tunnels: [] },
  "3990": { province: "Limbourg", commune: "PEER", zone: "3", tunnels: [] },
  "1670": { province: "Brabant Flamand", commune: "PEPINGEN", zone: "7", tunnels: [] },
  "2380": { province: "Anvers", commune: "RAVELS", zone: "12", tunnels: [] },
  "3390": { province: "Brabant Flamand", commune: "TIELT-WINGE", zone: "1", tunnels: [] },
  "2300": { province: "Anvers", commune: "TURNHOUT", zone: "12", tunnels: [] },
  "2260": { province: "Anvers", commune: "WESTERLO", zone: "10", tunnels: [] },
  "2160": { province: "Anvers", commune: "WOMMELGEM", zone: "13", tunnels: [] },
  "2230": { province: "Anvers", commune: "HERSELT", zone: "1", tunnels: [] },
};
