import ZipCodeFormController from "guest_website/controllers/ZipCodeFormController";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ServiceProvider } from "services/ServiceProvider";
import { confirmZipCodeError } from "store/actions/BookingSystemActions";
import { basicDefaultProps, BasicProps } from "ui/BasicProps";
import BookingConfirmation from "./BookingConfirmation";
import BookingForm from "./BookingForm";
import ZipCodeFormView from "./ZipCodeFormView";

type Props = BasicProps & {
  // dismiss: () => void;
  onContact: () => void;
  onConfirm:() => void;
  controller: ZipCodeFormController;
};

ZipCodeForm.defaultProps = {
  ...basicDefaultProps,
};

function ZipCodeForm(props: Props) {
  // const [controller, setController] = useState(
  //   ServiceProvider.getInstance().controllerFactory.createZipCodeFormController()
  // );

  // const [bookingFormShown, setBookingFormShown] = useState(false);

  // const hideBookingtForm = () => setBookingFormShown(false);

  // const showBookingForm = () => setBookingFormShown(true);

  const dispatch = useDispatch();

  return (
    <>
      <ZipCodeFormView
        checkField={checkField}
        checkZip={checkZip}
        onZipCodeChanged={onZipCodeChanged}
        onConfirm={onConfirm}
      />
      {/* {setUpBookingFormModal()} */}
    </>
  );

  function checkField(): boolean {
    return props.controller.checkField();
  }

  function checkZip(): boolean {
    return props.controller.checkZip();
  }

  function onZipCodeChanged(event: ChangeEvent<HTMLTextAreaElement>) {
    const zip = event.currentTarget.value;
    props.controller.onZipChanged(zip);
  }

  async function onConfirm() {
    try {
      //await controller.onConfirm();
      //props.dismiss();
      // showBookingForm();
      props.onConfirm();
    } catch (e) {
      dispatch(confirmZipCodeError(e.message));
    }
  }

  // function setUpBookingFormModal() {
  //   return (
  //     <Modal backdrop="static" size="xl" show={bookingFormShown} onHide={hideForm}>
  //       <Modal.Header closeButton />{" "}
  //       <BookingForm
  //         onContact={props.onContact}
  //         localiteInfo={controller.localiteInfo}
  //         postalCode={controller.localiteInfo.commune_code}
  //         dismiss={hideForm}
  //       />
  //     </Modal>
  //   );
  // }

  // function hideForm() {
  //   hideBookingtForm();
  //   props.dismiss();
  // }
}

export default ZipCodeForm;
