import { CalendarSlot } from "model/calendarSlot";
import { ZoneSelectionPolicy } from "model/policy/zoneSelectionPolicy";
import { Reservation } from "model/reservation";

export class Allocator {
  private zonePolicy: ZoneSelectionPolicy;

  constructor(zonePolicy: ZoneSelectionPolicy) {
    this.zonePolicy = zonePolicy;
  }

  allocateReservation(reservation: Reservation) {
    if (
      this.zonePolicy.canBeAllocatedToTunnel(
        reservation.date,
        reservation.isAfternoon,
        reservation.zone
      )
    ) {
        // COUCOU
    }
  }
}
