import React from "react";
import Color from "../graphic_helpers/Color";
import Icons from "assets/icons/icons.svg";
import { basicDefaultProps, BasicProps } from "ui/BasicProps";

type Props = BasicProps & {
  color: Color;
  size: number;
  name: string;
  onClick?: () => void;
};

SVGIcon.defaultProps = {...basicDefaultProps}

function SVGIcon(props: Props) {
  return (
    <svg
      onClick={(e) => {if (props.onClick) props.onClick()}}
      className={`icon icon-${props.name}` + " " + props.className}
      fill={props.color.toString()}
      height={props.size}
      width={props.size}>
      <use xlinkHref={`${Icons}#icon-${props.name}`} />
    </svg>
  );
}

export default SVGIcon;
