import SolidColor from "./SolidColor";
import Colors from "./Colors";

class Border {
  color: SolidColor;
  thickness: number;

  constructor(thickness: number, color?: SolidColor) {
    this.thickness = thickness;
    this.color = color ?? Colors.transparent;
  }

  static none() {
      return new Border(0);
  }

  public toString() {
    return this.thickness > 0 ? `${this.thickness}px solid ` + this.color.toString() : "none";
  }
}

export default Border;
