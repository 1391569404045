import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import { ConstantsState } from "store/reducers/stateTypes";
import { basicDefaultProps, BasicProps } from "ui/BasicProps";
import Divider from "ui/generic_components/Divider";
import SectionTitle from "ui/generic_components/SectionTitle";
import Text from "ui/generic_components/Text";
import { TextAlignment } from "ui/graphic_helpers/graphic_enums";

type Props = BasicProps & {
  header: string;
  text: string;
};

HeaderTextPage.defaultProps = {
  ...basicDefaultProps,
};

function HeaderTextPage(props: Props) {
  const { strings, theme }: ConstantsState = useSelector((state: RootState) => state.constants);

  return (
    <div style={{ backgroundColor: theme.backgroundColorA.toString() }}>
      <Container>
        <Divider height={4} />
        <SectionTitle alignment={TextAlignment.Left} title={props.header} color={theme.mainTextColor} />
        <Divider color={theme.accentColor} />
        <Text text={props.text} textAlign={TextAlignment.Left} color={theme.mainTextColor} />
        <Divider height={4} />
      </Container>
    </div>
  );
}

export default HeaderTextPage;
