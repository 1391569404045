// General
export const RESET: string = "RESET";

// Realisations
export const FETCH_REALISATIONS: string = "FETCH_COMPANY_REALISATIONS";
export const LOAD_MORE_REALISATIONS: string = "LOAD_MORE_REALISATIONS";
export const RESET_REALISATIONS: string = "RESET_REALISATIONS";

// Navigation
export const NAVIGATE_TO_HOME: string = "NAVIGATE_TO_HOME";
export const NAVIGATE_TO_SERVICE: string = "NAVIGATE_TO_SERVICE";
export const NAVIGATE_TO_REALISATIONS: string = "NAVIGATE_TO_REALISATIONS";
export const NAVIGATE_TO_INFORMATION: string = "NAVIGATE_TO_INFORMATION";
export const NAVIGATE_TO_COOKIE: string = "NAVIGATE_TO_INFORMATION";
export const NAVIGATE_TO_TERMS: string = "NAVIGATE_TO_INFORMATION";
export const NAVIGATE_TO_PRIVACY: string = "NAVIGATE_TO_INFORMATION";

// Constants
export const CHANGE_LANGUAGE: string = "CHANGE_LANGUAGE";
export const SWITCH_THEME: string = "SWITCH_THEME";

// Booking system
export const SEND_MAIL_FAILED: string = "SEND_MAIL_FAILED";
export const RESET_SEND_MAIL_ERROR: string = "RESET_SEND_MAIL_ERROR";
export const CONFIRM_ZIP_CODE_ERROR: string = "CONFIRM_ZIP_CODE_ERROR";
