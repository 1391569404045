import React, { CSSProperties, useState } from "react";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";
import Color from "ui/graphic_helpers/Color";
import BorderRadius from "ui/graphic_helpers/BorderRadius";
import SolidColor from "ui/graphic_helpers/SolidColor";
import Colors from "ui/graphic_helpers/Colors";
import "./Switch.css";

type Props = BasicProps & {
  value: boolean;
  width: number;
  backgroundColor: Color;
  activeColor: Color;
  innerSpace?: number;
  borderRadius: BorderRadius;
  buttonRadiusPercent: number;
  buttonColor: Color;
  onChange: (value: boolean) => void;
};

Switch.defaultProps = {
  ...basicDefaultProps,
  backgroundColor: SolidColor.fromRGBA(204, 204, 204),
  width: 60,
  activeColor: Colors.activeBlue,
  buttonColor: Colors.white,
  buttonRadiusPercent: 50,
  borderRadius: 34,
};

function Switch(props: Props) {
  return (
    <label className="switch" style={getStyle()}>
      {<input type="checkbox" onClick={onClick} checked={props.value} />}
      <span className="slider round" />
    </label>
  );

  function onClick(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    props.onChange(!props.value);
  }

  function getStyle() {
    return {
      background: "transparent",
      "--width": `${props.width}px`,
      "--bgcolor": props.backgroundColor.toString(),
      "--oncolor": props.activeColor.toString(),
      "--innerpadding": `${props.innerSpace ?? (props.width/15)}px`,
      "--borderradius": props.borderRadius.toString(),
      "--buttonradius": `${props.buttonRadiusPercent}%`,
      "--buttoncolor": props.buttonColor.toString(),
    } as CSSProperties;
  }
}

export default Switch;
