import SolidColor from "./SolidColor";

class Colors {
  static white: SolidColor = new SolidColor(255, 255, 255);
  static white70: SolidColor = new SolidColor(255, 255, 255, 0.7);
  static white80: SolidColor = new SolidColor(255, 255, 255, 0.85);
  static black: SolidColor = new SolidColor(0, 0, 0);
  static black38: SolidColor = new SolidColor(0, 0, 0, 0.38);
  static black45: SolidColor = new SolidColor(0, 0, 0, 0.45);
  static black54: SolidColor = new SolidColor(0, 0, 0, 0.54);
  static black70: SolidColor = new SolidColor(0, 0, 0, 0.7);
  static black87: SolidColor = new SolidColor(0, 0, 0, 0.87);
  static blackShadow: SolidColor = new SolidColor(0, 0, 0, 0.15);
  static blackText: SolidColor = new SolidColor(68, 68, 68);
  static activeBlue: SolidColor = new SolidColor(10, 132, 255);
  static turquoise: SolidColor = new SolidColor(3, 200, 168);
  static lightTurquoise: SolidColor = new SolidColor(137, 216, 211);
  static lighterTurquoise: SolidColor = new SolidColor(175, 238, 238);
  static transparent: SolidColor = new SolidColor(0, 0, 0, 0);
  static darkGrey: SolidColor = new SolidColor(40, 44, 52);
  static almostBlack: SolidColor = new SolidColor(16, 16, 16);
  static lightBlack: SolidColor = new SolidColor(32, 32, 32);
  static darkerAnthracite: SolidColor = new SolidColor(40, 40, 40);
  static darkAnthracite: SolidColor = new SolidColor(60, 60, 60);
  static anthracite: SolidColor = new SolidColor(85, 85, 85);
  static errorRed: SolidColor = new SolidColor(204, 0, 0);
  static red: SolidColor = new SolidColor(250, 0, 0);
  static extraLightBg: SolidColor = new SolidColor(239, 239, 244);
  static silver: SolidColor = new SolidColor(192,192,192);
  static whitePurple: SolidColor = new SolidColor(255,20,147, 0.5); 
}

export default Colors;
