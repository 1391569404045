import Colors from "./Colors";
import Color from "./Color";

class BoxShadow {
  static DEFAULT_BLUR: number = 8;
  yOffset: number;
  xOffset: number;
  blurRadius: number;
  spreadRadius: number;
  color: Color;

  private constructor(
    x: number,
    y: number,
    blur: number,
    spread: number,
    color: Color = Colors.blackShadow
  ) {
    this.xOffset = x;
    this.yOffset = y;
    this.blurRadius = blur;
    this.spreadRadius = spread;
    this.color = color;
  }

  static none(): BoxShadow {
    return new BoxShadow(0, 0, 0, 0);
  }

  static fromBlur(blur: number): BoxShadow {
    return new BoxShadow(0, 0, blur, 0, Colors.blackShadow);
  }

  static fromColor(color: Color): BoxShadow {
    return new BoxShadow(0, 0, this.DEFAULT_BLUR, 0, color);
  }

  static fromYOffsetAndBlur(y: number, blur: number): BoxShadow {
    return new BoxShadow(0, y, blur, 0, Colors.blackShadow);
  }

  static fromColorAndBlur(color: Color, blur: number): BoxShadow {
    return new BoxShadow(0, 0, blur, 0, color);
  }

  public toString(): string {
    return `${this.xOffset} ${this.yOffset} ${this.blurRadius}px ${
      this.spreadRadius
    }px ${this.color.toString()}`;
  }
}

export default BoxShadow;
