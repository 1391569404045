import { Theme } from "./theme";
import R from "../R";
import Colors from "ui/graphic_helpers/Colors";
import GradientColor from "ui/graphic_helpers/GradientColor";
import SolidColor from "ui/graphic_helpers/SolidColor";
import BoxShadow from "ui/graphic_helpers/BoxShadow";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";

const lightTheme: Theme = {
  id: "light",
  logo: R.IMAGES.logoBlack,
  themeName: "Particulier",
  mainTextColor: Colors.black,
  secondaryTextColor: Colors.black70,
  headerGradient: new GradientColor({
    angle: 5,
    colorA: SolidColor.fromRGBA(152, 251, 232),
    colorB: Colors.white,
    percentA: 0,
    percentB: 74,
  }),
  sectionGradient: new GradientColor({
    angle: 0,
    colorA: SolidColor.fromRGBA(152, 251, 232),
    colorB: Colors.white,
    percentA: 0,
    percentB: 60,
  }),
  turquoisGradient: new GradientColor({
    angle: 45,
    colorA: Colors.lightTurquoise,
    colorB: Colors.turquoise,
    percentA: 0,
    percentB: 50,
  }),
  navBarColor: Colors.white,
  navBarTextColor: Colors.black,
  navBarVariant: "light",
  footerColor: SolidColor.fromRGBA(245, 247, 250),
  footerTextColor: Colors.black,
  backgroundColorA: Colors.white,
  backgroundColorB: SolidColor.fromRGBA(152, 251, 232),
  accentColor: Colors.turquoise,
  materialCardColor: Colors.white,
  cardDropShadow: BoxShadow.fromBlur(10),
  bigCardDropShadow: BoxShadow.fromBlur(10),
  companiesCardStyle: {
    padding: EdgeInsets.fromLTRB(2, 8, 2, 8),
    fontSize: 20,
    iconSize: 80,
    col: "col-5",
    zIndex: 0,
    key: "small",
  },
  individualsCardStyle: {
    col: "col-7",
    zIndex: 1,
    iconSize: 120,
    fontSize: 28.32,
    padding: EdgeInsets.fromLTRB(3, 12.11, 3, 12.11),
    key: "big",
  },
};

export default lightTheme;
