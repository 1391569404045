import DateUtils from "guest_website/helpers/DateUtils";
import { CalendarSlot } from "model/calendarSlot";
import React, { ReactElement, useState } from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import { useMediaQuery } from "react-responsive";
import { ConstantsState } from "store/reducers/stateTypes";
import { basicDefaultProps, BasicProps } from "ui/BasicProps";
import Divider from "ui/generic_components/Divider";
import MaterialButton from "ui/generic_components/MaterialButton";
import Padding from "ui/generic_components/Padding";
import SVGIcon from "ui/generic_components/SVGIcon";
import Text from "ui/generic_components/Text";
import BoxShadow from "ui/graphic_helpers/BoxShadow";
import Colors from "ui/graphic_helpers/Colors";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import { FloatAlignment, FontWeight } from "ui/graphic_helpers/graphic_enums";
import BookingWidget from "./BookingWidget";

type Props = BasicProps & {
  onSlotChanged(slot: CalendarSlot): void;
  onConfirm(): void;
  onContactClicked(): void;
  isSlotSelected: boolean;
  selectedSlot: CalendarSlot;
  firstDate: Date;
  nbOfWeekToDisplay: number;
  localiteInfo: string;
  slotsMatrix: CalendarSlot[][][];
};

BookingFormView.defaultProps = { ...basicDefaultProps, nbOfWeekToDisplay: 6 };

function BookingFormView(props: Props) {
  const { strings }: ConstantsState = useSelector((state: RootState) => state.constants);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const [index, setIndex] = useState(0);

  const [errorMessageShown, setErrorMessageShown] = useState(false);

  const dayStrings = [
    strings.day_sun,
    strings.day_mon,
    strings.day_tue,
    strings.day_wed,
    strings.day_thu,
    strings.day_fri,
    strings.day_sat,
  ];

  return (
    <Padding padding={EdgeInsets.all(2)}>
      {renderTitle()}
      {renderLocaliteInfo()}
      <Divider height={2} />
      {renderWeeksNavigatorButtons(false)}
      {renderBookingCarousel()}
      {renderWeeksNavigatorButtons(true)}
      <Divider height={4} />
      {renderSelectedDate()}
      <Divider height={2} />
      {renderContactUs()}
      <Divider height={2} />
      {renderConfirmButton()}
    </Padding>
  );

  function renderLocaliteInfo() {
    return <></>;
  }

  function renderWeeksNavigatorButtons(second: boolean) {
    return second && !isMobile ? (
      <></>
    ) : (
      <Row style={{ justifyContent: "center" }} className="align-items-center">
        {renderNavigationButton("previous_page_solid", 0, loadPreviousWeek)}
        <Padding padding={EdgeInsets.symetricHorizontal(1.5)}>
          {renderPeriodIndicatorText()}
        </Padding>
        {renderNavigationButton("next_page_solid", props.nbOfWeekToDisplay - 1, loadNextWeek)}
      </Row>
    );
  }

  function renderPeriodIndicatorText() {
    let from: Date = new Date(props.firstDate);
    from.setDate(from.getDate() + index * 7);
    let to: Date = new Date(from);
    to.setDate(from.getDate() + 6);
    return (
      <>
        <span>
          <b style={{ color: Colors.blackText.toString(), fontWeight: FontWeight.w600 }}>
            {DateUtils.getStrDate(from)}
          </b>
        </span>{" "}
        <span style={{ color: Colors.blackText.toString() }}>{strings.to}</span>{" "}
        <span>
          <b style={{ color: Colors.blackText.toString(), fontWeight: FontWeight.w600 }}>
            {DateUtils.getStrDate(to)}
          </b>
        </span>
      </>
    );
  }

  function renderNavigationButton(iconName: string, hiddenIndex: number, onClick: () => void) {
    return (
      <MaterialButton
        onClick={index === hiddenIndex ? (e) => {} : (e) => onClick()}
        className={index === hiddenIndex ? "" : "hvr-grow"}
        boxShadow={BoxShadow.none()}
        color={Colors.transparent}
        withCursorPointer={index !== hiddenIndex}
        padding={EdgeInsets.zero()}>
        <SVGIcon
          color={index === hiddenIndex ? Colors.transparent : Colors.turquoise}
          size={34}
          name={iconName}></SVGIcon>
      </MaterialButton>
    );
  }

  function loadPreviousWeek() {
    setIndex(index - 1);
  }

  function loadNextWeek() {
    setIndex(index + 1);
  }

  function renderBookingCarousel() {
    return (
      <Carousel activeIndex={index} indicators={false} controls={false} interval={null}>
        {buildBookingWidgetsForCarousel()}
      </Carousel>
    );
  }

  function buildBookingWidgetsForCarousel() {
    let items: ReactElement[] = [];
    for (let i = 0; i < props.nbOfWeekToDisplay; i++) {
      let bookingWidgetFirstDate = new Date(props.firstDate);
      bookingWidgetFirstDate.setDate(props.firstDate.getDate() + i * 7);
      items.push(
        <Carousel.Item>
          <Padding padding={EdgeInsets.all(1)}>
            <BookingWidget
              daySlotList={props.slotsMatrix[i]}
              selectedSlot={props.selectedSlot}
              onSlotChanged={onSlotChanged}
              firstDate={bookingWidgetFirstDate}
              isSlotSelected={props.isSlotSelected}
            />
          </Padding>
        </Carousel.Item>
      );
    }
    return items;
  }

  function onSlotChanged(slot: CalendarSlot) {
    setErrorMessageShown(false);
    props.onSlotChanged(slot);
  }

  function renderTitle() {
    return (
      <Padding padding={EdgeInsets.only({ right: 2 })}>
        <Row>
          <Col sm={12} md={12} xl={6} lg={12}>
            <Padding padding={EdgeInsets.onlyBottom(2)}>
              <Text text={strings.ask_time_slot} fontSize={20} fontWeight={FontWeight.w500} />
            </Padding>
          </Col>
          <Col sm={12} md={12} xl={2} lg={12}>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              className="align-items-top">
              <span
                style={{
                  marginRight: "10px",
                  width: "25px",
                  height: "25px",
                  background: Colors.white.toString(),
                  borderRadius: "4px",
                  boxShadow: BoxShadow.fromBlur(5).toString(),
                }}
              />
              <Text text={strings.available} fontSize={14} />
            </div>
          </Col>
          <Col sm={12} md={12} xl={2} lg={12}>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              className="align-items-top">
              <span
                style={{
                  marginRight: "10px",
                  width: "25px",
                  height: "25px",
                  background: Colors.silver.toString(),
                  borderRadius: "4px",
                  boxShadow: BoxShadow.fromBlur(5).toString(),
                }}
              />
              <Text text={strings.unavailable} fontSize={14} />
            </div>
          </Col>
          <Col sm={12} md={12} xl={2} lg={12}>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              className="align-items-top">
              <span
                style={{
                  marginRight: "10px",
                  width: "25px",
                  height: "25px",
                  background: Colors.white.toString(),
                  border: "2px solid " + Colors.whitePurple.toString(),
                  borderRadius: "4px",
                  boxShadow: BoxShadow.fromBlur(5).toString(),
                }}
              />
              <Text text={strings.preferable} fontSize={14} />
            </div>
          </Col>
        </Row>
      </Padding>
    );
  }

  function renderSelectedDate() {
    return (
      <>
        <Text text={strings.selected_date} fontSize={17} fontWeight={FontWeight.w500} />
        <Divider height={0.3} />
        <Text
          text={
            props.isSlotSelected
              ? dayStrings[props.selectedSlot.getDate().getDay()] +
                " " +
                DateUtils.getStrDate(props.selectedSlot.getDate()) +
                ", " +
                (props.selectedSlot.isAfternoon ? strings.afternoon : strings.morning)
              : strings.no_date_selected
          }
          fontSize={16}
          fontWeight={FontWeight.w400}
        />
      </>
    );
  }

  function renderContactUs() {
    return (
      <>
        <span style={{ color: Colors.blackText.toString() }}>{strings.no_available_slot}</span>
        <span>
          {" " + strings.dont_hesistate_to_1}
          <span
            className="cursor-pointer"
            onClick={(e: React.MouseEvent) => props.onContactClicked()}
            style={{ color: Colors.turquoise.toString() }}>
            contact
          </span>
          {strings.dont_hesistate_to_2}
        </span>
      </>
    );
  }

  function renderConfirmButton() {
    return (
      <Padding padding={EdgeInsets.all(2)}>
        <Row className="align-items-center float-right">
          <Padding padding={EdgeInsets.only({ right: 1 })}>
            <Text
              text={strings.no_date_selected}
              fontSize={15}
              fontWeight={FontWeight.w400}
              color={errorMessageShown ? Colors.errorRed : Colors.transparent}
            />
          </Padding>
          <MaterialButton
            id="submit-booking-button"
            className="hvr-forward"
            color={Colors.turquoise}
            float={FloatAlignment.Right}
            onClick={onConfirm}>
            <Text text={strings.confirm} color={Colors.white} />
          </MaterialButton>
        </Row>
      </Padding>
    );
  }

  function onConfirm() {
    if (props.isSlotSelected) {
      props.onConfirm();
    } else {
      setErrorMessageShown(true);
    }
  }
}

export default BookingFormView;
