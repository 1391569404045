import React, { CSSProperties } from "react";
import Color from "../graphic_helpers/Color";
import Colors from "../graphic_helpers/Colors";
import BoxShadow from "../graphic_helpers/BoxShadow";
import EdgeInsets from "../graphic_helpers/EdgeInsets";
import BorderRadius from "../graphic_helpers/BorderRadius";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";

type Props = BasicProps & {
  color: Color;
  borderRadius: BorderRadius;
  boxShadow: BoxShadow;
  padding: EdgeInsets;
  cursorOnHover: boolean;
  borderWidth: number;
  borderColor: Color;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

Material.defaultProps = {
  ...basicDefaultProps,
  color: Colors.white,
  borderRadius: BorderRadius.zero(),
  boxShadow: BoxShadow.fromBlur(8),
  padding: EdgeInsets.zero(),
  borderColor: Colors.transparent,
  borderWidth: 0,
  cursorOnHover: false,
};

function Material(props: Props) {
  
  return (
    <div className={props.className} style={getStyle()} onClick={props.onClick}>
      {props.children}
    </div>
  );

  function getStyle(): CSSProperties {
    return {
      ...props.style,
      zIndex: props.zIndex,
      display: "block",
      padding: props.padding.toString(),
      backgroundColor: props.color.toString(),
      border: props.borderWidth.toString() + "px solid " + props.borderColor.toString(),
      borderRadius: props.borderRadius.toString(),
      boxShadow: props.boxShadow.toString(),
      cursor: hasHoverCursor() ? "pointer" : "default",
    };
  }

  function hasHoverCursor(): boolean {
    return props.cursorOnHover || props.onClick !== null || props.onClick !== undefined;
  }
}

export default Material;
