import {
  NAVIGATE_TO_HOME,
  NAVIGATE_TO_SERVICE,
  NAVIGATE_TO_REALISATIONS,
  NAVIGATE_TO_INFORMATION,
  RESET,
  NAVIGATE_TO_PRIVACY,
  NAVIGATE_TO_TERMS,
  NAVIGATE_TO_COOKIE,
} from "../actions/action_types";
import { NavigationState } from "./stateTypes";

const initialState: NavigationState = {
  canScrollToServices: true,
  canScrollToRealisations: true,
};

function NavigationReducer(state = initialState, action: any) {
  switch (action.type) {
    case NAVIGATE_TO_HOME:
      return {
        ...state,
        canScrollToServices: true,
        canScrollToRealisations: true,
      };
    case NAVIGATE_TO_PRIVACY:
      return {
        ...state,
        canScrollToServices: false,
        canScrollToRealisations: false,
      };
    case NAVIGATE_TO_TERMS:
      return {
        ...state,
        canScrollToServices: false,
        canScrollToRealisations: false,
      };
    case NAVIGATE_TO_COOKIE:
      return {
        ...state,
        canScrollToServices: false,
        canScrollToRealisations: false,
      };
    case NAVIGATE_TO_SERVICE:
      return {
        ...state,
        canScrollToServices: true,
        canScrollToRealisations: false,
      };
    case NAVIGATE_TO_REALISATIONS:
      return {
        ...state,
        canScrollToServices: false,
        canScrollToRealisations: false,
      };
    case NAVIGATE_TO_INFORMATION:
      return {
        ...state,
        canScrollToServices: false,
        canScrollToRealisations: false,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return { ...state };
  }
}

export default NavigationReducer;
