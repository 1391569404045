import { fireNameSpace } from "firebaseConfig";

export class Reservation {
  static DEFAULT_MEETING_TIME_IN_MINUTES: number = 60;

  public isPlanned: boolean;
  public isAfternoon: boolean;
  public date: Date;
  public name: string;
  public surname: string;
  public tel: string;
  public remarks: string;
  public mail: string;
  public dayOrder: number;
  public zone: string;
  public duration: number;
  public postalCode: string;
  public tunnel: string;
  public address: string;

  constructor(
    isPlanned: boolean,
    isAfternoon: boolean,
    date: Date,
    name: string,
    surname: string,
    tel: string,
    remarks: string,
    mail: string,
    dayOrder: number,
    zone: string,
    postalCode: string,
    tunnel: string,
    address: string,
    duration: number
  ) {
    this.isPlanned = isPlanned;
    this.isAfternoon = isAfternoon;
    this.date = date;
    this.name = name;
    this.surname = surname;
    this.tel = tel;
    this.remarks = remarks;
    this.mail = mail;
    this.dayOrder = dayOrder;
    this.zone = zone;
    this.postalCode = postalCode;
    this.tunnel = tunnel;
    this.address = address;
    this.duration = duration;
  }

  static fromData(data: any): Reservation {
    return new this(
      data.isPlanned,
      data.isAfternoon,
      data.date.toDate(),
      data.name,
      data.surname,
      data.tel,
      data.remarks,
      data.mail,
      data.dayOrder,
      data.zone,
      data.postalCode,
      data.tunnel,
      data.address,
      data.duration ? data.duration : this.DEFAULT_MEETING_TIME_IN_MINUTES
    );
  }

  isTunnelLocated(): boolean {
    return this.tunnel === "-1";
  }

  isPotentialJoker(): boolean {
    return this.zone === "1" && !this.isTunnelLocated();
  }

  isPotentiallyZoneLocated(): boolean {
    return !this.isTunnelLocated();
  }

  isJoker(alreadyJoker: boolean): boolean {
    return this.isPotentialJoker() && !alreadyJoker;
  }

  isZoneLocated(alreadyJoker: boolean): boolean {
    return !this.isJoker(alreadyJoker) && !this.isTunnelLocated();
  }

  toMap() {
    return {
      isPlanned: this.isPlanned,
      isAfternoon: this.isAfternoon,
      date: fireNameSpace.Timestamp.fromMillis(this.date.getTime()),
      name: this.name,
      surname: this.surname,
      tel: this.tel,
      remarks: this.remarks,
      mail: this.mail,
      dayOrder: this.dayOrder,
      zone: this.zone,
      postalCode: this.postalCode,
      tunnel: this.tunnel,
      address: this.address,
      duration: this.duration,
    };
  }
}
