import { BookingSystem } from "./BookingSystem";
import ContactFormData from "./ContactFormData";
import { DataAccessor } from "services/firestore/DataAccessor";
import { ServiceProvider } from "services/ServiceProvider";
import store from "store/store";
import checkDataValidity from "./contactFormDataValidation";

export class BookingSystemService implements BookingSystem {
  async sendMail(data: ContactFormData): Promise<void> {
    checkDataValidity(data);
    try {
      const dataAccessor: DataAccessor = ServiceProvider.getInstance().dataAccessor;
      await dataAccessor.sendMail(data);
    } catch (e) {
      throw new Error(store.getState().constants.strings.send_mail_unknown_error);
    }
  }
}
