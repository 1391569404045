import { FETCH_REALISATIONS, LOAD_MORE_REALISATIONS, RESET_REALISATIONS } from "./action_types";

export function fetchRealisations(photoNames: string[]) {
  return { type: FETCH_REALISATIONS, photoNames: photoNames };
}

export function loadMoreRealisations(realisationUrls: string[]) {
  console.info("Loading more realisations...");
  return { type: LOAD_MORE_REALISATIONS, realisationUrls: realisationUrls };
}

export function resetRealisations() {
  return { type: RESET_REALISATIONS }
}