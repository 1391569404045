import React, { CSSProperties } from "react";
import Color from "../graphic_helpers/Color";
import Colors from "../graphic_helpers/Colors";
import BoxShadow from "../graphic_helpers/BoxShadow";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";
import EdgeInsets from "../graphic_helpers/EdgeInsets";
import { FloatAlignment } from "../graphic_helpers/graphic_enums";
import Border from "ui/graphic_helpers/Border";

type Props = BasicProps & {
  color: Color;
  float: FloatAlignment;
  borderRadius: number;
  boxShadow: BoxShadow;
  border: Border;
  withCursorPointer: boolean;
  padding: EdgeInsets;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

MaterialButton.defaultProps = {
  ...basicDefaultProps,
  color: Colors.activeBlue,
  boxShadow: BoxShadow.fromBlur(10),
  border: Border.none,
  withCursorPointer: true,
  float: FloatAlignment.None,
  padding: EdgeInsets.symetric({vertical: 0.75, horizontal: 1.5}),
  borderRadius: 6,
}

function MaterialButton(props: Props) {
  let style = getStyle();

  return (
    <div id={props.id} className={ (props.withCursorPointer ? "cursor-pointer " : "") + props.className} style={style} onClick={props.onClick}>
      {props.children}
    </div>
  );

  function getStyle(): CSSProperties {
    return {
      float: props.float,
      display: "inline-block",
      outline: 0,
      boxShadow: props.boxShadow.toString(),
      padding: props.padding.toString(),
      borderRadius: props.borderRadius,
      border: props.border.toString(),
      background: props.color.toString(),
    };
  }
}

export default MaterialButton;
