import { Strings } from "assets/strings/strings";
import DateUtils from "guest_website/helpers/DateUtils";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers/RootReducer";
import { basicDefaultProps, BasicProps } from "ui/BasicProps";
import Material from "ui/generic_components/Material";
import Text from "ui/generic_components/Text";
import BorderRadius from "ui/graphic_helpers/BorderRadius";
import Colors from "ui/graphic_helpers/Colors";
import EdgeInsets from "ui/graphic_helpers/EdgeInsets";
import { FontWeight, TextAlignment } from "ui/graphic_helpers/graphic_enums";

type Props = BasicProps & {
  date: Date;
  isMorning: boolean;
  isSelected: boolean;
  isDisabled: boolean;
  isPreferred: boolean;
  onClicked(): void;
};

BookingSlot.defaultProps = {
  ...basicDefaultProps,
  isSelected: false,
};

function BookingSlot(props: Props) {
  const strings: Strings = useSelector((state: RootState) => state.constants.strings);

  let radius: BorderRadius = props.isMorning
    ? BorderRadius.only({ topLeft: 7, topRight: 7 })
    : BorderRadius.only({ bottomLeft: 7, bottomRight: 7 });
  return (
      <Material
      onClick={(e) => props.onClicked()}
        className={props.className}
        borderRadius={radius}
        padding={EdgeInsets.symetric({ horizontal: 1.5, vertical: 1.5 })}
        borderWidth={props.isPreferred ? 2.5 : 0}
        borderColor={props.isPreferred ? Colors.whitePurple : Colors.white}
        color={
          props.isDisabled ? Colors.silver : props.isSelected ? Colors.lightTurquoise : Colors.white
        }>
        {renderDateText()}
        {renderMorningOrAfternoonText()}
      </Material>
  );

  function renderMorningOrAfternoonText() {
    return (
      <Text
        color={Colors.blackText}
        fontSize={14}
        textAlign={TextAlignment.Center}
        fontWeight={FontWeight.w500}
        text={props.isMorning ? strings.morning : strings.afternoon}
      />
    );
  }

  function renderDateText() {
    return (
      <Text
        fontSize={14}
        textAlign={TextAlignment.Center}
        fontWeight={FontWeight.w400}
        color={Colors.blackText}
        text={DateUtils.getStrDate(props.date)}
      />
    );
  }
}

export default BookingSlot;
