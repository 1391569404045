import React from "react";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";

type Props = BasicProps;

Center.defaultProps = basicDefaultProps;

function Center(props: Props) {
  return (
    <div
      style={{
        display: "inline-block",
        alignContent: "center",
        alignSelf: "center",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
        zIndex: props.zIndex,
      }}>
      {props.children}
    </div>
  );
}

export default Center;
