import { CalendarSlot } from "model/calendarSlot";
import { DayInfo } from "model/dayInfo";
import BookingFormController from "./BookingFormController";

export default class BookingFromControllerImpl implements BookingFormController {
  public selectedSlot: CalendarSlot;
  public isSlotSelected: boolean;

  constructor(slot?: CalendarSlot) {
    if (!slot) {
      this.selectedSlot = CalendarSlot.empty();
      this.isSlotSelected = false;
    } else {
      this.selectedSlot = slot;
      this.isSlotSelected = true;
    }
  }

  onSlotChanged(slot: CalendarSlot): void {
    if (slot) {
      this.selectedSlot = slot;
      this.isSlotSelected = true;
    }
  }
  onConfirm(): Promise<void> {
    throw new Error("Method not implemented.");
  }
}
