export type ZoneAdja = { [id: string]: string[] };
export const zoneAdja: ZoneAdja = {
  "1": ["10", "2", "9", "6"],
  "2": ["1", "11", "10", "3", "4", "5", "6"],
  "3": ["11", "2", "4"],
  "4": ["3", "2", "5"],
  "5": ["6", "2", "4"],
  "6": ["7", "9", "1", "5"],
  "7": ["8", "6"],
  "8": ["13", "10", "9", "7"],
  "9": ["8", "10", "1", "6"],
  "10": ["8", "13", "12", "11", "2", "1", "9"],
  "11": ["12", "10", "2", "3"],
  "12": ["13", "10", "11"],
  "13": ["8", "10", "12"],
};

export function isZoneAdjacent(zone1: string, zone2: string): boolean {
  return zoneAdja[zone1].includes(zone2);
}
