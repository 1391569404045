import { Theme } from "assets/themes/theme";
import strings, { Strings } from "assets/strings/strings";
import darkTheme from "assets/themes/darkTheme";
import lightTheme from "assets/themes/lightTheme";

export const saveThemeToLocalStorage = (theme: Theme) => {
  localStorage.setItem("theme", theme.id);
};

export const loadThemeFromLocalStorage = (): Theme => {
  let storageTheme: string | null = localStorage.getItem("theme");
  switch (storageTheme) {
    case darkTheme.id:
      return darkTheme;
    case lightTheme.id:
      return lightTheme;
    default:
      return darkTheme;
  }
};

export const saveLanguageToLocalStorage = (lang: string) => {
  localStorage.setItem('lang', lang);
}

export const loadLanguageFromLocalStorage = (): Strings => {
  let storageLang: string | null = localStorage.getItem('lang');
  if (storageLang) {
    let language: Strings = strings[storageLang];
    if (language) {
      return language;
    }
  }
  return strings.nl;  
}