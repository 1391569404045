import { DataAccessor } from "./firestore/DataAccessor";
import { BookingSystem } from "./booking_system/BookingSystem";
import ControllerFactory from "./factory/ControllerFactory";


export class ServiceProvider {

  private static instance: ServiceProvider;
  public dataAccessor: DataAccessor;
  public bookingSystem: BookingSystem;
  public controllerFactory: ControllerFactory;

  private constructor(dataAccessor: DataAccessor, bookingSystem: BookingSystem, controllerFactory: ControllerFactory) {
    this.dataAccessor = dataAccessor;
    this.bookingSystem = bookingSystem;
    this.controllerFactory = controllerFactory;
  };

  public static getInstance(): ServiceProvider {
    if (!ServiceProvider.instance) {
      throw new Error("There is no instance yet!");
    }
    return ServiceProvider.instance;
  }

  public static init(dataAccessor: DataAccessor, bookingSystem: BookingSystem, controllerFactory: ControllerFactory) {
    if (ServiceProvider.instance) {
      throw new Error("Singleton is already initialized!");
    }
    ServiceProvider.instance = new ServiceProvider(dataAccessor, bookingSystem, controllerFactory);
  }
}