import { DayInfo } from "model/dayInfo";
import { Reservation } from "model/reservation";
import { areDatesEqualWithDayPrecision } from "utils";
import { CalendarSlot } from "./calendarSlot";

export abstract class ReservationsHandler {
  public reservations: Reservation[];

  constructor(reservations: Reservation[]) {
    this.reservations = reservations;
  }

  abstract doesReservationAgreeWithPolicy(slot: CalendarSlot, postalCode: string): boolean;

  getReservationsFromDate(date: Date): Reservation[] {
    return this.reservations.filter((reservation: Reservation) => {
      return areDatesEqualWithDayPrecision(reservation.date, date);
    });
  }

  getAfternoonReservations(date: Date): Reservation[] {
    return this.getReservationsForCorrespondingHalfDay(date, true);
  }
  getMorningReservations(date: Date): Reservation[] {
    return this.getReservationsForCorrespondingHalfDay(date, false);
  }
  getReservationsForCorrespondingHalfDay(date: Date, isAfternoon: boolean): Reservation[] {
    return this.reservations.filter((reservation: Reservation) => {
      return reservation.isAfternoon === isAfternoon && areDatesEqualWithDayPrecision(date, reservation.date);
    });
  }

  getSlotRes(date: Date, isAfternoon: boolean): Reservation[] {
    return this.getReservationsForCorrespondingHalfDay(date, isAfternoon);
  }

  getZoneOfSlot(dayInfo: DayInfo, isAfternoon: boolean): string {
    const slots = this.getSlotRes(dayInfo.date, isAfternoon);
    let zone = "0";
    let alreadyJoker = false;
    slots.forEach((res) => {
      if (res.isZoneLocated(alreadyJoker)) {
        zone = res.zone;
      } else if (res.isJoker(alreadyJoker)) {
        alreadyJoker = true;
      }
    });
    return zone;
  }
}
