export default class R {
  static IMAGES = {
    logoWhite: "/resources/images/phoenix_logo_white_text_2.png",
    logoBlack: "/resources/images/phoenix_logo_black_shadow_all_compressed.png",
    logoTurquoisWhite: "/resources/images/logo_phoenix_bigger_shadow_compressd.png",
    logoOnly: "/resources/images/phoenix-only.png",
    particularRealisation: "/resources/images/particular.jpg",
    companyRealisation: "/resources/images/bedrijf.jpg",
    happyFace: "/resources/images/happy_face.png",
  };

  static ICONS = {
    archive:"wallpaper",
    wallpaper: "wallpaper_solid",
    bike: "motorcycle_solid",
    ad_sign: "billboard_solid",
    sandblasting: "sheet_metal_solid",
    colour_film: "paint_palette_solid",
    concept: "design_solid",
    lettering: "lettering_solid",
    vehicle: "pickup_truck_solid",
    info: "info_solid",
    facebook: "facebook",
  };
}
