enum TextAlignment {
  Left = "left",
  Right = "right",
  Center = "center",
}

enum ItemsAlignment {
  Left = "flex-left",
  Right = "flex-right",
  Center = "center",
}

enum FontWeight {
  w300 = 300,
  w400 = 400,
  w500 = 500,
  w600 = 600,
  bold = "bold",
  normal = "normal",
}

enum FloatAlignment {
  Left = "left",
  Right = "right",
  None = "none",
}

export { TextAlignment, ItemsAlignment, FontWeight, FloatAlignment };
