import store from "store/store";
import ContactFormData from "./ContactFormData";
import isEmail from "sane-email-validation";

function checkDataValidity(data: ContactFormData) {
  checkFieldsNotEmpty(data);
  checkEmailValidity(data.email);
}

function checkFieldsNotEmpty(data: ContactFormData) {
  if (contactFormHasAnEmptyField(data)) {
    throw new Error(store.getState().constants.strings.send_mail_empty_field_error);
  }
}

function contactFormHasAnEmptyField(data: ContactFormData): boolean {
  return (
    isFieldEmpty(data.email) || isFieldEmpty(data.subject) || isFieldEmpty(data.message)
  );
}

function isFieldEmpty(field: string) {
  return !field || field.trim() === "";
}

function checkEmailValidity(email: string) {
  if (!isEmail(email)) {
    throw new Error(store.getState().constants.strings.send_mail_invalid_address);
  }
}

export default checkDataValidity;