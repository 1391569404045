import React, { Component, ReactNode } from "react";
import "./App.css";
import WebsiteRouter from "./WebsiteRouter";

class App extends Component {
  render(): ReactNode {
    return (
      <WebsiteRouter/>
    );
  }
  
}

export default App;
