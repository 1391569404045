import DateUtils from "guest_website/helpers/DateUtils";
import { CalendarSlot } from "model/calendarSlot";
import { Policy } from "./Policy";

export class AvailabilityChecker {
  private startWindow: Date;
  private endWindow: Date;
  private policies: Policy[];
  private calendarSlots: CalendarSlot[];

  constructor(
    startWindow: Date,
    endWindow: Date,
    policies: Policy[],
    calendarSlots: CalendarSlot[]
  ) {
    this.startWindow = startWindow;
    this.endWindow = endWindow;
    this.policies = policies;
    this.calendarSlots = calendarSlots;
  }

  SetAvailabilityForWindow(postalCode: string): void {
    const currentSlots = this.calendarSlots.filter((slot) => {
      return DateUtils.dateBetweenWindow(slot.dayInfo.date, this.startWindow, this.endWindow);
    });
    currentSlots.forEach((slot) => {
      if (this.doesSlotAgreeWithAllPolicies(slot, postalCode)) {
        slot.isAvailable = true;
        slot.preferenceLevel = this.getPreference(slot, postalCode);
      } else {
        slot.isAvailable = false;
        slot.isPreferred = false;
      }
    });
  }

  private doesSlotAgreeWithAllPolicies(slot: CalendarSlot, postalCode: string): boolean {
    let policyAgreement = true;
    this.policies.forEach((policy) => {
      if (!policy.doesReservationAgreeWithPolicy(slot, postalCode)) {
        policyAgreement = false;
      }
    });
    return policyAgreement;
  }

  private getPreference(slot: CalendarSlot, postalCode: string): number {
    let preference = 0;
    this.policies.forEach((policy) => {
      if (policy.isItPreferredSlot(slot, postalCode)) {
        preference++;
      }
    });
    return preference;
  }
}
