import { CHANGE_LANGUAGE, SWITCH_THEME } from "./action_types";
import { Language } from "assets/strings/strings";

export function changeLanguage(lang: Language) {
  return { type: CHANGE_LANGUAGE, lang: lang }
}

export function switchTheme() {
  return { type: SWITCH_THEME }
}
