import React, { CSSProperties } from "react";
import Color from "../graphic_helpers/Color";
import Colors from "../graphic_helpers/Colors";
import { BasicProps, basicDefaultProps } from "ui/BasicProps";

enum DividerDirection {
  top = "none",
  bottom = "rotate(180deg)",
}

class SVGPath {
  opacity: number;
  drawing: string;

  constructor(drawing: string, opacity: number = 1) {
    this.drawing = drawing;
    this.opacity = opacity;
  }
}

class DividerShapes {
  static AsymetricTriangle: SVGPath[] = [new SVGPath("M1200 0L0 0 892.25 114.72 1200 0z")];
  static InvertedAsymetricTriangle: SVGPath[] = [
    new SVGPath("M892.25 114.72L0 0 0 120 1200 120 1200 0 892.25 114.72z"),
  ];
  static Wave: SVGPath[] = [
    new SVGPath(
      "M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
    ),
  ];
  static InvertedWave: SVGPath[] = [
    new SVGPath(
      "M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
    ),
  ];
  static Tilt: SVGPath[] = [new SVGPath("M1200 120L0 16.48 0 0 1200 0 1200 120z")];
  static TriShadowAsymetricTriangle: SVGPath[] = [
    new SVGPath("M1200 0L0 0 892.25 114.72 1200 0z", 0.25),
    new SVGPath("M1200 0L0 0 892.25 100.72 1200 0z", 0.5),
    new SVGPath("M1200 0L0 0 892.25 88 1200 0z"),
  ];
  static InvertedTriShadowAsymetricTriangle: SVGPath[] = [
    new SVGPath("M892.25 90L0 0 0 120 1200 120 1200 0 892.25 90z", 0.25),
    new SVGPath("M892.25 102.72L0 0 0 120 1200 120 1200 0 892.25 102.72z", 0.5),
    new SVGPath("M892.25 114.72L0 0 0 120 1200 120 1200 0 892.25 114.72z"),
  ];
  static DoubleShadowAsymetricTriangle: SVGPath[] = [
    new SVGPath("M1200 0L0 0 892.25 114.72 1200 0z", 0.5),
    new SVGPath("M1200 0L0 0 892.25 88 1200 0z"),
  ];
  static InvertedDoubleShadowAsymetricTriangle: SVGPath[] = [
    new SVGPath("M892.25 90L0 0 0 120 1200 120 1200 0 892.25 90z", 0.5),
    new SVGPath("M892.25 114.72L0 0 0 120 1200 120 1200 0 892.25 114.72z"),
  ];
}

type Props = BasicProps & {
  direction: DividerDirection;
  horizontalFlip: boolean;
  verticalFlip: boolean;
  dividerColor: Color;
  shapeColor: Color;
  height: number;
  width: number;
  shape: SVGPath[];
};

ShapeDivider.defaultProps = {
  ...basicDefaultProps,
  direction: DividerDirection.top,
  horizontalFlip: false,
  verticalFlip: false,
  dividerColor: Colors.black,
  shapeColor: Colors.white,
  shape: DividerShapes.Tilt,
  height: 100,
  width: 100,
};

function ShapeDivider(props: Props) {
  return (
    <div style={{ transform: formatTransfromProperty() }}>
      <div style={getDivStyle()}>
        <svg viewBox="0 0 1200 120" preserveAspectRatio="none" style={getSvgStyle()}>
          {props.shape.map((svgPath) => {
            return <path key={Math.random().toString()} d={svgPath.drawing} opacity={svgPath.opacity} />;
          })}
        </svg>
      </div>
    </div>
  );

  function formatTransfromProperty(): string {
    let property;
    if (noTransformation()) {
      property = "none";
    } else {
      property =
        (props.horizontalFlip ? "rotateY(180deg) " : "") +
        (props.verticalFlip ? "rotateX(180deg)" : "");
    }
    return property;
  }

  function noTransformation(): boolean {
    return !props.horizontalFlip && !props.verticalFlip;
  }

  function getDivStyle(): CSSProperties {
    return {
      background: props.dividerColor.toString(),
      transform: props.direction,
    };
  }

  function getSvgStyle(): CSSProperties {
    return {
      display: "block",
      position: "relative",
      fill: props.shapeColor.toString(),
      width: `calc(${props.width}% + 1.3px)`,
      height: `${props.height}px`,
    };
  }
}

export { DividerShapes, DividerDirection, ShapeDivider };
