import { postalCodes } from "assets/geo/postalCodeToZones";
import DateUtils from "guest_website/helpers/DateUtils";
import { CalendarSlot } from "model/calendarSlot";
import { DayInfo } from "model/dayInfo";
import { AvailabilityChecker } from "model/policy/availabilityChecker";
import { BreakDayPolicy } from "model/policy/breakDayPolicy";
import { Policy } from "model/policy/Policy";
import { ReservationsPerSlotPolicy } from "model/policy/reservationsPerSlotPolicy";
import { ZoneSelectionPolicy } from "model/policy/zoneSelectionPolicy";
import { Reservation } from "model/reservation";
import { ReservationsHandler } from "model/reservationsHandler";
import { Allocator } from "./allocator";

export class CalendarSlotController {
  private MAX_AVAILABLE: number = 2;

  private startWindow: Date;
  private endWindow: Date;
  private reservations: Reservation[];
  private dayInfos: DayInfo[];
  private policies: Policy[];
  private zoneFinder: ReservationsHandler;
  private allocator: Allocator;

  constructor(reservations: Reservation[], dayInfos: DayInfo[]) {
    this.startWindow = DateUtils.createDateJustAfterMidnight(DateUtils.getMonday(new Date()));
    this.endWindow = DateUtils.createDateJustBeforeMidnight(DateUtils.addDays(this.startWindow, 6));
    this.reservations = reservations;
    this.dayInfos = dayInfos;
    this.zoneFinder = new ZoneSelectionPolicy(this.reservations);
    this.policies = [
      new ZoneSelectionPolicy(this.reservations),
      new BreakDayPolicy(),
      new ReservationsPerSlotPolicy(this.reservations),
    ];
    this.allocator = new Allocator(new ZoneSelectionPolicy(this.reservations));
  }

  retrieveActualisedCalendarSlots(startWindow: Date, postalCode: string): CalendarSlot[] {
    this.updateWindow(startWindow);
    const slots: CalendarSlot[] = this.initSlots();
    const checker = new AvailabilityChecker(this.startWindow, this.endWindow, this.policies, slots);
    checker.SetAvailabilityForWindow(postalCode);
    const zone = postalCodes[postalCode].zone;
    this.applyMultipleZonePolicy(slots, zone);
    this.applyPreferentialSlot(slots, zone);
    return slots;
  }

  finaliseReservation(reservation: Reservation) {
    reservation.zone = postalCodes[reservation.postalCode].zone;
    this.allocator.allocateReservation(reservation);
  }

  private updateWindow(startWindow: Date) {
    this.startWindow = DateUtils.createDateJustAfterMidnight(DateUtils.getMonday(startWindow));
    this.endWindow = DateUtils.createDateJustBeforeMidnight(DateUtils.addDays(startWindow, 6));
  }

  private getDayInfo(date: Date): DayInfo {
    let info = this.dayInfos.filter((dayInfo) =>
      DateUtils.areDatesEqualWithDayPrecision(dayInfo.date, date)
    );
    if (info.length < 1) {
      throw new Error("Error while finding day info !");
    }
    return info[0];
  }

  private setZonePerSlot(slots: CalendarSlot[]): void {
    slots.forEach((slot) => {
      slot.zone = this.zoneFinder.getZoneOfSlot(slot.dayInfo, slot.isAfternoon);
    });
  }

  private applyMultipleZonePolicy(slots: CalendarSlot[], zone: string): void {
    this.setZonePerSlot(slots);
    let zoneSlot: CalendarSlot[] = [];
    let allocatedZone: number = 0;
    slots.forEach((slot) => {
      if (slot.isAvailable && (slot.zone === "0" || slot.zone === zone)) {
        zoneSlot.push(slot);
      }
      if (slot.isAvailable && slot.zone === zone) {
        allocatedZone++;
      }
    });
    if (allocatedZone >= this.MAX_AVAILABLE) {
      let sortedSlots = zoneSlot.sort((slot1, slot2) => {
        if (slot1.preferenceLevel > slot2.preferenceLevel) {
          return 1;
        } else if (slot1.preferenceLevel === slot2.preferenceLevel) {
          return 0;
        } else {
          return -1;
        }
      });
      sortedSlots = sortedSlots.reverse();
      sortedSlots.forEach((slot, idx) => {
        if (idx >= this.MAX_AVAILABLE) {
          slot.isAvailable = false;
        }
      });
    }
  }

  private applyPreferentialSlot(slots: CalendarSlot[], zone: string): void {
    let max = slots[0].preferenceLevel;
    let maxSlot = slots[0];
    slots.forEach((slot) => {
      if (slot.preferenceLevel > max) {
        max = slot.preferenceLevel;
        maxSlot = slot;
      }
    });
    if (max > 0) {
      maxSlot.isPreferred = true;
    }
  }

  private initSlots(): CalendarSlot[] {
    const slots: CalendarSlot[] = [];
    let date = this.startWindow;
    while (date < this.endWindow) {
      slots.push(new CalendarSlot(this.getDayInfo(date), false));
      slots.push(new CalendarSlot(this.getDayInfo(date), true));
      date = DateUtils.addDays(date, 1);
    }
    return slots;
  }
}
