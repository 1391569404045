import { isZoneAdjacent } from "assets/geo/AdjacentZones";
import { postalCodes } from "assets/geo/postalCodeToZones";
import { CalendarSlot } from "model/calendarSlot";
import { Reservation } from "model/reservation";
import { ReservationsHandler } from "model/reservationsHandler";
import { Policy } from "./Policy";

export class ZoneSelectionPolicy extends ReservationsHandler implements Policy {
  MAX_JOKER: number = 1; // CANNOT BE CHANGED
  MAX_TUNNEL: number = 1;

  constructor(reservations: Reservation[]) {
    super(reservations);
  }

  doesReservationAgreeWithPolicy(slot: CalendarSlot, postalCode: string): boolean {
    const zone = postalCodes[postalCode].zone;
    if (
      this.canBeAllocatedToJoker(slot.dayInfo.date, slot.isAfternoon, zone) ||
      this.canBeAllocatedToTunnel(slot.dayInfo.date, slot.isAfternoon, zone) ||
      this.canBeAssignedToZone(
        this.getZoneOfSlot(slot.dayInfo, slot.isAfternoon),
        zone,
        this.getZoneOfSlot(slot.dayInfo, !slot.isAfternoon)
      )
    ) {
      return true;
    }
    return false;
  }

  isItPreferredSlot(slot: CalendarSlot, postalCode: string): boolean {
    const zone = postalCodes[postalCode].zone;
    if (
      (this.canBeAllocatedToJoker(slot.dayInfo.date, slot.isAfternoon, zone) ||
        this.canBeAllocatedToTunnel(slot.dayInfo.date, slot.isAfternoon, zone) ||
        zone === this.getZoneOfSlot(slot.dayInfo, slot.isAfternoon)) &&
      this.getZoneOfSlot(slot.dayInfo, slot.isAfternoon) !== "0"
    ) {
      return true;
    } else {
      return false;
    }
  }

  isBelowJokerLimit(reservations: Reservation[]): boolean {
    let res = 0;
    reservations.forEach((reservation) => {
      if (reservation.isPotentialJoker()) {
        res++;
      }
    });
    return res < this.MAX_JOKER;
  }

  isBelowTunnelLimit(reservations: Reservation[]): boolean {
    let res = 0;
    reservations.forEach((reservation) => {
      if (reservation.isTunnelLocated()) {
        res++;
      }
    });
    return res <= this.MAX_TUNNEL;
  }

  canBeAllocatedToJoker(date: Date, isAfternoon: boolean, zone: string): boolean {
    const slotsRes = this.getSlotRes(date, isAfternoon);
    return this.isBelowJokerLimit(slotsRes) && zone === "1";
  }

  canBeAllocatedToTunnel(date: Date, isAfternoon: boolean, zone: string): boolean {
    const slotsRes = this.getSlotRes(date, isAfternoon);
    //return this.isBelowTunnelLimit(slots) && TUNNEL OK;
    return false;
  }

  canBeAssignedToZone(slotZone: string, zone: string, oppositeZone: string): boolean {
    if (slotZone !== "0") {
      if (slotZone === zone) {
        return true;
      }
    } else {
      if (oppositeZone === "0") {
        return true;
      } else if (oppositeZone === zone || isZoneAdjacent(zone, oppositeZone)) {
        return true;
      }
    }
    return false;
  }
}
